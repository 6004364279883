import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptyAwardStats } from 'domains/AwardsStatistic/AwardsStatistic.mocks';
import { AwardsStatisticsState, AwardsStatisticsPayload, AwardsStatisticsFailPayload } from './AwardsStatistics.types';

const initialState: AwardsStatisticsState = {
    stats: [],
    isLoading: false,
    errorMessage: '',
};

const awardStatisticsSlice = createSlice({
    name: 'awardStatistics',
    initialState,
    reducers: {
        fetchAwardsStatisticsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = '';
        },
        fetchAwardsStatisticsSucceeded: (state, action: PayloadAction<AwardsStatisticsPayload>) => {
            const { stats } = action.payload;
            state.stats = stats;
            state.isLoading = false;
        },
        fetchAwardsStatisticsFailed: (state, action: PayloadAction<AwardsStatisticsFailPayload>) => {
            const { message } = action.payload;
            state.stats = emptyAwardStats;
            state.errorMessage = message;
            state.isLoading = false;
        },
    },
});

export const { fetchAwardsStatisticsStarted, fetchAwardsStatisticsSucceeded, fetchAwardsStatisticsFailed } =
    awardStatisticsSlice.actions;
export default awardStatisticsSlice.reducer;

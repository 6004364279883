import is from 'utils/is';
import typeOf from 'utils/typeOf';
import shallowCopy from 'utils/shallowCopy';
import parse from 'utils/parse';
import type { SnakeCasedProperties } from 'type-fest';
import type { TrackingEvent } from './types';

const convertKeyToSnakeCase = <T extends object>(source: T): SnakeCasedProperties<T> => {
    if (!is.object(source)) {
        throw new TypeError(`Expected "source" to be an "object", but instead got "${typeOf(source)}"`);
    }

    const clone = {} as T;
    const keys = Object.keys(source);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        //@ts-expect-error creating properties dynamically
        clone[parse.toSnakeCase(key)] = shallowCopy(source[key]);
    }

    return clone as SnakeCasedProperties<T>;
};

/**
 * Parse to Snake Case
 * @description custom case parsing that preserves string chars
 * @example
 * toSnakeCase('Tag Added {name}')
 * outputs "tag_added_{name}"
 * @param {string} values
 * @return {string}
 */
const toSnakeCase = (values: string): string => {
    return (
        values
            ?.trim()
            .replace(/([A-Z])/g, '$1')
            .split(' ')
            .join('_')
            .toLowerCase() ?? ''
    );
};

/**
 * Try Parse to Snake Case if value is not nullish
 * @param {string | undefined} value
 */
const tryParseToSnakeCase = (value: string | undefined): string | undefined => {
    if (is.nullish(value)) {
        return;
    }

    return toSnakeCase(value);
};

const compileVariables = <T extends TrackingEvent>(tracking: T, values?: Record<string, string>): T => {
    const trackingEntries = Object.entries(tracking);
    const variables = Object.entries(values ?? {});

    const errors = trackingEntries
        .filter(([, value]) => !is.func(value) && !is.nullish(value))
        .filter(([, value]: [string, unknown]) => /{(.*)}/i.test(value!.toString()))
        .map(([, value]) => value)
        .filter((value) => !variables.some(([variable]) => value.includes(`{${variable}}`)));

    if (errors.length) {
        throw Error(
            `[Google Analytics] Missing variable values for the following strings:\n\t- ${errors.join('\n\t- ')}`,
        );
    }

    return Object.keys(tracking).reduce((acc, key) => {
        //@ts-expect-error
        if (!is.string(acc[key])) return acc;

        const compiled = variables.reduce(
            (content, [variable, value]) => {
                return content.replace(`{${variable}}`, value);
            },
            //@ts-expect-error
            acc[key],
        );

        return { ...acc, [key]: compiled };
    }, tracking);
};

export { convertKeyToSnakeCase, toSnakeCase, tryParseToSnakeCase, compileVariables };

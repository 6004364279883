import './LandingPage.module.scss';
import { lazy } from 'react';
import { isLiveJasmin } from 'configs/sites';
import routes from 'configs/routes';

const getLJLanding = (pathname: string) => {
    switch (pathname) {
        case routes.certifiedStudios:
            return lazy(() => import('./CertifiedStudios'));
        default:
            return lazy(() => import('./LiveJasmin'));
    }
};

const getLanding = () => {
    if (isLiveJasmin) {
        return getLJLanding(window.location.pathname);
    }

    return lazy(() => import('./Common'));
};

export default getLanding;

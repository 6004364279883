import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PretenceGroup } from 'domains/PretenceGroups/PretenceGroups.types';
import { PretenceGroupState } from './PretenceGroups.types';

const initialState: PretenceGroupState = {
    pretenceGroups: [],
    status: 'initial',
    errorMessage: null,
};

const pretenceGroupsSlice = createSlice({
    name: 'pretenceGroups',
    initialState,
    reducers: {
        fetchPretenceGroupsStarted: (state) => {
            state.status = 'pending';
            state.errorMessage = null;
        },
        fetchPretenceGroupsSucceeded: (state, action: PayloadAction<PretenceGroup[]>) => {
            state.pretenceGroups = action.payload;
            state.status = 'resolved';
        },
        fetchPretenceGroupsFailed: (state, action: PayloadAction<string>) => {
            state.status = 'rejected';
            state.errorMessage = action.payload;
        },
    },
});

export const { fetchPretenceGroupsStarted, fetchPretenceGroupsSucceeded, fetchPretenceGroupsFailed } =
    pretenceGroupsSlice.actions;
export default pretenceGroupsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageActionsState } from './MessageActions.type';

export const messengerPageInitialState: MessageActionsState = {};

const messengerPageSlice = createSlice({
    name: 'message-actions',
    initialState: messengerPageInitialState,
    reducers: {
        setDeletingMessage: (state, action: PayloadAction<MessageActionsState['deleting']>) => {
            state.deleting = action.payload;
        },
        clearDeletingMessage: (state) => {
            state.deleting = undefined;
        },
    },
});

export const { setDeletingMessage, clearDeletingMessage } = messengerPageSlice.actions;
export default messengerPageSlice.reducer;

/* tslint:disable */
/* eslint-disable */
/**
 * Account_Services API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AudioSpokenLanguageEnum } from './audio-spoken-language-enum';
import { UpdatePerformerPropertiesRequestCountryBan } from './update-performer-properties-request-country-ban';
import { UpdatePerformerPropertiesRequestNextOnlineSession } from './update-performer-properties-request-next-online-session';

/**
 *
 * @export
 * @interface UpdatePerformerPropertiesRequest
 */
export interface UpdatePerformerPropertiesRequest {
    /**
     *
     * @type {boolean}
     * @memberof UpdatePerformerPropertiesRequest
     */
    isBirthdayVisible?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePerformerPropertiesRequest
     */
    isMobileLiveDisabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePerformerPropertiesRequest
     */
    isReadReceiptsEnabled?: boolean;
    /**
     *
     * @type {number}
     * @memberof UpdatePerformerPropertiesRequest
     */
    liveShowChargeAmount?: number;
    /**
     *
     * @type {number}
     * @memberof UpdatePerformerPropertiesRequest
     */
    cam2camChargeAmount?: number;
    /**
     *
     * @type {number}
     * @memberof UpdatePerformerPropertiesRequest
     */
    audioChargeRateModifier?: number;
    /**
     *
     * @type {number}
     * @memberof UpdatePerformerPropertiesRequest
     */
    videoVoiceCallPrice?: number;
    /**
     *
     * @type {number}
     * @memberof UpdatePerformerPropertiesRequest
     */
    memberMessagePrice?: number;
    /**
     *
     * @type {UpdatePerformerPropertiesRequestCountryBan}
     * @memberof UpdatePerformerPropertiesRequest
     */
    countryBan?: UpdatePerformerPropertiesRequestCountryBan;
    /**
     *
     * @type {string}
     * @memberof UpdatePerformerPropertiesRequest
     */
    aboutMe?: string;
    /**
     *
     * @type {UpdatePerformerPropertiesRequestNextOnlineSession}
     * @memberof UpdatePerformerPropertiesRequest
     */
    nextOnlineSession?: UpdatePerformerPropertiesRequestNextOnlineSession;
    /**
     * Must be true
     * @type {boolean}
     * @memberof UpdatePerformerPropertiesRequest
     */
    isSubscriptionEnabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdatePerformerPropertiesRequest
     */
    subscriptionPackageId?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePerformerPropertiesRequest
     */
    isTwoWayAudioEnabled?: boolean;
    /**
     *
     * @type {Array<AudioSpokenLanguageEnum>}
     * @memberof UpdatePerformerPropertiesRequest
     */
    audioSpokenLanguage?: Array<AudioSpokenLanguageEnum>;
    /**
     * Referral Consent Status Id  - 0: default - 1: accepted - 2: revoked - 3: expired
     * @type {string}
     * @memberof UpdatePerformerPropertiesRequest
     */
    referralConsentStatus?: UpdatePerformerPropertiesRequestReferralConsentStatusEnum;
    /**
     *
     * @type {string}
     * @memberof UpdatePerformerPropertiesRequest
     */
    thingsLike?: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePerformerPropertiesRequest
     */
    thingsDislike?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePerformerPropertiesRequestReferralConsentStatusEnum {
    _0 = '0',
    _1 = '1',
    _2 = '2',
    _3 = '3',
}

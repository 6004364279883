import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipientFilterState } from './RecipientFilter.types';

export const initialState: RecipientFilterState = {
    categoryId: undefined,
    timePeriodId: undefined,
};

const recipientFilterSlice = createSlice({
    name: 'recipientFilter',
    initialState,
    reducers: {
        setSelectedFilter: (state, action: PayloadAction<{ categoryId?: number; timePeriodId?: number }>) => {
            state.categoryId = action.payload.categoryId;
            state.timePeriodId = action.payload.timePeriodId;
        },
        clearSelectedFilter: (state) => {
            state.categoryId = undefined;
            state.timePeriodId = undefined;
        },
    },
});

export const { setSelectedFilter, clearSelectedFilter } = recipientFilterSlice.actions;
export default recipientFilterSlice.reducer;

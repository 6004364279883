/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { getEnv } from 'configs/environments';
import { Environment } from 'configs/globalConfig';
import { FeatureFlagEnum } from 'configs/featureFlags';
import MockResponse from 'domains/mockResponse';
import { getFeatureFlag } from 'utils/featureFlag';
import { BaseAPI } from '../../base';
import { DEV_COMPATIBLE_STUDIO_ID } from './Statistics.config';
import {
    ApiCertifiedStudioEarningsPayload,
    ApiCertifiedStudioEarningsResponse,
    ApiGetBiTokenResponse,
    ApiGetBiTokenType,
    ApiCertifiedStudioKPIsPayload,
    ApiCertifiedStudioKPIsResponse,
    ApiCertifiedStudioTopPerformerEarningsPayload,
    ApiCertifiedStudioTopPerformerEarningsResponse,
    ApiCertifiedStudioWorkingTimePayload,
    ApiCertifiedStudioWorkingTimeResponse,
    AvailableReports,
    ReportModelRequests,
    StatisticResponse,
    StatisticStudioRequest,
    ApiCertifiedStudioTrafficDataChartResponse,
    ApiCertifiedStudioTrafficDataChartPayload,
    ApiCertifiedStudioTrafficKPIsPayload,
    ApiCertifiedStudioTrafficKPIsResponse,
    ApiCertifiedStudioTopCountriesPayload,
    ApiCertifiedStudioTopCountries,
} from './Statistics.types';

export const AllReports = [
    AvailableReports.generalOverview,
    AvailableReports.earningsOverview,
    AvailableReports.workingTime,
    AvailableReports.messengerTime,
];

export class StatisticReportApi extends BaseAPI {
    public async getStudioStatistics(
        startDate: string,
        endDate: string,
        reports: Array<AvailableReports> | undefined,
        studioId: number,
        models?: number[] | null,
    ) {
        const request: StatisticStudioRequest = {
            reportRequests: {
                studio_id: studioId.toString(),
                reports: reports || AllReports,
                metadata: {
                    from_date: startDate,
                    to_date: endDate,
                },
                models,
                batch_mode: Boolean(models && models.length > 0),
            },
        };

        return this.axios.post<StatisticStudioRequest, AxiosResponse<StatisticResponse>>(
            `${this.basePath}v2/studio/reports:batchGet`,
            request,
        );
    }

    public async getModelStatistics(
        startDate: string,
        endDate: string,
        reports: Array<string>,
        selectedPerformerId: number,
    ) {
        const request = reports.map((report) => {
            return {
                from_date: startDate,
                model_id: selectedPerformerId.toString(),
                report_id: report,
                to_date: endDate,
            } as ReportModelRequests;
        });

        return this.axios.post<StatisticStudioRequest, AxiosResponse<StatisticResponse>>(
            `${this.basePath}v2/reports:batchGet`,
            { reportRequests: request },
        );
    }

    public async getCertifiedStudioKPIs(params: ApiCertifiedStudioKPIsPayload) {
        const certifiedStudioParams = this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioKPIsPayload>(params);
        return this.axios.post<ApiCertifiedStudioKPIsPayload, AxiosResponse<ApiCertifiedStudioKPIsResponse>>(
            `${this.basePath}v2/studio/kpis`,
            certifiedStudioParams,
        );
    }

    public async getCertifiedStudioEarnings(params: ApiCertifiedStudioEarningsPayload) {
        const certifiedStudioParams = this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioEarningsPayload>(params);
        return this.axios.post<ApiCertifiedStudioEarningsPayload, AxiosResponse<ApiCertifiedStudioEarningsResponse>>(
            `${this.basePath}v2/studio/earnings-trendchart`,
            certifiedStudioParams,
        );
    }

    public async getCertifiedStudioWorkingTime(params: ApiCertifiedStudioWorkingTimePayload) {
        const certifiedStudioParams = this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioWorkingTimePayload>(params);
        return this.axios.post<
            ApiCertifiedStudioWorkingTimePayload,
            AxiosResponse<ApiCertifiedStudioWorkingTimeResponse>
        >(`${this.basePath}v2/studio/working-hours-trendchart`, certifiedStudioParams);
    }

    public async getCertifiedStudioTopPerformerEarnings(params: ApiCertifiedStudioTopPerformerEarningsPayload) {
        const certifiedStudioParams =
            this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioTopPerformerEarningsPayload>(params);
        return this.axios.post<
            ApiCertifiedStudioTopPerformerEarningsPayload,
            AxiosResponse<ApiCertifiedStudioTopPerformerEarningsResponse>
        >(`${this.basePath}v2/studio/top-performer-earnings`, certifiedStudioParams);
    }

    public async getBiToken(type: ApiGetBiTokenType = 'studio') {
        return this.axios.post<AxiosResponse<ApiGetBiTokenResponse>>(`/en/statistics/dashboard/generate-token`, {
            type,
        });
    }

    public async getCertifiedStudioTrafficKPIs(params: ApiCertifiedStudioTrafficKPIsPayload) {
        const certifiedStudioParams = this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioTrafficKPIsPayload>(params);
        return this.axios.post<
            ApiCertifiedStudioTrafficKPIsPayload,
            AxiosResponse<ApiCertifiedStudioTrafficKPIsResponse>
        >(`${this.basePath}v2/studio/traffic/kpis`, certifiedStudioParams);
    }

    private mockCertifiedStudioIdOnDev<T>(params: T) {
        const isDev = getEnv() === Environment.DEV;
        const studioId =
            isDev && getFeatureFlag(FeatureFlagEnum.MockCertifiedStudioDataOnDev)
                ? DEV_COMPATIBLE_STUDIO_ID.toString()
                : // @ts-ignore
                  params.studioId;

        return {
            ...params,
            studioId,
        };
    }

    public async getCertifiedStudioTrafficDataChart(params: ApiCertifiedStudioTrafficDataChartPayload) {
        const certifiedStudioParams =
            this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioTrafficDataChartPayload>(params);
        return this.axios.post<
            ApiCertifiedStudioTrafficDataChartPayload,
            AxiosResponse<ApiCertifiedStudioTrafficDataChartResponse>
        >(`${this.basePath}v2/studio/traffic/data-chart`, certifiedStudioParams);
    }

    public async getCertifiedStudioTopCountries(params: ApiCertifiedStudioTopCountriesPayload) {
        const certifiedStudioParams = this.mockCertifiedStudioIdOnDev<ApiCertifiedStudioTopCountriesPayload>(params);
        return this.axios.post<ApiCertifiedStudioTopCountriesPayload, AxiosResponse<ApiCertifiedStudioTopCountries>>(
            `${this.basePath}v2/studio/traffic/top-countries`,
            certifiedStudioParams,
        );
    }
}

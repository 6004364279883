import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodsState, PeriodsSuccessPayload } from './Periods.types';

const initialState: PeriodsState = {
    periods: [],
    fetchedPeriods: [],
    isLoading: false,
    errorMessage: null,
};

const periodsSlice = createSlice({
    name: 'periods',
    initialState,
    reducers: {
        fetchPeriodsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
        },
        fetchPeriodsSucceeded: (state, action: PayloadAction<PeriodsSuccessPayload>) => {
            const { periods, periodKey } = action.payload;
            state.periods = state.periods.concat(periods);
            state.fetchedPeriods.push(periodKey);
            state.isLoading = false;
        },
        fetchPeriodsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
    },
});

export const { fetchPeriodsStarted, fetchPeriodsSucceeded, fetchPeriodsFailed } = periodsSlice.actions;
export default periodsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PerformerPricing } from 'domains/Performer/Performer.types';
import { PerformerPricingState } from './PerformerPricing.types';

const initialState: PerformerPricingState = {
    pricing: undefined,
    pricingStatus: 'initial',
};

const performerPricingSlice = createSlice({
    name: 'performerPricing',
    initialState,
    reducers: {
        fetchPerformerPricingStarted: (state) => {
            state.pricing = undefined;
            state.pricingStatus = 'pending';
        },
        fetchPerformerPricingSucceeded: (state, action: PayloadAction<PerformerPricing>) => {
            state.pricing = action.payload;
            state.pricingStatus = 'resolved';
        },
        fetchPerformerPricingFailed: (state) => {
            state.pricingStatus = 'rejected';
        },
    },
});

export const { fetchPerformerPricingStarted, fetchPerformerPricingSucceeded, fetchPerformerPricingFailed } =
    performerPricingSlice.actions;
export default performerPricingSlice.reducer;

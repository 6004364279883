import { getEnv } from 'configs/environments';
import { Environment } from 'configs/globalConfig';
import Cookies, { CookieAttributes } from 'js-cookie';
import { addSecondsToCurrentTime } from 'utils/datetime';
import location, { LocationUrl } from 'utils/location';
import parse from 'utils/parse';
import { CookieStorage } from './index';

const COOKIE_KEY_PREFIX = '__msc';
const k8sDevDomain = '.dev.k8sdevel.com';
type CookieCategories = 'essential' | 'analytical' | 'functional';

// Dots and spaces in cookie name got replaced to underscore!
// https://www.php.net/manual/en/reserved.variables.cookies.php
export const cookieNameCreator = (category: CookieCategories, uniqueName: string) => {
    return `${COOKIE_KEY_PREFIX}-${parse.toKebabCase(category)}-${parse.toKebabCase(uniqueName)}`;
};

export const getCookieDomain = (
    domain: LocationUrl['domain'],
    subdomain: LocationUrl['subdomain'],
): string | undefined => {
    if (!subdomain) {
        return undefined;
    }
    return `.${subdomain.split('.').splice(-1)[0]}.${domain}`;
};

const getCookieDomainDev = (hostname: string) => {
    if (hostname.includes(k8sDevDomain)) return k8sDevDomain;
    return undefined;
};

const getCookieAttributes = (expiresInSeconds: number): CookieAttributes => {
    return {
        expires: addSecondsToCurrentTime(expiresInSeconds),
        domain:
            getEnv() === Environment.DEV
                ? getCookieDomainDev(window.location.hostname)
                : getCookieDomain(location.url.domain, location.url.subdomain),
    };
};

const cookies: CookieStorage = {
    get<T>(key: string) {
        try {
            const cookieContent = Cookies.get(key);

            if (!cookieContent) return undefined;

            return JSON.parse(cookieContent) as T;
        } catch (e) {
            console.warn(`Couldn't parse cookie: ${e}`);
            return undefined;
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTypeGuarded<T>(key: string, isT: (x: any) => x is T) {
        try {
            const cookieContent = Cookies.get(key);

            if (!cookieContent) return undefined;

            const data = JSON.parse(cookieContent) as T;

            return isT(data) ? data : undefined;
        } catch (e) {
            console.warn(`Couldn't parse cookie: ${e}`);
            return undefined;
        }
    },
    set(key, value, expiresInSeconds) {
        try {
            const cookieValue = typeof value === 'string' ? value : JSON.stringify(value);
            // Removes the cookie from the root domain to prevent >4kb http headers
            Cookies.remove(key, { domain: `.${location.url.domain}` });
            Cookies.set(key, cookieValue, getCookieAttributes(expiresInSeconds));
        } catch (e) {
            console.warn(`Couldn't stringify value: ${value}`);
        }
    },
    remove(key) {
        Cookies.remove(key, getCookieAttributes(-1));
    },
};
export default cookies;

/* eslint-disable no-restricted-imports */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassMessageLimit } from 'domains/Messenger/massMessage/MassMessage.types';
import { ForwardMediaState } from './ForwardMedia.types';

export const initialState: ForwardMediaState = {
    isLoading: false,
    errorMessage: undefined,
    forwardMediaLimit: undefined,
    media: undefined,
};

const forwardMediaSlice = createSlice({
    name: 'forwardMedia',
    initialState,
    reducers: {
        fetchForwardMediaLimitStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchForwardMediaLimitSucceeded: (state, action: PayloadAction<MassMessageLimit>) => {
            state.forwardMediaLimit = action.payload;
            state.isLoading = false;
        },
        fetchForwardMediaLimitFailed: (state, action: PayloadAction<string>) => {
            state.forwardMediaLimit = undefined;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        addForwardingMedia: (state, action: PayloadAction<Pick<ForwardMediaState, 'media'>>) => {
            state.media = action.payload.media;
        },
        clearForwardingMedia: (state) => {
            state.media = undefined;
        },
    },
});

export const {
    fetchForwardMediaLimitStarted,
    fetchForwardMediaLimitSucceeded,
    fetchForwardMediaLimitFailed,
    addForwardingMedia,
    clearForwardingMedia,
} = forwardMediaSlice.actions;
export default forwardMediaSlice.reducer;

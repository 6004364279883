import { AuthToken } from 'configs/appConfig';
import { AuthTokenRequest } from './Token.types';

export interface TokenServiceInterface {
    getAuthToken: (request: AuthTokenRequest, userId: number) => Promise<AuthToken>;
}

export class TokenService {
    constructor(private mapper: TokenServiceInterface) {}

    public async getAuthToken(request: AuthTokenRequest, userId: number) {
        return this.mapper.getAuthToken(request, userId);
    }
}

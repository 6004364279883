import is from './is';
import typeOf from './typeOf';

/**
 * Shallow copy/clone element type
 * @description superficially clone the structure but not the elements.
 * @param {T} value
 * @return {T}
 */
const shallowCopy = <T>(value: T): T => {
    let copy: unknown;

    if (is.array(value)) {
        copy = [...value];
    } else if (is.object(value)) {
        copy = { ...value };
    } else if (typeOf(value) === 'date') {
        copy = new Date(value as unknown as Date);
    } else if (typeOf(value) === 'map') {
        copy = new Map(value as unknown as Map<unknown, unknown>);
    } else if (typeOf(value) === 'set') {
        copy = new Set(value as unknown as Set<unknown>);
    } else {
        copy = value;
    }

    return copy as T;
};

export default shallowCopy;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Studio, StudioPagedPerformerList } from 'domains/Studio/Studio.types';
import { PerformerListType, PerformerStatusFilter, StudioState } from './Studio.types';

export const initialState: StudioState = {
    studio: undefined,
    isLoading: false,
    performerList: {
        performerTab: {
            data: [],
            pagination: {
                offset: 0,
                limit: 20,
            },
            isLoading: false,
        },
        comparisonTabA: {
            data: [],
            pagination: {
                offset: 0,
                limit: 20,
            },
            isLoading: false,
        },
        comparisonTabB: {
            data: [],
            pagination: {
                offset: 0,
                limit: 20,
            },
            isLoading: false,
        },
        trafficTab: {
            data: [],
            pagination: {
                offset: 0,
                limit: 20,
            },
            isLoading: false,
        },
        trafficTabCompareWith: {
            data: [],
            pagination: {
                offset: 0,
                limit: 20,
            },
            isLoading: false,
        },
    },
    errorMessage: undefined,
};

const studioSlice = createSlice({
    name: 'studio',
    initialState,
    reducers: {
        fetchStudioStarted: (state) => {
            state.studio = undefined;
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchStudioSucceeded: (state, action: PayloadAction<Studio>) => {
            state.studio = action.payload;
            state.isLoading = false;
        },
        fetchStudioFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        fetchPerformerListStarted: (
            state,
            action: PayloadAction<{
                type: PerformerListType;
                filterName?: string;
                filterStatus?: PerformerStatusFilter;
            }>,
        ) => {
            const { type, filterName, filterStatus } = action.payload;
            state.performerList[type].isLoading = true;
            state.performerList[type].filterName = filterName;
            state.performerList[type].filterStatus = filterStatus;
        },
        fetchPerformerListSucceeded: (
            state,
            action: PayloadAction<{
                resetList: boolean;
                result: StudioPagedPerformerList;
                type: PerformerListType;
            }>,
        ) => {
            const { resetList, result, type } = action.payload;
            const { data, pagination } = result;
            state.performerList[type].data = resetList ? data : state.performerList[type].data.concat(data);
            state.performerList[type].pagination = pagination;
            state.performerList[type].isLoading = false;
        },
        fetchPerformerListFailed: (state, action: PayloadAction<PerformerListType>) => {
            const type = action.payload;
            state.performerList[type].isLoading = false;
        },
        setPerformerListOffset: (state, action: PayloadAction<{ offset: number; type: PerformerListType }>) => {
            const { offset, type } = action.payload;
            state.performerList[type].pagination.offset = offset;
        },
        setPerformerListStatusFilter: (
            state,
            action: PayloadAction<{ filterStatus: PerformerStatusFilter; type: PerformerListType }>,
        ) => {
            const { filterStatus, type } = action.payload;
            state.performerList[type].filterStatus = filterStatus;
        },
    },
});

export const {
    fetchStudioStarted,
    fetchStudioSucceeded,
    fetchStudioFailed,
    fetchPerformerListStarted,
    fetchPerformerListSucceeded,
    fetchPerformerListFailed,
    setPerformerListOffset,
    setPerformerListStatusFilter,
} = studioSlice.actions;
export default studioSlice.reducer;

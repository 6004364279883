import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelBooster } from 'domains/ModelBooster/ModelBooster.types';
import { ModelBoosterState } from './ModelBooster.types';

const initialState: ModelBoosterState = {
    status: 'initial',
    errorMessage: '',
    info: undefined,
};

const modelBoosterSlice = createSlice({
    name: 'modelBooster',
    initialState,
    reducers: {
        fetchModelBoosterStarted: (state) => {
            state.status = 'pending';
            state.errorMessage = '';
        },
        fetchModelBoosterSucceeded: (state, action: PayloadAction<ModelBooster>) => {
            state.status = 'resolved';
            state.errorMessage = '';
            state.info = action.payload;
        },
        fetchModelBoosterFailed: (state, action: PayloadAction<string>) => {
            state.status = 'rejected';
            state.errorMessage = action.payload;
        },
    },
});

export const { fetchModelBoosterStarted, fetchModelBoosterSucceeded, fetchModelBoosterFailed } =
    modelBoosterSlice.actions;
export default modelBoosterSlice.reducer;

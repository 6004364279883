import { PerformerEntity, MeEntity, PerformerPeriod } from 'domains/Performer/Performer.types';
// eslint-disable-next-line no-restricted-imports
import { UpdatePerformerPropertiesRequest } from 'apiClients/msc-api-gateway/accountservices/models';
import { RequestStatus } from 'store/commonTypes';

export interface PerformerState {
    me: MeEntity | undefined;
    myPerformer: PerformerEntity | undefined;
    language: string;
    isLoading: boolean;
    progress: Progress[];
    errorMessage: string | undefined;
    periods?: Array<PerformerPeriod>;
    properties: UpdatePerformerPropertiesRequest;
}

export interface Progress {
    type: PerformerLoadingTypes;
    status: ProgressStatus;
    errorMessage?: string;
}

export interface PerformerNewbieStatus {
    status: RequestStatus;
    errorMessage: string | undefined;
    newbieUntil: string | undefined;
    isNewbie: boolean;
}

export enum PerformerLoadingTypes {
    SubscriptionPrice = 'subscriptionPrice',
    VideoCallPrice = 'videoCallPrice',
    MessagePrice = 'messagPrice',
    PrivateShowPrice = 'privateShowPrice',
    Cam2CamPrice = 'cam2CamPrice',
    TwoWayAudioPrice = 'twoWayAudioPrice',
    MyStoryPrice = 'myStoryPrice',
    TwoWayAudioLanguages = 'twoWayAudioLanguages',
    TwoWayAudioEnabled = 'twoWayAudioEnabled',
}

export enum ProgressStatus {
    Loading = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

import _ from '@dh-fe/translate';
import routes from 'configs/routes';

const content = {
    infoHeader: _`Privacy Preference Center`,
    infoBody:
        _`We process user's data to deliver content or advertisements and measure the delivery of such content or advertisements, extract insights and generate reports to understand service usage; and/or accessing or storing information on devices for that purpose. Below you may read further about the purposes for which we process data, exercise your preferences for processing, and/or see our partners. [a href="{url}"]Cookie Policy[/a].`
            .withData({ url: routes.cookiePolicyUrl })
            .parseBB()
            .toJSX(),
    controlsHeader: _`Manage Cookie Preferences`,
    essentialLabel: _`Essential Cookies`,
    essentialStatus: _`Always active`,
    essentialInfo: _`These are cookies that are required for the operation of our website. We use, for example, these cookies to enable the purchase functionality on our website or to route traffic to the right servers on our network.`,
    analyticalLabel: _`Analytical Cookies`,
    analyticalInfo: _`We use these cookies to gain insight into how you use our site. This helps us to understand how you interact with our site, what is interesting for you and how we can improve our services, and enhance the overall user experience.`,
    functionalityLabel: _`Functionality Cookies`,
    functionalityInfo: _`These cookies allow the website to remember choices you make or your preferences (such as your language or the region you are in) and provide enhanced, more personal features.`,
    confirm: _`Confirm My Choices`,
};

export default content;

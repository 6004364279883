import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UISubscribers, UISubscriptionStatistics, SubscriptionLoading } from 'domains/Subscription/Subscription.types';
import { SubscriptionState } from './Subscription.types';

const initialState: SubscriptionState = {
    statistics: {
        statistics: undefined,
        isLoading: false,
        isFailed: false,
    },
    isLoading: SubscriptionLoading.INITIAL,
    progress: [],
    errorMessage: undefined,
    subscribers: undefined,
    pagination: undefined,
    availability: {
        isLoading: false,
        error: false,
        isAvailable: undefined,
        numberOfUploadedVideos: undefined,
        timeLeftInSeconds: undefined,
    },
};

const subscriptionSlice = createSlice({
    name: 'subscribers',
    initialState,
    reducers: {
        fetchStatisticsStarted: (state) => {
            state.statistics = {
                statistics: undefined,
                isLoading: true,
                isFailed: false,
            };
        },
        fetchStatisticsSucceeded: (state, action: PayloadAction<UISubscriptionStatistics>) => {
            state.statistics = {
                statistics: action.payload,
                isLoading: false,
                isFailed: false,
            };
        },
        fetchStatisticsFailed: (state) => {
            state.statistics = {
                statistics: undefined,
                isLoading: false,
                isFailed: true,
            };
        },
        fetchSubscribersStarted: (state, action: PayloadAction<SubscriptionLoading>) => {
            state.isLoading = action.payload;
            state.errorMessage = undefined;
        },
        cleanSubscribersList: (state) => {
            state.subscribers = [];
        },
        fetchSubscribersSucceeded: (state, action: PayloadAction<UISubscribers>) => {
            state.isLoading = SubscriptionLoading.NONE;
            state.errorMessage = undefined;
            state.pagination = action.payload.pagination;
            state.subscribers = (state.subscribers || []).concat(action.payload.subscriberList);
        },
        fetchSubscribersFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = SubscriptionLoading.NONE;
            state.errorMessage = action.payload;
        },
    },
});

export const {
    fetchStatisticsStarted,
    fetchStatisticsSucceeded,
    fetchStatisticsFailed,
    fetchSubscribersStarted,
    fetchSubscribersSucceeded,
    fetchSubscribersFailed,
    cleanSubscribersList,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckUsernameResponse } from 'domains/BeMyFan/BeMyFan.types';
import { BeMyFanState } from './BeMyFan.types';

export const initialState: BeMyFanState = {
    requestStatus: 'initial',
    canRegister: undefined,
    token: '',
    username: '',
};

const beMyFanSlice = createSlice({
    name: 'beMyFan',
    initialState,
    reducers: {
        fetchBeMyFanStatusStarted: (state) => {
            state.requestStatus = 'pending';
        },
        fetchBeMyFanStatusSucceeded: (state, action: PayloadAction<CheckUsernameResponse>) => {
            state.requestStatus = 'resolved';
            state.canRegister = action.payload.canRegister;
            state.token = action.payload.token || '';
            state.username = action.payload.username || '';
        },
        fetchBeMyFanStatusFailed: (state) => {
            state.requestStatus = 'rejected';
        },
    },
});

export const { fetchBeMyFanStatusStarted, fetchBeMyFanStatusSucceeded, fetchBeMyFanStatusFailed } =
    beMyFanSlice.actions;
export default beMyFanSlice.reducer;

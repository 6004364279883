import { getOfflineSurpriseLastMessage } from 'domains/Messenger/MessengerThreads/MessengerThreads.mapper';
import { MessageTypesEnum, MessengerMemberLastMessage } from 'domains/Messenger/Messages.types';
import {
    JawsMessageResponse,
    CommonJawsMessage,
    GiftJawsMessage,
    CallJawsMessage,
    TypesEnum,
    MediaTypesEnum,
    SurpriseJawsMessage,
} from './types';

const { Audio, Image, Video, CallInfo, Gift } = MessageTypesEnum;
const { MEDIA_CONTENT, OFFLINE_SURPRISE, CALL_INFO, GIFT } = TypesEnum;
const { AUDIO, IMAGE, VIDEO } = MediaTypesEnum;

export const mapJawsResponseToThreadLastMessage = (
    response: JawsMessageResponse<CommonJawsMessage>,
): MessengerMemberLastMessage => {
    let lastMessage: MessengerMemberLastMessage = { type: MessageTypesEnum.Text, body: response.body.message };
    const messageData = response.body.data;

    switch (messageData.type) {
        case MEDIA_CONTENT: {
            switch (messageData.mediaType) {
                case AUDIO:
                    lastMessage = { type: Audio };
                    break;
                case IMAGE:
                    lastMessage = { type: Image };
                    break;
                case VIDEO:
                    lastMessage = { type: Video };
                    break;
            }

            break;
        }
        case OFFLINE_SURPRISE: {
            const { price, creditType, surpriseVariant } = messageData as SurpriseJawsMessage;

            lastMessage = getOfflineSurpriseLastMessage(price, creditType, 'received', surpriseVariant);
            break;
        }
        case GIFT: {
            lastMessage = { type: Gift, giftName: (messageData as GiftJawsMessage).name };
            break;
        }
        case CALL_INFO: {
            lastMessage = {
                type: CallInfo,
                event: (messageData as CallJawsMessage).event,
                senderName: response.body.senderName,
            };
            break;
        }
    }

    return lastMessage;
};

import { AppThunk } from 'store/store';
import { ToastMeta, addToast } from './Toast.slice';

export const showToast =
    (toast: ToastMeta): AppThunk =>
    async (dispatch) => {
        const id = toast.customId || getRandomId();

        dispatch(
            addToast({
                id,
                toast,
            }),
        );
    };

// If showToast is fired two times immediately it should return different unique IDs (unless a customId was passed)
const getRandomId = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

import { Environment } from './globalConfig';

const prodDomains = ['.livejasmin.com', '.lj.com', '.oranum.com'];

export const getEnv = (): Environment => {
    if (window.location.hostname.includes('lubet.')) return Environment.STAGING;

    const prodDomain = prodDomains.find((domain) => window.location.hostname.includes(domain));
    if (prodDomain) return Environment.PROD;

    return Environment.DEV;
};

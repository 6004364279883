import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationList } from 'domains/Notification/Notification.types';
import { NotificationsLoadingPosition, NotificationsState, NotificationUpdateType } from './Notifications.types';

const initialState: NotificationsState = {
    entries: [],
    meta: { cursor: undefined },
    isLoading: false,
    loadingPosition: '',
    errorMessage: undefined,
};

const notificationsSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        fetchNotificationsStarted: (state, action: PayloadAction<NotificationsLoadingPosition>) => {
            state.isLoading = true;
            state.loadingPosition = action.payload;
            state.errorMessage = undefined;
        },
        fetchNotificationsSucceeded: (
            state,
            action: PayloadAction<{ response: NotificationList; updateType: NotificationUpdateType }>,
        ) => {
            const {
                updateType,
                response: { notifications, meta },
            } = action.payload;
            state.entries = updateType === 'overwrite' ? notifications : [...state.entries, ...notifications];
            state.meta = meta;
            state.isLoading = false;
            state.loadingPosition = '';
        },
        fetchNotificationsFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
            state.loadingPosition = '';
        },
        setNotificationsAsRead: (state) => {
            state.entries = state.entries.map((entry) => {
                return {
                    ...entry,
                    isNew: false,
                };
            });
        },
    },
});

export const {
    fetchNotificationsStarted,
    fetchNotificationsSucceeded,
    fetchNotificationsFailed,
    setNotificationsAsRead,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;

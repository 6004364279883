import { apiGwConfig, apiHost } from 'apiClients/api.config';
import { axiosApiGw } from 'apiClients/axiosSetup';
import { CookieConsentsApi } from 'apiClients/msc-api-gateway/proxy/cookieconsent/client/cookie-consents-api';
import { CookieConsentDataMapperInterface, CookieConsentService } from './CookieConsent.service';
import { CookieConsentDataMapper } from './CookieConsent.dataMapper';

export interface CookieConsentServiceInterface extends CookieConsentDataMapperInterface {}

const api = new CookieConsentsApi(apiGwConfig, apiHost, axiosApiGw);
const mapper = new CookieConsentDataMapper(api);

export const cookieConsentService = new CookieConsentService(mapper);

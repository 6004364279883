import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    PerformerAchievementResponse,
    LeadersResponse,
    WinnersResponse,
    ContestWinnerResponse,
    ContestTypes,
    ContestMainProgressResponse,
} from 'domains/Achievements/Achievements.types';
import { AchievementsState } from './Achievements.types';

export const initialState: AchievementsState = {
    leaderboard: {
        global: {
            data: [],
            requestStatus: 'initial',
        },
    },
    winners: {
        data: [],
        requestStatus: 'initial',
    },
    me: {
        data: {
            globalRank: 0,
            level: 0,
            points: 0,
            missingPointsToTop: null,
        },
        requestStatus: 'initial',
    },
    contests: {
        'oranum-monthly': {
            data: { winners: [], contestPeriod: null },
            requestStatus: 'initial',
        },
        spring: {
            data: { winners: [], contestPeriod: null },
            requestStatus: 'initial',
        },
        summer: {
            data: { winners: [], contestPeriod: null },
            requestStatus: 'initial',
        },
        autumn: {
            data: { winners: [], contestPeriod: null },
            requestStatus: 'initial',
        },
        finals: {
            data: { winners: [], contestPeriod: null },
            requestStatus: 'initial',
        },
    },
    mainProgress: { data: { conversionScore: undefined, trafficScore: undefined }, requestStatus: 'initial' },
};

const achievementsSlice = createSlice({
    name: 'achievements',
    initialState,
    reducers: {
        fetchMyAchievementsStarted: (state) => {
            state.me.requestStatus = 'pending';
        },
        fetchMyAchievementsSucceeded: (state, action: PayloadAction<PerformerAchievementResponse>) => {
            state.me.requestStatus = 'resolved';
            state.me.data = action.payload.data;
        },
        fetchMyAchievementsFailed: (state) => {
            state.me.requestStatus = 'rejected';
        },
        fetchLeadersGlobalStarted: (state) => {
            state.leaderboard.global.requestStatus = 'pending';
        },
        fetchLeadersGlobalSucceeded: (state, action: PayloadAction<LeadersResponse>) => {
            state.leaderboard.global.requestStatus = 'resolved';
            state.leaderboard.global.data = action.payload.data;
        },
        fetchLeadersGlobalFailed: (state) => {
            state.leaderboard.global.requestStatus = 'rejected';
        },
        fetchWinnersStarted: (state) => {
            state.winners.requestStatus = 'pending';
        },
        fetchWinnersSucceeded: (state, action: PayloadAction<WinnersResponse>) => {
            state.winners.requestStatus = 'resolved';
            state.winners.data = action.payload.data;
        },
        fetchWinnersFailed: (state) => {
            state.winners.requestStatus = 'rejected';
        },
        fetchContestWinnersStarted: (state, action: PayloadAction<{ contestName: ContestTypes }>) => {
            const { contestName } = action.payload;
            state.contests[contestName].requestStatus = 'pending';
        },
        fetchContestWinnersSucceeded: (
            state,
            action: PayloadAction<{ data: ContestWinnerResponse['data']; contestName: ContestTypes }>,
        ) => {
            const { data, contestName } = action.payload;

            state.contests[contestName].requestStatus = 'resolved';
            state.contests[contestName].data = data;
        },
        fetchContestWinnersFailed: (state, action: PayloadAction<{ contestName: ContestTypes }>) => {
            const { contestName } = action.payload;
            state.contests[contestName].requestStatus = 'rejected';
        },

        fetchMainProgressStarted: (state) => {
            state.mainProgress.requestStatus = 'pending';
        },
        fetchMainProgressSucceeded: (state, action: PayloadAction<{ data: ContestMainProgressResponse }>) => {
            const { data } = action.payload;

            state.mainProgress.requestStatus = 'resolved';
            state.mainProgress.data = data;
        },
        fetchMainProgressFailed: (state) => {
            state.mainProgress.requestStatus = 'rejected';
        },
    },
});

export const {
    fetchMyAchievementsStarted,
    fetchMyAchievementsSucceeded,
    fetchMyAchievementsFailed,
    fetchLeadersGlobalStarted,
    fetchLeadersGlobalSucceeded,
    fetchLeadersGlobalFailed,
    fetchWinnersStarted,
    fetchWinnersSucceeded,
    fetchWinnersFailed,
    fetchContestWinnersStarted,
    fetchContestWinnersSucceeded,
    fetchContestWinnersFailed,
    fetchMainProgressStarted,
    fetchMainProgressSucceeded,
    fetchMainProgressFailed,
} = achievementsSlice.actions;

export default achievementsSlice.reducer;

import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import { useTheme } from 'contexts/ThemeContext';
import { IconList } from './IconList';
import styles from './Icon.module.scss';

type IconColorScheme = 'muted' | 'mutedWithHover' | 'mutedWithLightHover';

const defaultSize = '2.4rem';

interface IconProps extends HTMLAttributes<HTMLElement> {
    /** icon's color */
    color?: string;
    hexColor?: string;
    /** icon fontSize eg 2.4rem */
    size?: string | number;
    className?: string;
    icon: IconList;
    label?: string;
    colorScheme?: IconColorScheme;
    hideSpinner?: boolean;
    'data-testid'?: string;
    gradient?: string;
    /** adds background transparent underlay for outline icons.
     * useful for media thumbnails icons
     */
    addTransparentUnderlay?: boolean;
}

const getColor = (color?: string, hexColor?: string, colorScheme?: IconColorScheme): string | undefined => {
    if (colorScheme === 'muted') {
        return undefined;
    }

    if (hexColor) {
        return hexColor;
    }

    return color ? `rgb(var(--${color}))` : '';
};

const Icon: FC<IconProps> = (props) => {
    const {
        color = '',
        size,
        style,
        icon,
        className,
        label,
        colorScheme,
        hexColor,
        gradient,
        addTransparentUnderlay,
        ...rest
    } = props;
    const { theme } = useTheme();

    return (
        <i
            data-testid={rest['data-testid']}
            className={cn(styles.icon, styles[theme], styles[`icon-${icon}`], className, {
                [styles.iconColorSchemeMuted]: colorScheme === 'muted',
                [styles.iconColorSchemeMutedWithHover]: colorScheme === 'mutedWithHover',
                [styles.iconColorSchemeMutedWithLightHover]: colorScheme === 'mutedWithLightHover',
                [styles['with-gradient']]: gradient,
                [styles.transparentUnderlay]: addTransparentUnderlay,
            })}
            style={{
                ...style,
                fontSize: size || defaultSize,
                background: gradient,
                color: getColor(color, hexColor, colorScheme),
            }}
            aria-hidden
            aria-label={label}
            {...rest}
        />
    );
};

export default Icon;

export { IconList };
export type { IconProps, IconColorScheme };

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Category, SongsRequestParameters, SongsResponse, UISong } from 'domains/MusicLibrary/MusicLibrary.types';
import { MusicLibraryState } from './MusicLibrary.types';

export const initialState: MusicLibraryState = {
    genres: undefined,
    genresLoading: 'initial',
    genresErrorMessage: undefined,
    songs: undefined,
    songsLoading: 'initial',
    songsCurrentParams: undefined,
    songsErrorMessage: undefined,
    toggleFavoriteLoading: 'initial',
    toggleFavoriteErrorMessage: undefined,
    currentPlayingSong: undefined,
    currentPlayingSongPaused: false,
};

const musicLibrarySlice = createSlice({
    name: 'musicLibrary',
    initialState,
    reducers: {
        fetchGenresStarted: (state) => {
            state.genresLoading = 'loading';
            state.genres = undefined;
            state.genresErrorMessage = undefined;
        },
        fetchGenresSucceeded: (state, action: PayloadAction<{ genres: Category[] }>) => {
            const { genres } = action.payload;
            state.genresLoading = 'loaded';
            state.genres = genres;
        },
        fetchGenresFailed: (state, action: PayloadAction<{ message: string }>) => {
            const { message } = action.payload;
            state.genresLoading = 'loaded';
            state.genresErrorMessage = message;
        },

        fetchSongsStarted: (state, action: PayloadAction<{ songParams: SongsRequestParameters }>) => {
            state.songsLoading = 'loading';
            state.songs = undefined;
            state.songsErrorMessage = undefined;
            state.songsCurrentParams = action.payload.songParams;
        },
        fetchSongsSucceeded: (state, action: PayloadAction<{ songs: SongsResponse }>) => {
            state.songsLoading = 'loaded';
            state.songs = action.payload.songs;
            state.songsErrorMessage = undefined;
        },
        fetchSongsFailed: (state, action: PayloadAction<{ message: string }>) => {
            const { message } = action.payload;
            state.songsLoading = 'loaded';
            state.songsErrorMessage = message;
        },
        setFavoriteStarted: (state) => {
            state.toggleFavoriteLoading = 'loading';
            state.toggleFavoriteErrorMessage = undefined;
        },
        setFavoriteSucceeded: (state, action: PayloadAction<{ songId: string }>) => {
            state.toggleFavoriteLoading = 'loaded';
            state.toggleFavoriteErrorMessage = undefined;

            if (!state.songs) return;

            state.songs.songs = state.songs.songs?.map((song) => {
                if (song.id.toString() === action.payload.songId) song.isFavorite = !song.isFavorite;

                return song;
            });
        },
        setFavoriteFailed: (state, action: PayloadAction<{ message: string }>) => {
            const { message } = action.payload;
            state.toggleFavoriteLoading = 'loaded';
            state.toggleFavoriteErrorMessage = message;
        },
        setPlayerSong: (state, action: PayloadAction<{ song?: UISong; isPaused: boolean }>) => {
            const { song, isPaused } = action.payload;
            state.currentPlayingSong = song;
            state.currentPlayingSongPaused = isPaused;
        },
        resetInitialSongsState: (state) => {
            state.songs = initialState.songs;
            state.songsLoading = initialState.songsLoading;
            state.songsCurrentParams = initialState.songsCurrentParams;
            state.songsErrorMessage = initialState.songsErrorMessage;
        },
    },
});

export const {
    fetchGenresStarted,
    fetchGenresSucceeded,
    fetchGenresFailed,
    fetchSongsStarted,
    fetchSongsSucceeded,
    fetchSongsFailed,
    setFavoriteStarted,
    setFavoriteSucceeded,
    setFavoriteFailed,
    setPlayerSong,
    resetInitialSongsState,
} = musicLibrarySlice.actions;

export default musicLibrarySlice.reducer;

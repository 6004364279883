import { RequestMethods, config } from 'configs/appConfig';
import { buildRequest } from 'utils/http';
import {
    GetAuthCodeResponse,
    AuthCodeAccessTokenRequest,
    RefreshTokenRequestParameters,
    TokenRequestResponse,
} from './types';

const { POST } = RequestMethods;

export const getAuthCode = async (): Promise<GetAuthCodeResponse> => {
    return buildRequest<GetAuthCodeResponse>({
        parameters: {
            csrfToken: window.GlobalConfig.csrfToken,
        },
        host: config.api.portalHost,
        api: '/oauth2',
        pathname: '/auth-codes',
        withoutAccessToken: true,
        method: POST,
    });
};

export const requestAuthCodeAccessToken = async (
    parameters: AuthCodeAccessTokenRequest,
): Promise<TokenRequestResponse> => {
    return buildRequest({
        parameters,
        api: '/auth/oauth2',
        pathname: '/tokens',
        withoutAccessToken: true,
        method: POST,
    });
};

export const requestRefreshAccessToken = async (
    parameters: RefreshTokenRequestParameters,
): Promise<TokenRequestResponse> => {
    return buildRequest({
        parameters,
        api: '/auth/oauth2',
        pathname: '/tokens',
        withoutAccessToken: true,
        method: POST,
    });
};

import { AppThunk } from 'store/store';
import type { CookieCategory } from 'domains/CookieConsent/CookieConsent.types';
import { cookieConsentService } from 'domains/CookieConsent/CookieConsent.serviceFactory';
import {
    fetchConfigurationStarted,
    fetchConfigurationSucceeded,
    fetchConfigurationFailed,
    createCookieConsentStarted,
    createCookieConsentSucceeded,
    createCookieConsentFailed,
} from './CookieConsent.slice';

export const fetchCookieConsentConfiguration = (): AppThunk => (dispatch, getState) => {
    const status = getState().cookieConsent.configuration.requestStatus;
    if (status !== 'initial') return;

    dispatch(fetchConfigurationStarted());

    cookieConsentService
        .getCookieConsentConfigurations()
        .then((data) => {
            dispatch(fetchConfigurationSucceeded(data));
        })
        .catch(() => {
            dispatch(fetchConfigurationFailed());
        });
};

export const createCookieConsent =
    (approvedCategories: CookieCategory[], consentConfigurationId: string): AppThunk =>
    (dispatch) => {
        dispatch(createCookieConsentStarted());

        cookieConsentService
            .createCookieConsent(window.location.origin, approvedCategories, consentConfigurationId)
            .then((data) => {
                dispatch(createCookieConsentSucceeded(data));
            })
            .catch(() => {
                dispatch(createCookieConsentFailed());
            });
    };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeaturedPromoVideo } from 'domains/PromoVideos/PromoVideos.types';
import { PromoVideosState } from './PromoVideos.types';

const initialState: PromoVideosState = {
    promos: undefined,
    isLoading: false,
    errorMessage: undefined,
};

const promoVideosSlice = createSlice({
    name: 'promoVideos',
    initialState,
    reducers: {
        fetchPromoVideosStarted: (state) => {
            state.promos = undefined;
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchPromoVideosSucceeded: (state, action: PayloadAction<FeaturedPromoVideo[]>) => {
            state.promos = action.payload;
            state.isLoading = false;
        },
        fetchPromoVideosFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
    },
});

export const { fetchPromoVideosStarted, fetchPromoVideosSucceeded, fetchPromoVideosFailed } = promoVideosSlice.actions;
export default promoVideosSlice.reducer;

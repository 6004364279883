import typeOf from './typeOf';
import is from './is';

const shallowEqual = <T>(source: T, target: T): boolean => {
    if (typeOf(source) !== typeOf(target)) {
        return false;
    }

    if (is.array(source) && is.array(target)) {
        return source.length === target.length;
    }

    if (is.object(source) && is.object(target)) {
        return Object.keys(source).every((key) => source[key] === target[key]);
    }

    if (is.date(source) && is.date(target)) {
        return source.getTime() === target.getTime();
    }

    if (is.func(source) && is.func(target)) {
        return true;
    }

    return source === target;
};

export default shallowEqual;

import {
    CookieCategory as ApiCookieCategory,
    CookieConsentConfigurationsResponse as ApiCookieConsentConfigurationsResponse,
    CookieConsentConfigurationCookies as ApiCookieConsentConfigurationCookies,
    CookieApproval as ApiCookieApproval,
    CookieConsentResponseData,
} from 'apiClients/msc-api-gateway/proxy/cookieconsent/models';
import {
    CookieConsentConfiguration,
    CookieCategory,
    Cookie,
    CreateCookieConsentResponse,
    CookieApproval,
} from './CookieConsent.types';

const cookieCategoryMapToUI = new Map<ApiCookieCategory, CookieCategory>();

cookieCategoryMapToUI.set(ApiCookieCategory.Advertising, 'advertising');
cookieCategoryMapToUI.set(ApiCookieCategory.Analytical, 'analytical');
cookieCategoryMapToUI.set(ApiCookieCategory.Essential, 'essential');
cookieCategoryMapToUI.set(ApiCookieCategory.Functionality, 'functionality');
cookieCategoryMapToUI.set(ApiCookieCategory.MarketingTargeting, 'marketing_targeting');
cookieCategoryMapToUI.set(ApiCookieCategory.Performance, 'performance');

const cookieCategoryMapToApi = new Map<CookieCategory, ApiCookieCategory>();

cookieCategoryMapToApi.set('advertising', ApiCookieCategory.Advertising);
cookieCategoryMapToApi.set('analytical', ApiCookieCategory.Analytical);
cookieCategoryMapToApi.set('essential', ApiCookieCategory.Essential);
cookieCategoryMapToApi.set('functionality', ApiCookieCategory.Functionality);
cookieCategoryMapToApi.set('marketing_targeting', ApiCookieCategory.MarketingTargeting);
cookieCategoryMapToApi.set('performance', ApiCookieCategory.Performance);

export const mapCookieConsentConfigurationResponse = ({
    data,
}: ApiCookieConsentConfigurationsResponse): CookieConsentConfiguration => {
    if (!data?.items?.[0]) throw new Error('Missing cookie consent configuration!');

    const cookieConfig = data.items[0];

    return {
        id: cookieConfig.id || '',
        defaultCheckedCookieCategories: mapCheckedCookieCategories(cookieConfig.defaultCheckedCookieCategories),
        cookies: mapCookies(cookieConfig.cookies),
    };
};

const mapCheckedCookieCategories = (cookieCategories?: ApiCookieCategory[]): CookieCategory[] => {
    if (!cookieCategories) return [];

    const mappedCookieCategories: CookieCategory[] = cookieCategories.map((cookieCategory) => {
        return cookieCategoryMapToUI.get(cookieCategory)!;
    });

    return mappedCookieCategories;
};

const mapCookies = (cookies?: ApiCookieConsentConfigurationCookies[]): Cookie[] => {
    if (!cookies) return [];

    const mappedCookies: Cookie[] = cookies.map((cookie) => {
        const mappedCookie: Cookie = {
            id: cookie.id,
            category: cookie.category,
            name: cookie.name,
            provider: cookie.provider,
            purpose: cookie.purpose,
            ttl: cookie.ttl,
            isVisible: cookie.isVisible,
        };

        return mappedCookie;
    });

    return mappedCookies;
};

export const mapCategoriesToRequest = (categories: CookieCategory[]): ApiCookieCategory[] => {
    const mappedCategories: ApiCookieCategory[] = categories.map((category) => {
        return cookieCategoryMapToApi.get(category)!;
    });

    return mappedCategories;
};

export const mapCreateCookieConsent = (response: CookieConsentResponseData): CreateCookieConsentResponse => {
    const mappedResponse: CreateCookieConsentResponse = {
        id: response.id,
        url: response.url,
        cookieApprovals: mapCookieApprovals(response.cookieApprovals),
        approvedCategories: mapApprovedCategories(response.approvedCategories),
        consentConfigurationId: response.consentConfigurationId,
    };

    return mappedResponse;
};

const mapCookieApprovals = (approvals: ApiCookieApproval[]): CookieApproval[] => {
    const mappedApprovals: CookieApproval[] = approvals.map((approval) => ({
        name: approval.name || '',
        isConsentGiven: Boolean(approval.isConsentGiven),
    }));

    return mappedApprovals;
};

const mapApprovedCategories = (categories: ApiCookieCategory[]): CookieCategory[] => {
    const mappedCategories: CookieCategory[] = categories.map((category) => {
        return cookieCategoryMapToUI.get(category)!;
    });

    return mappedCategories;
};

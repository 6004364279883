import { defineTracking } from 'utils/googleAnalytics/defineTracking';

const category = 'landing page - gdpr';
const action = 'gdpr pop-up';

export default {
    ...defineTracking({
        bannerAppeared: {
            category,
            action,
            label: 'appeared',
            description: 'This event triggered when the GDPR pop-up banner appears.',
        },
        acceptCookies: {
            category,
            action,
            label: 'accept cookies',
            description: 'This event triggered when the user accepts cookies.',
        },
        rejectCookies: {
            category,
            action,
            label: 'reject cookies',
            description: 'This event triggered when the user rejects cookies.',
        },
        manageCookies: {
            category,
            action,
            label: 'manage cookies',
            description: 'This event triggered when the user chooses to manage cookies.',
        },
        viewPolicy: {
            category,
            action,
            label: 'cookie policy',
            description: 'This event triggered when the user views the cookie policy.',
        },
        confirmCookies: {
            category,
            action,
            label: 'confirm my choices: analytical {analytical}, functionality {functionality}',
            description:
                'This event triggered when the user confirms their cookie choices. {analytical} and {functionality} are placeholders for the status of the analytical and functionality cookies, respectively.',
        },
    }),
};

import { configureStore, isPlain, ThunkAction, UnknownAction } from '@reduxjs/toolkit';
import { FeatureFlagEnum } from 'configs/featureFlags';
import { getFeatureFlag } from 'utils/featureFlag';
import { getAllReducersCombined } from './rootReducer';

const isProdBuild = process.env.NODE_ENV === 'production';
const isReduxDevToolsEnabled = Boolean(getFeatureFlag(FeatureFlagEnum.EnableReduxDevTools));

const store = configureStore({
    reducer: getAllReducersCombined(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                isSerializable: (val: unknown) => {
                    if (val instanceof Date) {
                        // redux is able to serialize/deserialize dates automatically
                        return true;
                    }
                    return isPlain(val);
                },
            },
        }),
    devTools: !isProdBuild || isReduxDevToolsEnabled,
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>;

export default store;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tip } from 'domains/Tips/Tips.types';
import { EditTipState, TipsState } from './Tips.types';

export const initialState: TipsState = {
    tips: [],
    status: 'initial',
    errorMessage: undefined,
    editTipStatus: undefined,
};

const tipsSlice = createSlice({
    name: 'tips',
    initialState,
    reducers: {
        fetchTipsStarted: (state) => {
            state.status = 'pending';
            state.errorMessage = undefined;
        },
        fetchTipsSucceeded: (state, action: PayloadAction<Tip[]>) => {
            state.tips = action.payload;
            state.status = 'resolved';
        },
        fetchTipsFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.status = 'rejected';
        },
        editTipStarted: (state, action: PayloadAction<number>) => {
            const editTipStatus: EditTipState = {
                id: action.payload,
                status: 'pending',
                progress: 0,
                errorMessage: undefined,
            };
            state.editTipStatus = editTipStatus;
        },
        editTipProgress: (state, action: PayloadAction<number>) => {
            if (!state.editTipStatus) {
                return;
            }
            let progress = action.payload;
            if (progress < 0) {
                progress = 0;
            } else if (progress > 100) {
                progress = 100;
            }
            state.editTipStatus.progress = progress;
        },
        editTipSucceeded: (state) => {
            if (!state.editTipStatus) {
                return;
            }
            state.editTipStatus.status = 'resolved';
            state.editTipStatus.progress = 100;
        },
        editTipFailed: (state, action: PayloadAction<string>) => {
            if (!state.editTipStatus) {
                return;
            }
            state.editTipStatus.status = 'rejected';
            state.editTipStatus.errorMessage = action.payload;
        },
        clearAndRemoveEditTip: (state) => {
            const { editTipStatus } = state;
            if (!editTipStatus?.id) {
                return;
            }
            state.tips = (state.tips || []).filter((tip) => tip.id !== editTipStatus.id);
            state.editTipStatus = undefined;
        },
    },
});

export const {
    fetchTipsStarted,
    fetchTipsSucceeded,
    fetchTipsFailed,
    editTipStarted,
    editTipProgress,
    editTipSucceeded,
    editTipFailed,
    clearAndRemoveEditTip,
} = tipsSlice.actions;
export default tipsSlice.reducer;

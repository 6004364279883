import dispatchToasts, { DispatchToastNames } from './Toasts.content';
import { TOAST_EVENT_NAME } from './Toasts.config';

const dispatchToastEvent = (toastName: DispatchToastNames) => {
    if (dispatchToasts?.[toastName]) {
        window.dispatchEvent(new CustomEvent(TOAST_EVENT_NAME, { detail: dispatchToasts[toastName] }));
    }
};

export default dispatchToastEvent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DownloadJasminCamState } from './DownloadJasminCam.types';

const initialState: DownloadJasminCamState = {
    link: '',
    status: 'initial',
    errorMessage: undefined,
};

const downloadJasminCamSlice = createSlice({
    name: 'downloadJasminCam',
    initialState,
    reducers: {
        fetchLinkStarted: (state) => {
            state.link = '';
            state.status = 'pending';
            state.errorMessage = undefined;
        },
        fetchLinkSucceeded: (state, action: PayloadAction<string>) => {
            state.link = action.payload;
            state.status = 'resolved';
        },
        fetchLinkFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.status = 'rejected';
        },
    },
});

export const { fetchLinkStarted, fetchLinkSucceeded, fetchLinkFailed } = downloadJasminCamSlice.actions;
export default downloadJasminCamSlice.reducer;

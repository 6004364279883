import { FeatureFlagEnum } from 'configs/featureFlags';
import { globalConfig } from 'configs/globalConfig';

export const serializeFlags = (flags: string | undefined) => {
    return Object.entries(flags ?? {})
        .map(([key, value]) => `${key}=${value ? '1' : '0'}`)
        .join(';');
};

export const getFeatureFlag = (featureFlag: FeatureFlagEnum): boolean | string => {
    const flags = JSON.parse(globalConfig?.enabledFeatureFlags || '{}');

    if (!flags) {
        return false;
    }

    return flags[featureFlag];
};

export const getEnabledFeatureFlagsSerialized = (featureFlags = globalConfig?.enabledFeatureFlags): string => {
    const flags = JSON.parse(featureFlags || '{}');
    return serializeFlags(flags);
};

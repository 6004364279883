import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DecemberContestStats } from 'domains/DecemberContest/DecemberContest.types';
import { DecemberContestState } from './DecemberContest.types';

export const initialState: DecemberContestState = {
    getDecemberContestStats: {
        requestStatus: 'initial',
    },
};

const decemberContestSlice = createSlice({
    name: 'decemberContest',
    initialState,
    reducers: {
        getDecemberContestStatsStarted: (state) => {
            state.getDecemberContestStats.requestStatus = 'pending';
        },

        getDecemberContestStatsSucceeded: (state, action: PayloadAction<DecemberContestStats>) => {
            state.getDecemberContestStats.data = action.payload;
            state.getDecemberContestStats.requestStatus = 'resolved';
        },
        getDecemberContestStatsFailed: (state) => {
            state.getDecemberContestStats.data = undefined;
            state.getDecemberContestStats.requestStatus = 'rejected';
        },
    },
});

export const { getDecemberContestStatsStarted, getDecemberContestStatsSucceeded, getDecemberContestStatsFailed } =
    decemberContestSlice.actions;

export default decemberContestSlice.reducer;

import React, { FC, useState } from 'react';
import Icon, { IconProps, IconList } from '../Icon';

interface SmartIconProps extends IconProps {
    iconActive?: IconList;
    isActive?: boolean;
}

const SmartIcon: FC<SmartIconProps> = (props) => {
    const { icon, iconActive, isActive, ...rest } = props;
    const [isHovering, setIsHovering] = useState(false);

    return (
        <span
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            data-testid="smart-icon"
        >
            {iconActive && (isActive || isHovering) ? (
                <Icon icon={iconActive} {...rest} />
            ) : (
                <Icon icon={icon} {...rest} />
            )}
        </span>
    );
};

export default SmartIcon;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Permissions } from 'domains/Permissions/Permissions.types';
import { PermissionsState } from './Permissions.types';

export const initialState: PermissionsState = {
    permissions: {
        scheduledShows: null,
    },
    requestStatus: 'initial',
};

const permissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        fetchPermissionsStarted: (state) => {
            state.requestStatus = 'pending';
        },
        fetchPermissionsSucceeded: (state, action: PayloadAction<Permissions>) => {
            state.requestStatus = 'resolved';
            state.permissions = action.payload;
        },
        fetchPermissionsFailed: (state) => {
            state.requestStatus = 'rejected';
        },
    },
});

export const { fetchPermissionsStarted, fetchPermissionsSucceeded, fetchPermissionsFailed } = permissionsSlice.actions;

export default permissionsSlice.reducer;

/* eslint-disable max-statements */
/* eslint-disable complexity */
import { RequestMethods, RequestContentTypes, RequestProtocols, config, RestStatusCodesEnum } from 'configs/appConfig';
import { stringify } from 'qs';
import { getCookieData, getAuthToken } from './auth';

/**
 * Defines api request options that is used to build an api call.
 */
export interface RequestOptions {
    api?: string;
    protocol?: RequestProtocols;
    host?: string;
    pathname: string;
    method: RequestMethods;
    variables?: object;
    parameters?: object;
    noCache?: boolean;
    contentType?: RequestContentTypes;
    withoutAccessToken?: boolean;
}

const RETRY_LIMIT = 3;

/**
 * Build an api call based on the provider api endpoint options.
 */
export const buildRequest = async <T>(options: RequestOptions, forceAccessToken?: string): Promise<T> => {
    const { api, pathname, method, contentType = RequestContentTypes.JSON, withoutAccessToken } = options;
    const { parameters } = options;

    /**
     * Request URL is the URL that we will call and all the variable is replaced to values
     */
    const path = [api, pathname].join('');
    const protocol = options.protocol || config.api.protocol;
    const host = options.host || config.api.host;
    const url = [protocol, '://', host, path].join('');
    let requestUrl = api ? url : pathname;

    /**
     * Prepare parameters that will be sent to the api.
     */
    let body: string | FormData | undefined;
    if (parameters && method === RequestMethods.GET) {
        requestUrl += `?${stringify(parameters)}`;
    }

    if (parameters && method !== RequestMethods.GET) {
        switch (contentType) {
            case RequestContentTypes.JSON:
                body = JSON.stringify(parameters);
                break;
            case RequestContentTypes.FORM:
                body = new FormData();
                for (const parameter of Object.keys(parameters)) {
                    body.append(parameter, (parameters as { [key: string]: string | Blob })[parameter]);
                }
                break;
        }
    }

    /**
     * Prepare headers
     */
    const headers: { [key: string]: string } = {
        Accept: 'application/json',
    };

    if (contentType !== '') {
        headers['Content-Type'] = contentType;
    }

    let statusCode: number;
    let accessToken = forceAccessToken;
    /**
     * Get access token if we don't have a forced token and the request needs a token
     */

    if (!accessToken && !withoutAccessToken) {
        const authToken = await getAuthToken();
        if (authToken && authToken.accessToken) {
            accessToken = authToken.accessToken;
        }
    }

    /**
     * If there is access token, it sends it in header to the backend
     */
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    /**
     * Call request with native fetch and format the response to json. If there is an error,
     * that it would try to format the error response to json and send that forward.
     */
    try {
        let retryCount = 0;
        let response;

        do {
            const commonFetchParams = {
                headers,
                body,
                method: RequestMethods[method],
            };
            const portalOnlyFetchParams = {
                cache: 'no-store',
                credentials: 'same-origin',
            };
            const fetchParams =
                host === config.api.portalHost ? { ...commonFetchParams, ...portalOnlyFetchParams } : commonFetchParams;
            /* eslint-disable-next-line no-await-in-loop */
            response = await fetch(requestUrl, fetchParams);

            if (response.status !== RestStatusCodesEnum.Unauthorized || withoutAccessToken) {
                break;
            } else {
                /* eslint-disable max-depth */
                if (!withoutAccessToken) {
                    const newAuthToken = getCookieData();
                    if (newAuthToken && newAuthToken.accessToken) {
                        headers.Authorization = `Bearer ${newAuthToken.accessToken}`;
                    }
                }
                /* eslint-enable max-depth */
                retryCount++;
            }
        } while (retryCount < RETRY_LIMIT);

        if (!response.ok) {
            throw response;
        }

        /**
         * Save status code for later
         */
        statusCode = response.status;

        let jsonResponse;
        try {
            if (contentType === RequestContentTypes.BLOB) {
                jsonResponse = await response.blob();
            } else {
                jsonResponse = await response.json();
            }
        } catch (e) {
            /**
             * Response is not json
             */
            jsonResponse = {};
        }

        if (contentType !== RequestContentTypes.BLOB) {
            jsonResponse = {
                ...jsonResponse,
                get _apiResponseCode(): number {
                    return statusCode;
                },
            };
        }

        return jsonResponse;
    } catch (error) {
        const statusCode = error.status;

        let finalError;
        try {
            finalError = await error.json();
        } catch (e) {
            finalError = error;
        }

        /**
         * Create a getter in a response that will return with the status code of the response
         */
        finalError = {
            ...finalError,
            get _apiResponseCode(): number {
                return statusCode;
            },
        };
        throw finalError;
    }
};

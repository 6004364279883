import { Products } from './globalConfig';

export const getDomain = (): Products => {
    if (!window.location || !window.location.hostname) {
        return 'livejasmin';
    }
    if (window.location.hostname.includes('livejasmin')) {
        return 'livejasmin';
    }
    if (window.location.hostname.includes('lj')) {
        return 'livejasmin';
    }
    if (window.location.hostname.includes('oranum')) {
        return 'oranum';
    }
    return 'livejasmin';
};

/** This variable is strictly for non react code. In react use `useTheme()` hook */
export const domain = getDomain();
/** This variable is strictly for non react code. In react use `useTheme()` hook */
export const isLiveJasmin = domain === 'livejasmin';
/** This variable is strictly for non react code. In react use `useTheme()` hook */
export const isOranum = domain === 'oranum';

export const getProduct = (): Products => {
    return getDomain() === 'oranum' ? 'oranum' : 'livejasmin';
};

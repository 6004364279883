import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionRequest, ActionRequestsStatus, ActionTier } from 'domains/ActionRequests/ActionRequests.types';
import { ActionRequestsSlice } from './ActionRequests.types';

const initialState: ActionRequestsSlice = {
    isLoading: true,
    fetchActionRequestsStatus: 'initial',
    isUpdating: false,
    actionRequestStates: [],
    hasTiers: false,
    actionRequests: undefined,
    actionTiers: undefined,
    errorCode: undefined,
};

const actionRequestsSlice = createSlice({
    name: 'actionRequests',
    initialState,
    reducers: {
        fetchActionRequestsStarted: (state) => {
            state.isLoading = true;
            state.fetchActionRequestsStatus = 'pending';
            state.errorCode = undefined;
            state.actionRequests = [];
        },
        fetchActionRequestsSucceeded: (state, action: PayloadAction<ActionRequest[]>) => {
            const { payload: actionRequests } = action;

            state.isLoading = false;
            state.fetchActionRequestsStatus = 'resolved';
            state.actionRequests = action.payload;
            state.actionRequestStates = actionRequests.map((action) => ({
                id: action.actionId,
                isLoading: false,
            }));
        },
        fetchActionRequestsFailed: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.fetchActionRequestsStatus = 'rejected';
            state.errorCode = action.payload;
        },
        updateActionRequestStarted: (state, action: PayloadAction<number>) => {
            state.isUpdating = true;
            state.errorCode = undefined;

            const entry = state.actionRequestStates.find((state) => state.id === action.payload);
            if (entry) entry.isLoading = true;
        },
        updateActionRequestSucceeded: (
            state,
            action: PayloadAction<{ actionId: number; price: number; status: ActionRequestsStatus }>,
        ) => {
            const { actionId, price, status } = action.payload;
            state.isUpdating = false;
            if (state.actionRequests) {
                state.actionRequests
                    .filter((action) => action.actionId === actionId)
                    // eslint-disable-next-line array-callback-return
                    .map((action) => {
                        action.isActive = status === 'enabled';
                        action.price = price;
                    });
            }

            if (state.actionTiers) {
                // eslint-disable-next-line array-callback-return
                state.actionTiers.filter((tier) => {
                    tier.actions
                        .filter((action) => action.actionId === actionId)
                        .map((action) => {
                            action.isActive = status === 'enabled';
                            action.price = price;
                            return null;
                        });
                });
            }

            const entry = state.actionRequestStates.find((state) => state.id === actionId);
            if (entry) entry.isLoading = false;
        },
        updateActionRequestFailed: (state, action: PayloadAction<{ actionId: number; errorCode: number }>) => {
            const { actionId, errorCode } = action.payload;

            state.isUpdating = false;
            state.errorCode = errorCode;

            const entry = state.actionRequestStates.find((state) => state.id === actionId);
            if (entry) entry.isLoading = false;
        },
        fetchActionTiersStarted: (state) => {
            state.isLoading = true;
            state.errorCode = undefined;
            state.actionTiers = [];
        },
        fetchActionTiersSucceeded: (state, action: PayloadAction<ActionTier[]>) => {
            // @ts-ignore [flat]
            const mappedActions = action.payload.flatMap((tier) => {
                // @ts-ignore [flat]
                return tier.actions.map((action) => ({
                    id: action.actionId,
                    isLoading: false,
                }));
            });

            state.isLoading = false;
            state.errorCode = undefined;
            state.actionTiers = action.payload;
            state.actionRequestStates = mappedActions;
            state.hasTiers = action.payload.length > 1;
        },
        fetchActionTiersFailed: (state, action: PayloadAction<number>) => {
            state.isLoading = false;
            state.errorCode = action.payload;
        },
    },
});

export const {
    fetchActionRequestsStarted,
    fetchActionRequestsSucceeded,
    fetchActionRequestsFailed,
    updateActionRequestStarted,
    updateActionRequestSucceeded,
    updateActionRequestFailed,
    fetchActionTiersStarted,
    fetchActionTiersSucceeded,
    fetchActionTiersFailed,
} = actionRequestsSlice.actions;
export default actionRequestsSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodStats } from 'domains/PeriodStatistics/PeriodStatistics.types';
import { PeriodStatisticsState } from './PeriodStatistics.types';

const initialState: PeriodStatisticsState = {
    stats: undefined,
    status: 'initial',
    errorMessage: null,
};

const periodStatisticsSlice = createSlice({
    name: 'periodStatistics',
    initialState,
    reducers: {
        fetchPeriodStatisticsStarted: (state) => {
            state.status = 'pending';
            state.errorMessage = null;
            state.stats = undefined;
        },
        fetchPeriodStatisticsSucceeded: (state, action: PayloadAction<PeriodStats>) => {
            state.stats = action.payload;
            state.status = 'resolved';
        },
        fetchPeriodStatisticsFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.status = 'rejected';
        },
    },
});

export const { fetchPeriodStatisticsStarted, fetchPeriodStatisticsSucceeded, fetchPeriodStatisticsFailed } =
    periodStatisticsSlice.actions;
export default periodStatisticsSlice.reducer;

import { TagGroup } from 'domains/TagGroups/TagGroups.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TagGroupsState, TagUpdate } from './TagGroups.types';

export const initialState: TagGroupsState = {
    requestStatus: 'initial',
    groups: [],
};

const tagGroupsSlice = createSlice({
    name: 'TagGroups',
    initialState,
    reducers: {
        fetchTagGroupsStarted: (state) => {
            state.requestStatus = 'pending';
        },
        fetchTagGroupsSucceeded: (state, action: PayloadAction<TagGroup[]>) => {
            state.requestStatus = 'resolved';
            state.groups = action.payload;
        },
        fetchTagGroupFailed: (state) => {
            state.requestStatus = 'rejected';
            state.groups = [];
        },
        updateTagGroups: (state, action: PayloadAction<TagUpdate>) => {
            state.requestStatus = 'resolved';
            state.groups = updateGroups(state.groups, action.payload);
        },
    },
});

const updateGroups = (groups: TagGroup[], { groupName, selectedIds }: TagUpdate): TagGroup[] => {
    const current = groups.filter((item) => item.name === groupName)[0];
    if (!current) return [...groups];

    current.tags.forEach((tag) => {
        tag.isChecked = selectedIds.includes(tag.id);
    });

    return [...groups];
};

export const { fetchTagGroupsStarted, fetchTagGroupsSucceeded, fetchTagGroupFailed, updateTagGroups } =
    tagGroupsSlice.actions;
export default tagGroupsSlice.reducer;

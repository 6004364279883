import { CookieConsentsApiInterface } from 'apiClients/msc-api-gateway/proxy/cookieconsent/client/cookie-consents-api';
import { CookieCategory as ApiCookieCategory } from 'apiClients/msc-api-gateway/proxy/cookieconsent/models';
import {
    mapCategoriesToRequest,
    mapCookieConsentConfigurationResponse,
    mapCreateCookieConsent,
} from './CookieConsent.mapper';
import { CookieConsentDataMapperInterface } from './CookieConsent.service';
import { CookieCategory, CookieConsentConfiguration, CreateCookieConsentResponse } from './CookieConsent.types';

export class CookieConsentDataMapper implements CookieConsentDataMapperInterface {
    constructor(private apiClient: CookieConsentsApiInterface) {}

    public getCookieConsentConfigurations(): Promise<CookieConsentConfiguration> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .findCookieConsentConfigurations()
                .then((response) => {
                    resolve(mapCookieConsentConfigurationResponse(response.data));
                })
                .catch(reject);
        });
    }

    public createCookieConsent(
        url: string,
        approvedCategories: CookieCategory[],
        consentConfigurationId: string,
    ): Promise<CreateCookieConsentResponse> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .createCookieConsent({
                    url,
                    approvedCategories: [ApiCookieCategory.Essential, ...mapCategoriesToRequest(approvedCategories)],
                    consentConfigurationId,
                })
                .then((response) => {
                    if (!response.data.data) {
                        reject(new Error('Backend error: No data in response'));
                        return;
                    }

                    resolve(mapCreateCookieConsent(response.data.data));
                })
                .catch(reject);
        });
    }

    public createCookieConsentConfigurationCookies(
        consentConfigurationId: string,
        cookieNames: string[],
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            this.apiClient
                .createCookieConsentConfigurationCookies(consentConfigurationId, {
                    cookies: cookieNames.map((cookieName) => ({ name: cookieName })),
                })
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    }
}

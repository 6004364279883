export interface AwardsGamesEntity {
    data: GameItem[];
}

export interface GameItem {
    id: string;
    type: GameTypeEnum;
    status: GameStatusEnum;
    expiresAt: string;
}

export interface AwardsCompetitorsEntity {
    performerPosition: CompetitorsItem | null;
    data: CompetitorsItem[];
    gameType: GameTypeEnum;
}

export interface AwardsStandingsEntity {
    data: StandingsItem[];
}

export interface AwardsExcludedPerformersEntity {
    id: string;
    mscPerformerId: number;
    gameType: string;
    reason: string;
    createdAt: string | null;
    updatedAt: string | null;
}

export interface AwardsExcludedGamesEntity {
    games: GameTypeEnum[];
}

export interface StandingsItem {
    game: GameItem;
    position: CompetitorsItem;
}

export interface CompetitorsItem {
    position: number;
    creditReward: number;
    points: number;
    performer: Performer;
}

export interface Performer {
    id: number;
    displayName: string;
    profilePicture: string;
    necessaryPointsToBeInTop: number;
}

export enum GameTypeEnum {
    UnhandledGame = 'UnhandledGame',
    Girl = 'Girl',
    Boy = 'Boy',
    Guy = 'Guy',
    Trans = 'Trans',
    Couple = 'Couple',
    Lesbian = 'Lesbian',
    Milf = 'Milf',
    Asian = 'Asian',
    Fetish = 'Fetish',
    SoulMate = 'SoulMate',
    HotFlirt = 'HotFlirt',
    FreeShow = 'FreeShow',
    Newbies = 'Newbie',
    RealGifts = 'RealGifts',
    VideoCallGame = 'VideoCallGame',
    MessagesGame = 'MessagesGame',
    MyStoryGame = 'MyStoryGame',
    NewModelsGame = 'NewModelsGame',
    DailyAwards = 'DailyAwards',
    InfluencerTestGame = 'InfluencerTestGame',
    PicturesGame = 'PicturesGame',
    VideosGame = 'VideosGame',
    InteractiveToyGame = 'InteractiveToy',
    ActionsGame = 'Actions',
    TipsGame = 'Tips',
    GoodieBasketGame = 'GoodieBasket',
    FanClubGame = 'FanClub',
    BimbimDailyAwards = 'BimbimDailyAwards',
    BimbimWeeklyVideoCallAwards = 'BimbimWeeklyVideoCallAwards',
    BimbimWeeklyMessagesAwards = 'BimbimWeeklyMessagesAwards',
    BimbimWeeklyHighlightsAwards = 'BimbimWeeklyHighlightsAwards',
    BimbimWeeklyNewModelsAwards = 'BimbimWeeklyNewModelsAwards',
    BimbimWeeklySubscriptionAwards = 'BimbimWeeklySubscriptionAwards',
    BimbimWeeklySurprisesAwards = 'BimbimWeeklySurprisesAwards',
    BimbimWeeklyActionsAwards = 'BimbimWeeklyActionsAwards',
    BimbimWeeklyHotShowAwards = 'BimbimWeeklyHotShowAwards',
    BimbimWeeklyBattleAwards = 'BimbimWeeklyBattleAwards',
    JasminWeeklyVideoCallAwards = 'JasminWeeklyVideoCallAwards',
    JasminWeeklyMessagesAwards = 'JasminWeeklyMessagesAwards',
    JasminWeeklyHighlightsAwards = 'JasminWeeklyHighlightsAwards',
    JasminWeeklyNewModelsAwards = 'JasminWeeklyNewModelsAwards',
    JasminWeeklySubscriptionAwards = 'JasminWeeklySubscriptionAwards',
    JasminWeeklySurprisesAwards = 'JasminWeeklySurprisesAwards',
    JasminWeeklyActionsAwards = 'JasminWeeklyActionsAwards',
    JasminWeeklyHotShowAwards = 'JasminWeeklyHotShowAwards',
    OranumWeeklyVideoCallAwards = 'OranumWeeklyVideoCallAwards',
    OranumWeeklyMessagesAwards = 'OranumWeeklyMessagesAwards',
    OranumWeeklyHighlightsAwards = 'OranumWeeklyHighlightsAwards',
    OranumWeeklyNewModelsAwards = 'OranumWeeklyNewModelsAwards',
    OranumWeeklySurprisesAwards = 'OranumWeeklySurprisesAwards',
    DoclerWeeklyVideoCallAwards = 'DoclerWeeklyVideoCallAwards',
    DoclerWeeklyMessagesAwards = 'DoclerWeeklyMessagesAwards',
    DoclerWeeklyHighlightsAwards = 'DoclerWeeklyHighlightsAwards',
    DoclerWeeklyNewModelsAwards = 'DoclerWeeklyNewModelsAwards',
    DoclerWeeklySurprisesAwards = 'DoclerWeeklySurprisesAwards',
    VideoCallConversion = 'VideoCallConversion',
    FreeChatConversion = 'FreeChatConversion',
    HotDealConversion = 'HotDealConversion',
    VipShowConversion = 'VIPShowConversion',
    TeaserConversion = 'TeaserConversion',
    FreeShorts = 'FreeShorts',
    PremiumShorts = 'PremiumShorts',
    PersonalSpace = 'PersonalSpace',
    PublicLocations = 'PublicLocations',
    Nature = 'Nature',
    Wellness = 'Wellness',
    RisingStar = 'RisingStar',
    IconGame = 'Icon',
    Superstar = 'Superstar',
    NewbieRisingStarHoliday = 'NewbieRisingStarHoliday',
    IconSuperstarHoliday = 'IconSuperstarHoliday',
}

export enum GameStatusEnum {
    Active = 'active',
    Finished = 'finished',
}

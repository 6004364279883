import React, { FC, useState } from 'react';
import classnames from 'classnames';
import type { CookieCategory } from 'domains/CookieConsent/CookieConsent.types';
import { useTheme } from 'contexts/ThemeContext';
import Button from 'components/Button';
import Switch from 'components/Switch';
import Icon, { IconList } from 'components/Icon';
import content from './CookieConsentDialog.content';
import type { CookieConsentSelections } from '../../CookieConsent.types';
import styles from './CookieConsentDialog.module.scss';

interface CookieConsentDialogProps {
    selections: CookieConsentSelections;
    onToggleClick: (category: CookieCategory, isEnabled: boolean) => void;
    onConfirmClick: () => void;
    loading?: boolean;
}

const enum InfoBox {
    None = -1,
    Essential = 0,
    Analytical = 1,
    Functionality = 2,
}

const CookieConsentDialog: FC<CookieConsentDialogProps> = ({ selections, onToggleClick, onConfirmClick, loading }) => {
    const [selectedInfoBox, setSelectedInfoBox] = useState<InfoBox>(InfoBox.None);
    const { theme } = useTheme();

    const handleToggleClick = (category: CookieCategory) => {
        onToggleClick(category, !selections[category]);
    };

    const toggleInfoBox = (infoBox: InfoBox): void => {
        setSelectedInfoBox((oldVal) => (oldVal !== infoBox ? infoBox : InfoBox.None));
    };

    return (
        <div className={classnames(styles.wrapper, styles[theme])} data-testid="cookie-consent-dialog">
            <div className={classnames(styles.cookieDialog)}>
                <h4 className={styles.headerText}>{content.infoHeader}</h4>
                <p className={classnames(styles.bodyText, styles.infoText)}>{content.infoBody}</p>
                <h4 className={styles.headerText}>{content.controlsHeader}</h4>
                <div className={styles.bodyText}>
                    <span>{content.essentialLabel}</span>
                    <span className={styles.preferenceText}>{content.essentialStatus}</span>
                    <button
                        className={styles.preferenceToggleWrapper}
                        type="button"
                        data-testid="cookie-consent-dialog-essential-infobox-toggle"
                        onClick={() => toggleInfoBox(InfoBox.Essential)}
                    >
                        <Icon
                            className={classnames(styles.preferenceToggle, {
                                [styles.selected]: selectedInfoBox === InfoBox.Essential,
                            })}
                            icon={IconList.caretDown}
                            data-testid="info-icon"
                        />
                    </button>
                </div>
                <div
                    data-testid="cookie-consent-dialog-essential-infobox"
                    className={classnames(styles.infoBox, { [styles.hidden]: selectedInfoBox !== InfoBox.Essential })}
                >
                    {content.essentialInfo}
                </div>
                <div className={classnames(styles.switchText, styles.bodyText)}>
                    <span>{content.analyticalLabel}</span>
                    <span className={styles.switchContainer}>
                        <Switch
                            data-testid="cookie-consent-dialog-analytical-toggle"
                            checked={selections.analytical}
                            onClick={() => handleToggleClick('analytical')}
                            disabled={loading}
                        />
                    </span>
                    <button
                        className={styles.preferenceToggleWrapper}
                        type="button"
                        data-testid="cookie-consent-dialog-analytical-infobox-toggle"
                        onClick={() => toggleInfoBox(InfoBox.Analytical)}
                    >
                        <Icon
                            className={classnames(styles.preferenceToggle, {
                                [styles.selected]: selectedInfoBox === InfoBox.Analytical,
                            })}
                            icon={IconList.caretDown}
                            data-testid="info-icon"
                        />
                    </button>
                </div>
                <div
                    data-testid="cookie-consent-dialog-analytical-infobox"
                    className={classnames(styles.infoBox, { [styles.hidden]: selectedInfoBox !== InfoBox.Analytical })}
                >
                    {content.analyticalInfo}
                </div>
                <div className={classnames(styles.switchText, styles.bodyText)}>
                    <span>{content.functionalityLabel}</span>
                    <span className={styles.switchContainer}>
                        <Switch
                            data-testid="cookie-consent-dialog-functionality-toggle"
                            checked={selections.functionality}
                            onClick={() => handleToggleClick('functionality')}
                            disabled={loading}
                        />
                    </span>
                    <button
                        className={styles.preferenceToggleWrapper}
                        type="button"
                        data-testid="cookie-consent-dialog-functionality-infobox-toggle"
                        onClick={() => toggleInfoBox(InfoBox.Functionality)}
                    >
                        <Icon
                            className={classnames(styles.preferenceToggle, {
                                [styles.selected]: selectedInfoBox === InfoBox.Functionality,
                            })}
                            icon={IconList.caretDown}
                            data-testid="info-icon"
                        />
                    </button>
                </div>
                <div
                    data-testid="cookie-consent-dialog-functionality-infobox"
                    className={classnames(styles.infoBox, {
                        [styles.hidden]: selectedInfoBox !== InfoBox.Functionality,
                    })}
                >
                    {content.functionalityInfo}
                </div>
                <Button
                    data-testid="cookie-consent-dialog-confirm-button"
                    className={styles.confirmButton}
                    onClick={onConfirmClick}
                    disabled={loading}
                >
                    {content.confirm}
                </Button>
            </div>
        </div>
    );
};

export type { CookieConsentDialogProps };
export default CookieConsentDialog;

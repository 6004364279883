import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewsData } from 'domains/News/News.types';
import { LatestNewsState } from './LatestNews.types';

const initialState: LatestNewsState = {
    news: [],
    errorMessage: null,
    status: 'initial',
};

const latestNewsSlice = createSlice({
    name: 'latestNews',
    initialState,
    reducers: {
        fetchLatestNewsStarted: (state) => {
            state.news = [];
            state.errorMessage = null;
            state.status = 'loading';
        },
        fetchLatestNewsSucceeded: (state, action: PayloadAction<NewsData[]>) => {
            state.news = action.payload;
            state.errorMessage = null;
            state.status = 'resolved';
        },
        fetchLatestNewsFailed: (state, action: PayloadAction<string>) => {
            state.news = [];
            state.errorMessage = action.payload;
            state.status = 'failed';
        },
    },
});

export const { fetchLatestNewsStarted, fetchLatestNewsSucceeded, fetchLatestNewsFailed } = latestNewsSlice.actions;
export default latestNewsSlice.reducer;

import deepmerge from 'deepmerge';

const overwriteMerge = (destArray: [], sourceArray: []) => sourceArray;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const merge = (source: any, overwrites: any): any => {
    return deepmerge(source, overwrites, { arrayMerge: overwriteMerge });
};

export default merge;

import React, { FC } from 'react';
import classnames from 'classnames';
import { useTheme } from 'contexts/ThemeContext';
import styles from './Switch.module.scss';

export interface SwitchProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    checked?: boolean;
    onLabel?: string;
    offLabel?: string;
    disabled?: boolean;
    className?: string;
}

const Switch: FC<SwitchProps> = ({ checked = false, onLabel, offLabel, disabled, className, ...props }) => {
    const { theme } = useTheme();

    const onLabelFallback = onLabel || 'On';
    const offLabelFallback = offLabel || 'Off';
    return (
        <button
            type="button"
            role="switch"
            aria-checked={checked}
            className={classnames(
                styles[theme],
                styles.root,
                styles.switchButton,
                className,
                checked ? styles.on : styles.off,
                {
                    [styles.disabled]: disabled,
                },
            )}
            data-testid="switch"
            disabled={disabled}
            {...props}
        >
            <div className={styles.handle} data-testid="switch-handle" />
            <div className={styles.innerLabel} data-testid="switch-label">
                {checked ? onLabelFallback : offLabelFallback}
            </div>
        </button>
    );
};

export default Switch;

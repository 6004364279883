import {
    CallInfoEvent,
    PartnerTypeEnum,
    MediaStatusEnum,
    OfflineSurpriseVariant,
} from 'domains/Messenger/Messages.types';
import { CurrencyType } from 'domains/Common.types';

interface JawsParticipant {
    id: number;
    /* eslint-disable camelcase */
    display_name: string;
    /* eslint-enable camelcase */
    name: string;
    type: PartnerTypeEnum;
}

export enum MessageEvents {
    MEMBER_TO_PERFORMER_LIVE = 'notification.performer.member.message.delivery.live',
    MEMBER_TO_PERFORMER_HISTORY = 'notification.performer.member.message.delivery.history',
    PERFORMER_TO_MEMBER_LIVE = 'notification.member.performer.message.delivery.live',
    PERFORMER_TO_MEMBER_HISTORY = 'notification.member.performer.message.delivery.history',
    PERFORMER_MESSAGE_SENT = 'notification.performer.message.sent.delivery.live',
    MEMBER_MESSAGE_SENT = 'notification.member.message.sent.delivery.live',
    MESSENGER_MESSAGE_UPDATED = 'notification.messenger.message-updated.delivery.live',
}

interface JawsResponseHeader {
    event: MessageEvents;
    'eds-client': string;
    'eds-endpoint': string;
    'eds-notification-id': string;
}

export enum TypesEnum {
    MEDIA_CONTENT = 'media-content',
    TEXT = 'text',
    OFFLINE_SURPRISE = 'offline-surprise',
    CALL_INFO = 'call-info',
    GIFT = 'gift',
    STORY_REPLY = 'story-reply',
    GIFT_BASKET = 'gift-basket',
}

export enum MediaTypesEnum {
    VIDEO = 'video',
    IMAGE = 'image',
    AUDIO = 'audio',
}

export enum FileTypeEnums {
    SVG = 'svg',
    PNG = 'png',
    SWF = 'swf',
    FLA = 'fla',
    JS = 'js',
}

interface Asset {
    id: string;
    path: string;
    fileType: FileTypeEnums;
}

interface VideoContent {
    fileFormat: string;
    fileSize: number;
    width: number;
    height: number;
    aspectRatio: string;
    duration: number;
    durationFloat: string;
    fps: string;
    frames: string;
    meanVolume: string;
    maxVolume: string;
    rotation: string;
    videoCodec: string;
    audioCodec: string;
    videoBitrate: string;
    audioBitrate: string;
    audioChannels: string;
    uri: string;
}

interface AudioContent {
    format: string;
    fileSize: number;
    bitRate: number;
    duration: number;
    uri: string;
}

interface PreviewObject {
    format: string;
    fileSize: number;
    width: number;
    height: number;
    mainColor: string;
    uri: string;
}

export interface JawsMessageResponse<T> {
    /* eslint-disable camelcase */
    body: {
        createdAt: string;
        senderId: number;
        senderType: number;
        senderName: string;
        senderDisplay_name: string;
        receiverId: number;
        receiverType: number;
        receiverName: string;
        receiverDisplay_name: string;
        id: number;
        mailId: number;
        threadId: number;
        participant: JawsParticipant;
        recipient: JawsParticipant;
        partner: string;
        created_at: string;
        timestamp: number;
        body: string;
        message: string;
        data: T;
        status: string;
        visible: number;
        is_sender_blocked: number;
        isPinned: number;
        unreadMessageCount: number;
        unreadThreadCount: number;
    };
    /* eslint-enable camelcase */
    header: JawsResponseHeader;
}

export interface CommonJawsMessage {
    type: TypesEnum;
    message: string;
    price: number;
    mediaType?: MediaTypesEnum;
}

export interface AudioJawsMessage extends CommonJawsMessage {
    uploadToken: string;
    status: MediaStatusEnum;
    statusChangedAt: string;
    mediaInfo: {
        content: AudioContent;
        previewUri: string;
        contentUri: string;
        mobileContentUri: string;
        mobileContent: AudioContent;
    };
}

export interface CallJawsMessage extends CommonJawsMessage {
    event: CallInfoEvent;
    version: number;
}

export interface ImageJawsMessage extends CommonJawsMessage {
    uploadToken: string;
    status: MediaStatusEnum;
    statusChangedAt: string;
    creditType: CurrencyType;
    linkedAttachmentId: number;
    mediaInfo: {
        preview: PreviewObject;
        content: PreviewObject;
        fullHD: PreviewObject;
        previewUri: string;
        contentUri: string;
        mobileContentUri: string;
        mobileContent: PreviewObject;
    };
}

export interface SurpriseJawsMessage extends CommonJawsMessage {
    surpriseName: string;
    surpriseId: string;
    surpriseCustomText: string | null;
    creditType: CurrencyType;
    surpriseAssets: {
        previews: Asset[];
        animations: Asset[];
    };
    surpriseVariant: OfflineSurpriseVariant;
}

export interface VideoJawsMessage extends CommonJawsMessage {
    uploadToken: string;
    status: MediaStatusEnum;
    statusChangedAt: string;
    creditType: CurrencyType;
    linkedAttachmentId: number;
    mediaInfo: {
        preview: PreviewObject;
        content: VideoContent;
        fullHD: VideoContent;
        previewUri: string;
        contentUri: string;
        mobileContentUri: string;
        mobileContent: VideoContent;
    };
}

export interface GiftJawsMessage extends CommonJawsMessage {
    id: string;
    image: {
        url: string;
    };
    name: string;
    text: string;
}

export interface GiftBasketJawsMessage extends CommonJawsMessage {
    id: string;
    amount: number;
}

export interface StoryReplyJawsMessage extends CommonJawsMessage {
    text: string;
    story: {
        isHighlighted: boolean;
        status: string;
        storyId: number;
        storyItemId: number;
        thumbnail: {
            height: number;
            width: number;
            url: string;
        };
    };
    message: string;
}

export interface SeenJawsMessage {
    body: {
        createdAt: string;
        lastMessage: LastMessage;
        threadId: number;
        timestamp: number;
        version: number;
    };
    header: JawsResponseHeader;
}

interface LastMessage {
    content: {
        message: string;
    };
    createdAt: string;
    id: number;
    isSeen: boolean | null;
    sender: string;
    text: string;
    type: TypesEnum;
}

export interface JawsInteraction {
    type: 'memberSpent' | 'addedToFavorites' | 'contentLiked' | 'contentCommented';
    createdAt?: string;
    performerId: number;
    memberId: number;
    memberNick: string;
    metadata?: InteractionMetadata;
}

type InteractionMetadata = MemberSpentMetaData | ContentCommentedMetaData | ContentLikedMetaData;

export interface MemberSpentMetaData {
    credits: number;
    pretenceId: number;
    pretenceName: JawsSpendingPretences;
}

export interface ContentCommentedMetaData {
    contentId: string;
    contentType: ContentInteractionMediaType;
    privacy: 'free' | 'exclusive' | 'public';
}

export type ContentInteractionMediaType = 'note' | 'video' | 'image' | 'album';

export interface ContentLikedMetaData extends ContentCommentedMetaData {}

export type JawsSpendingPretences =
    | 'private show'
    | 'surprise'
    | 'snapshot'
    | 'MyContent video purchase'
    | 'MyContent photo album purchase'
    | 'my story'
    | 'story highlight'
    | 'hot show tip'
    | 'battle tip'
    | 'action request'
    | 'Interactive Toy - Private'
    | 'Interactive Toy - Hot Show'
    | 'Interactive Toy - VIP Show'
    | 'Interactive Toy - Free Live'
    | 'Interactive Toy - Video Call'
    | 'Interactive Toy - Event Show'
    | 'Freemium Tip - Live'
    | 'Freemium Tip - Event Show'
    | 'Freemium Tip - VIP Show'
    | 'Freemium Tip - Video Call'
    | 'vip show lock'
    | 'event show lock';

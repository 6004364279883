import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessengerMember } from 'domains/Messenger/Messages.types';
import { Interaction, InteractionEntry } from 'domains/Member/Member.types';
import { RecommendedMembersState } from './RecommendedMembers.types';

export const recommendedMembersInitialState: RecommendedMembersState = {
    entries: [],
    isLoading: false,
    isInteractionLoading: false,
    isInitialLoadDone: false,
    errorMessage: null,
    interactions: [],
};

const INTERACTION_LIMIT = 5;

const recommendedMembersSlice = createSlice({
    name: 'messenger',
    initialState: recommendedMembersInitialState,
    reducers: {
        fetchRecommendedMembersStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = null;
        },
        fetchRecommendedMembersSucceeded: (state, action: PayloadAction<MessengerMember[]>) => {
            state.isLoading = false;
            state.isInitialLoadDone = true;
            state.entries = action.payload;
        },
        fetchRecommendedMembersFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.isInitialLoadDone = true;
            state.errorMessage = action.payload;
        },
        setChatThreadId: (state, action: PayloadAction<{ recommendedThreadId: number; chatThreadId: number }>) => {
            const { recommendedThreadId, chatThreadId } = action.payload;
            const index = state.entries.findIndex((thread) => thread.id === recommendedThreadId);

            if (index > -1) {
                state.entries[index].chatThreadId = chatThreadId;
            }
        },
        fetchMemberInteractionsStarted: (state) => {
            state.isLoading = true;
            state.isInteractionLoading = true;
            state.errorMessage = null;
        },
        fetchMemberInteractionsSucceeded: (state, action: PayloadAction<Interaction>) => {
            const { memberId, entries } = action.payload;
            const isNewMemberInStore = !state.interactions.find((interaction) => interaction.memberId === memberId);
            const isEmptyStore = !state.interactions.length;

            if (isEmptyStore || isNewMemberInStore) {
                state.interactions.push({ memberId, entries });
            } else {
                state.interactions = state.interactions.map((interaction) => {
                    if (interaction.memberId === memberId) {
                        interaction.entries = entries;
                    }

                    return interaction;
                });
            }

            state.isLoading = false;
            state.isInteractionLoading = false;
        },
        fetchMemberInteractionsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.isInteractionLoading = false;
            state.isInitialLoadDone = true;
            state.errorMessage = action.payload;
        },
        addInteractionToRecommendation: (
            state,
            action: PayloadAction<{ recommendedMember: MessengerMember; interaction: InteractionEntry }>,
        ) => {
            const interactionEntry = state.interactions.find(
                (interaction) => interaction.memberId === action.payload.recommendedMember.partner.id,
            );

            if (interactionEntry) {
                if (interactionEntry.entries.length === INTERACTION_LIMIT) interactionEntry.entries.pop();
                interactionEntry.entries.unshift(action.payload.interaction);
                return;
            }

            state.entries.unshift(action.payload.recommendedMember);

            state.interactions.push({
                memberId: action.payload.recommendedMember.partner.id,
                entries: [action.payload.interaction],
            });
        },
        removeRecommendation: (state, action: PayloadAction<number>) => {
            state.entries = state.entries.filter((entry) => entry.id !== action.payload);
        },
    },
});

export const {
    fetchRecommendedMembersStarted,
    fetchRecommendedMembersSucceeded,
    fetchRecommendedMembersFailed,
    setChatThreadId,
    fetchMemberInteractionsStarted,
    fetchMemberInteractionsSucceeded,
    fetchMemberInteractionsFailed,
    addInteractionToRecommendation,
    removeRecommendation,
} = recommendedMembersSlice.actions;
export default recommendedMembersSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
    CookieConsentConfiguration,
    CreateCookieConsentResponse,
} from 'domains/CookieConsent/CookieConsent.types';
import type { CookieConsentState } from './CookieConsent.types';

export const initialState: CookieConsentState = {
    configuration: {
        requestStatus: 'initial',
        data: undefined,
    },
    createConsent: {
        requestStatus: 'initial',
        data: undefined,
    },
};

const cookieConsentSlice = createSlice({
    name: 'cookieConsent',
    initialState,
    reducers: {
        fetchConfigurationStarted: (state) => {
            state.configuration.data = undefined;
            state.configuration.requestStatus = 'pending';
        },
        fetchConfigurationSucceeded: (state, action: PayloadAction<CookieConsentConfiguration>) => {
            state.configuration.data = action.payload;
            state.configuration.requestStatus = 'resolved';
        },
        fetchConfigurationFailed: (state) => {
            state.configuration.data = undefined;
            state.configuration.requestStatus = 'rejected';
        },
        createCookieConsentStarted: (state) => {
            state.createConsent.data = undefined;
            state.createConsent.requestStatus = 'pending';
        },
        createCookieConsentSucceeded: (state, action: PayloadAction<CreateCookieConsentResponse>) => {
            state.createConsent.data = action.payload;
            state.createConsent.requestStatus = 'resolved';
        },
        createCookieConsentFailed: (state) => {
            state.createConsent.data = undefined;
            state.createConsent.requestStatus = 'rejected';
        },
    },
});

export const {
    fetchConfigurationStarted,
    fetchConfigurationSucceeded,
    fetchConfigurationFailed,
    createCookieConsentStarted,
    createCookieConsentSucceeded,
    createCookieConsentFailed,
} = cookieConsentSlice.actions;

export default cookieConsentSlice.reducer;

import _ from '@dh-fe/translate';
// eslint-disable-next-line no-restricted-imports
import { ToastMeta } from 'store/slices/Toast/Toast.slice';

type DispatchToastNames = 'apiGwTimeout';

const dispatchToasts: { [key in DispatchToastNames]: ToastMeta } = {
    apiGwTimeout: {
        message: _`Oops! Something went wrong... Please wait a moment and try again.`,
        icon: 'warning',
        customId: 'apiGwTimeout',
    },
};

export type { DispatchToastNames };
export default dispatchToasts;

import uri from './uri';

export interface LocationUrl {
    host: string;
    hostname: string;
    domain: string;
    subdomain: string | null;
    pathname: string;
    protocol: string;
    localhost: boolean;
}

const location = {
    get url(): LocationUrl {
        const { protocol, host, hostname, pathname } = window.location;

        const isIPAddress = uri.ip(hostname);

        return {
            protocol,
            host,
            hostname,
            get domain(): string {
                if (isIPAddress) {
                    return hostname;
                }

                return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
            },
            get subdomain(): string | null {
                if (isIPAddress) {
                    return null;
                }

                const subdomain = hostname.replace(this.domain, '');

                return subdomain.endsWith('.') ? subdomain.substring(0, subdomain.lastIndexOf('.')) : subdomain;
            },
            get localhost(): boolean {
                return hostname === 'localhost' || hostname === '127.0.0.1';
            },
            pathname,
        };
    },
};

export default location;

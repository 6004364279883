import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PerformerNewbieStatus } from './Performer.types';

const initialState: PerformerNewbieStatus = {
    status: 'initial',
    errorMessage: undefined,
    newbieUntil: undefined,
    isNewbie: false,
};

const performerNewbieStatusSlice = createSlice({
    name: 'performerNewbieStatus',
    initialState,
    reducers: {
        fetchNewbieStatusStarted: (state) => {
            state.status = 'pending';
            state.errorMessage = undefined;
        },
        fetchNewbieStatusSucceeded: (state, action: PayloadAction<{ newbieUntil: string; isNewbie: boolean }>) => {
            state.status = 'resolved';
            state.newbieUntil = action.payload.newbieUntil;
            state.isNewbie = action.payload.isNewbie;
        },
        fetchNewbieStatusFailed: (state, action: PayloadAction<string>) => {
            state.status = 'rejected';
            state.errorMessage = action.payload;
        },
    },
});

export const { fetchNewbieStatusStarted, fetchNewbieStatusSucceeded, fetchNewbieStatusFailed } =
    performerNewbieStatusSlice.actions;
export default performerNewbieStatusSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoyalfansProfile, LoyalfansRegistrationStatus, RejectionReason } from 'domains/Loyalfans/Loyalfans.types';
import { LoyalfansState } from './Loyalfans.types';

const initialState: LoyalfansState = {
    requestStatus: 'pending',
    tokenRequestStatus: 'initial',
    consentRequestStatus: 'initial',
    isConsentDialogOpen: false,
    consentDialogVariant: 'consent',
    registrationStatus: undefined,
    errorMessage: undefined,
    authToken: undefined,
    isLinkVisibilityConsent: undefined,
    profileLinkRejectionReason: 'noneIsFulfilled',
};

const loyalfansSlice = createSlice({
    name: 'loyalfans',
    initialState,
    reducers: {
        consentToLoyalfansStarted: (state) => {
            state.consentRequestStatus = 'pending';
            state.errorMessage = undefined;
        },
        consentToLoyalfansSucceeded: (state) => {
            state.consentRequestStatus = 'resolved';
            state.consentDialogVariant = 'pending';
            state.registrationStatus = 'pending';
        },
        consentToLoyalfansFailed: (state, { payload }: PayloadAction<string>) => {
            state.consentRequestStatus = 'rejected';
            state.errorMessage = payload;
        },
        updateConsentDialogOpenStatus: (state, { payload }: PayloadAction<boolean>) => {
            state.isConsentDialogOpen = payload;
        },
        updateRegistrationStatus: (state, { payload }: PayloadAction<LoyalfansRegistrationStatus>) => {
            state.registrationStatus = payload;
        },
        fetchLoyalfansAuthTokenStarted: (state) => {
            state.tokenRequestStatus = 'pending';
            state.errorMessage = undefined;
            state.authToken = undefined;
        },
        fetchLoyalfansAuthTokenSucceeded: (state, { payload }: PayloadAction<string>) => {
            state.tokenRequestStatus = 'resolved';
            state.errorMessage = undefined;
            state.authToken = payload;
        },
        fetchLoyalfansAuthTokenFailed: (state, { payload }: PayloadAction<string>) => {
            state.tokenRequestStatus = 'rejected';
            state.errorMessage = payload;
            state.authToken = undefined;
        },
        updateProfileLinkStatusStarted: (state, { payload }: PayloadAction<{ isConsent: boolean }>) => {
            state.isLinkVisibilityConsent = payload.isConsent;
            state.errorMessage = undefined;
        },
        updateProfileLinkStatusSucceeded: (state, { payload }: PayloadAction<LoyalfansProfile>) => {
            state.profileLinkRejectionReason = payload.rejectionReason;
            state.errorMessage = undefined;
        },
        updateProfileLinkStatusFailed: (
            state,
            { payload }: PayloadAction<{ isConsent: boolean; errorMsg: string }>,
        ) => {
            state.isLinkVisibilityConsent = payload.isConsent;
            state.errorMessage = payload.errorMsg;
        },
        getLoyalfansProfileStarted: (state) => {
            state.requestStatus = 'pending';
        },
        getLoyalfansProfileSucceeded: (
            state,
            {
                payload,
            }: PayloadAction<{
                isConsent: boolean;
                rejectionReason: RejectionReason;
                registrationStatus: LoyalfansRegistrationStatus;
            }>,
        ) => {
            state.requestStatus = 'resolved';
            state.isLinkVisibilityConsent = payload.isConsent;
            state.profileLinkRejectionReason = payload.rejectionReason;
            state.registrationStatus = payload.registrationStatus;
        },
        getLoyalfansProfileFailed: (state, { payload }: PayloadAction<string | undefined>) => {
            state.requestStatus = 'rejected';
            state.errorMessage = payload;
        },
    },
});

export const {
    consentToLoyalfansStarted,
    consentToLoyalfansSucceeded,
    consentToLoyalfansFailed,
    updateConsentDialogOpenStatus,
    updateRegistrationStatus,
    fetchLoyalfansAuthTokenStarted,
    fetchLoyalfansAuthTokenSucceeded,
    fetchLoyalfansAuthTokenFailed,
    updateProfileLinkStatusStarted,
    updateProfileLinkStatusSucceeded,
    updateProfileLinkStatusFailed,
    getLoyalfansProfileStarted,
    getLoyalfansProfileSucceeded,
    getLoyalfansProfileFailed,
} = loyalfansSlice.actions;
export default loyalfansSlice.reducer;

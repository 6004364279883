import { config, AuthToken } from 'configs/appConfig';
import { AUTH_CACHE_KEY } from 'configs/cacheKeys';
// eslint-disable-next-line no-restricted-imports
import { getAuthCode, requestAuthCodeAccessToken, requestRefreshAccessToken } from 'api/auth';
// eslint-disable-next-line no-restricted-imports
import { AuthCodeAccessTokenRequest, RefreshTokenRequestParameters, TokenRequestResponse } from 'api/auth/types';
import { globalConfig } from 'configs/globalConfig';
// eslint-disable-next-line no-restricted-imports
import { tokenService } from 'domains/Token/Token.serviceFactory';
import cookies from 'utils/storage/cookies';

const defaultRefreshTokenLifetime = 604800; // in seconds

const ACCESS_TOKEN_EXPIRES_IN = 3600;

const deleteCookie = (): void => {
    cookies.remove(AUTH_CACHE_KEY);
};

export const saveCookie = (auth: AuthToken): void => {
    cookies.set(AUTH_CACHE_KEY, auth, defaultRefreshTokenLifetime);
};

export const getCookieData = (): AuthToken | null => {
    const cookieString = JSON.stringify(cookies.get<AuthToken>(AUTH_CACHE_KEY));
    try {
        return JSON.parse(cookieString);
    } catch (e) {
        return null;
    }
};

const getExpiresAt = (expiresIn: number) => {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
    return expiresAt.getTime();
};

export const mapTokenResponseToAuthToken = (tokenResponse: TokenRequestResponse): AuthToken => {
    /* eslint-disable camelcase */
    const { access_token, refresh_token, expires_in } = tokenResponse;
    const { userId } = globalConfig;
    const token: AuthToken = {
        accessToken: access_token,
        refreshToken: refresh_token,
        expiresAt: getExpiresAt(expires_in),
        userId: userId.toString(),
    };
    /* eslint-enable camelcase */

    return token;
};

export const getInitialAccessToken = async (): Promise<AuthToken> => {
    const authCodeResponse = await getAuthCode();
    const { data, status } = authCodeResponse;
    const emptyToken: AuthToken = {
        expiresAt: new Date().getTime(),
        accessToken: '',
        refreshToken: '',
        userId: '',
    };

    if (status === 'OK') {
        const { clientId, clientSecret, redirectUri } = config.api;
        const { authCode } = data;
        const { userId } = globalConfig;
        const parameters: AuthCodeAccessTokenRequest = {
            grant_type: 'authorization_code',
            client_id: clientId,
            client_secret: clientSecret,
            code: authCode,
            redirect_uri: redirectUri,
        };
        const accessTokenResponse = await requestAuthCodeAccessToken(parameters);
        if (!userId) console.error('userId is missing in globalConfig');
        if (accessTokenResponse._apiResponseCode === 200) {
            const token = mapTokenResponseToAuthToken(accessTokenResponse);

            saveCookie(token);
            return token;
        }
        return emptyToken;
    }
    return emptyToken;
};

export const getRefreshedAccessToken = async (refreshToken: string): Promise<AuthToken> => {
    const { clientId, clientSecret } = config.api;
    const parameters: RefreshTokenRequestParameters = {
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: refreshToken,
    };
    try {
        const { userId } = globalConfig;
        const refreshTokenResponse = await requestRefreshAccessToken(parameters);
        if (refreshTokenResponse._apiResponseCode === 200) {
            // the expires_in returned by this request is for the refresh token, not the access token
            // that's why we are not using it
            /* eslint-disable camelcase */
            const { access_token, refresh_token } = refreshTokenResponse;

            const expiresAtDate = new Date();
            expiresAtDate.setSeconds(expiresAtDate.getSeconds() + ACCESS_TOKEN_EXPIRES_IN);
            const token: AuthToken = {
                accessToken: access_token,
                refreshToken: refresh_token,
                expiresAt: expiresAtDate.getTime(),
                userId: userId.toString(),
            };

            saveCookie(token);
            return token;
        }
        // refresh token probably expired. get a new access token

        return getInitialAccessToken();
    } catch (e) {
        return getInitialAccessToken();
    }
};

export const isAuthTokenUser = (): boolean => {
    const cookieData = getCookieData();

    return cookieData?.userId ? +cookieData.userId === globalConfig.userId : false;
};

export const getAuthToken = async () => {
    const existingToken = getCookieData();
    if (existingToken !== null && !isTokenValid(existingToken) && isAuthTokenUser()) {
        const { clientId, clientSecret } = config.api;
        const { userId } = globalConfig;
        try {
            const authToken = await tokenService.getAuthToken(
                {
                    grant_type: 'refresh_token',
                    client_id: clientId,
                    client_secret: clientSecret,
                    refresh_token: existingToken.refreshToken,
                },
                userId,
            );

            if (authToken.accessToken) {
                saveCookie(authToken);
            }

            return authToken;
        } catch (e) {
            const authToken = await getInitialAccessToken();
            return authToken;
        }
    } else if (existingToken === null || !isAuthTokenUser()) {
        const authToken = await getInitialAccessToken();
        return authToken;
    }

    return existingToken;
};

export const deleteAuthData = (): void => {
    deleteCookie();
};

export const isTokenValid = (authToken: AuthToken) => {
    if (!authToken) return false;
    if (!isAuthTokenUser()) return false;
    const now = new Date();
    return authToken.accessToken && authToken.expiresAt > now.getTime();
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Level } from 'domains/LevelDependent/LevelDependent.types';
import { LevelDependentStatePayload } from './LevelDependent.types';

const initialState: LevelDependentStatePayload = {
    levels: [] as Array<Level>,
    earning: 0,
    isLoading: false,
};

const levelDependentSlice = createSlice({
    name: 'levelDependent',
    initialState,
    reducers: {
        fetchStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchLevelsSucceeded: (state, action: PayloadAction<Array<Level>>) => {
            state.levels = action.payload;
            state.isLoading = false;
        },
        fetchEarningsSucceeded: (state, action: PayloadAction<number>) => {
            state.earning = action.payload;
            state.isLoading = false;
        },
        fetchFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
    },
});

export const { fetchStarted, fetchLevelsSucceeded, fetchEarningsSucceeded, fetchFailed } = levelDependentSlice.actions;
export default levelDependentSlice.reducer;

/* eslint-disable camelcase */
// Non-Certified Statistics Dashboard types
export interface StatisticStudioRequest {
    reportRequests: ReportStudioRequests;
}

export interface ReportStudioRequests {
    metadata: MetaData;
    reports: Array<string>;
    studio_id: string;
    models?: number[] | null;
    batch_mode?: boolean;
}

export interface StatisticModelRequest {
    reportRequests: ReportModelRequests[];
}

export interface ReportModelRequests {
    from_date: string;
    model_id: string;
    report_id: string;
    to_date: string;
}

export interface MetaData {
    from_date: string;
    to_date: string;
}

export interface StatisticsAPIReport {
    data: ReportData;
    header: ReportHeader;
    totals?: { [key: string]: number | undefined };
}

export interface ReportData {
    metadata: {
        columns: string[];
        dimensions: string[];
    };
    rows: ReportDataRow[];
}

export interface ReportDataRow {
    dimensions: string[];
    values: (number | null)[];
}

export interface ReportHeader {
    from_date: string;
    granularity: string;
    reference_from_date?: string;
    reference_to_date?: string;
    report_id: string;
    to_date: string;
    model_id?: string;
    studio_id?: string;
}

export interface StatisticResponse {
    reports: StatisticsAPIReport[] | Array<StatisticsAPIReport[]>;
}

export enum AvailableReports {
    generalOverview = 'general-overview',
    earningsOverview = 'earnings-overview',
    workingTime = 'working-time',
    earningsGrouping = 'earnings-grouping',
    messengerTime = 'messenger-time-kpi',
}

// Certified Statistics Dashboard types
export interface ApiCertifiedStudioDateRanges {
    fromDate: string;
    toDate: string;
    referenceFromDate?: string;
    referenceToDate?: string;
}

export interface ApiCertifiedStudioKPIsPayload extends ApiCertifiedStudioDateRanges {
    studioId: string;
    performers?: number[];
}

export interface ApiCertifiedStudioKPI {
    performerId?: number;
    value: number;
    reference: number | null;
    unit: 'usd' | 'seconds';
    trend: number | null;
}

export interface ApiCertifiedStudioKPIsResponse {
    studioID: string;
    totalEarnings: ApiCertifiedStudioKPI[];
    totalWorkingHours: ApiCertifiedStudioKPI[];
    averageEarningPerHour: ApiCertifiedStudioKPI[];
    messageResponseTime: ApiCertifiedStudioKPI[];
}

export interface ApiCertifiedStudioEarningsPayload extends ApiCertifiedStudioDateRanges {
    studioId: string;
    performers?: number[];
    pretences?: number[];
}

export interface ApiCertifiedStudioEarningItem {
    date: string;
    value: number;
}

type ApiCertifiedStudioEarningsDatasetTypes =
    | 'base'
    | 'reference'
    | 'top50studioAverage'
    | 'referenceTop50studioAverage'
    | 'top100modelAverage'
    | 'referenceTop100modelAverage';

export interface ApiCertifiedStudioEarningsDataset {
    performerId?: number;
    name: ApiCertifiedStudioEarningsDatasetTypes;
    items: ApiCertifiedStudioEarningItem[];
}

export interface ApiCertifiedStudioEarningsResponse {
    studioID: string;
    datasets: ApiCertifiedStudioEarningsDataset[];
}

export interface ApiCertifiedStudioWorkingTimePayload extends ApiCertifiedStudioDateRanges {
    studioId: string;
    performers?: number[];
    onlineType: string[];
}

type ApiCertifiedStudioWorkingTimeDatasetTypes = 'base' | 'reference';

export interface ApiCertifiedStudioWorkingTimeItem {
    date: string;
    value: number;
}

export interface ApiCertifiedStudioWorkingTimeDataset {
    performerId?: number;
    name: ApiCertifiedStudioWorkingTimeDatasetTypes;
    items: ApiCertifiedStudioWorkingTimeItem[];
}

export interface ApiCertifiedStudioWorkingTimeResponse {
    studioID: string;
    datasets: ApiCertifiedStudioWorkingTimeDataset[];
}

type ApiCertifiedStudioTopPerformerEarningsIntervals = 'lastPeriod' | '30days' | '365days';

export interface ApiCertifiedStudioTopPerformerEarningsPayload {
    interval: ApiCertifiedStudioTopPerformerEarningsIntervals;
    studioId: string;
}

export interface ApiCertifiedStudioTopPerformer {
    performer: {
        id: number;
        displayName: string;
    };
    totalEarning: number;
}

export interface ApiCertifiedStudioTopPerformerEarningsResponse {
    studioID: string;
    performers: ApiCertifiedStudioTopPerformer[];
    startDate: string;
    endDate: string;
}

export type ApiGetBiTokenType = 'studio' | 'model';

export interface ApiGetBiTokenResponse {
    token: ApiBiToken;
}

export interface ApiBiToken {
    token: string;
    expireAt: string;
}

type ApiCertifiedStudioTrafficDataChartUserType = 'all' | 'guest' | 'free' | 'paying';

export interface ApiCertifiedStudioTrafficDataChartPayload extends ApiCertifiedStudioDateRanges {
    studioId: string;
    performers?: number[];
    withStudioData?: boolean;
    userType: ApiCertifiedStudioTrafficDataChartUserType;
}

export type ApiCertifiedStudioTrafficDataChartDatasetTypes = 'base' | 'reference';

export interface ApiCertifiedStudioTrafficDataChartItem {
    date: string;
    value: number;
    numberOfPerformers?: number;
}

export interface ApiCertifiedStudioTrafficDataChartDataset {
    performerId?: number;
    studioId?: string;
    name: ApiCertifiedStudioTrafficDataChartDatasetTypes;
    items: ApiCertifiedStudioTrafficDataChartItem[];
}

export interface ApiCertifiedStudioTrafficDataChartResponse {
    studioID: string;
    datasets: ApiCertifiedStudioTrafficDataChartDataset[];
}

export interface ApiCertifiedStudioTrafficKPIsPayload extends ApiCertifiedStudioDateRanges {
    studioId: string;
    performers?: number[];
}

export interface ApiCertifiedStudioTrafficKPI {
    value: number;
    reference: number | null;
    unit: 'users/hour' | 'unitless';
    trend: number | null;
}

export interface ApiCertifiedStudioTrafficKPIsResponse {
    studioID: string;
    performerId?: number[];
    averageTrafficPerHour: ApiCertifiedStudioTrafficKPI;
    userConversion: ApiCertifiedStudioTrafficKPI;
    newPayingMembers: ApiCertifiedStudioTrafficKPI;
    uniquePrivateShows: ApiCertifiedStudioTrafficKPI;
    totalTraffic: ApiCertifiedStudioTrafficKPI;
    trafficGuest: ApiCertifiedStudioTrafficKPI;
    trafficFree: ApiCertifiedStudioTrafficKPI;
    trafficPaying: ApiCertifiedStudioTrafficKPI;
}

type ApiCertifiedStudioTopCountriesInterval = 'lastPeriod' | '30days' | '365days';

export interface ApiCertifiedStudioTopCountriesPayload {
    studioId: string;
    performerId?: number[];
    interval: ApiCertifiedStudioTopCountriesInterval;
}

export interface ApiCertifiedStudioTopCountries {
    studioID: string;
    performerId?: number[];
    datasets: ApiCertifiedStudioTopCountriesDataset[];
}

export interface ApiCertifiedStudioTopCountriesDataset {
    countryCode: string;
    value: number;
}

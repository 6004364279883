/* tslint:disable */
/* eslint-disable */
/**
 * OAuth_2.0 API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OAuthTokenRequestCommonProperties
 */
export interface OAuthTokenRequestCommonProperties {
    /**
     *
     * @type {string}
     * @memberof OAuthTokenRequestCommonProperties
     */
    grant_type?: OAuthTokenRequestCommonPropertiesGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof OAuthTokenRequestCommonProperties
     */
    client_id?: string;
    /**
     *
     * @type {string}
     * @memberof OAuthTokenRequestCommonProperties
     */
    client_secret?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum OAuthTokenRequestCommonPropertiesGrantTypeEnum {
    AuthorizationCode = 'authorization_code',
    Password = 'password',
    RefreshToken = 'refresh_token',
}

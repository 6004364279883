import { createSelector } from '@reduxjs/toolkit';
import type { CookieConsentConfiguration } from 'domains/CookieConsent/CookieConsent.types';
import type { RequestStatus } from 'store/commonTypes';
import { RootState } from '../../store';
import type { CookieConsentConfigurationState, CreateCookieConsentState } from './CookieConsent.types';

const _fetchCookieConsentConfiguration = (state: RootState): CookieConsentConfigurationState =>
    state.cookieConsent.configuration;

const _createCookieConsent = (state: RootState): CreateCookieConsentState => state.cookieConsent.createConsent;

export const selectCookieConsentConfiguration = createSelector(
    [_fetchCookieConsentConfiguration],
    (cookieConsentConfiguration): CookieConsentConfiguration | undefined => cookieConsentConfiguration.data,
);

export const selectCookieConsentConfigurationRequestStatus = createSelector(
    [_fetchCookieConsentConfiguration],
    (cookieConsentConfiguration): RequestStatus => cookieConsentConfiguration.requestStatus,
);

export const selectCreateCookieConsentRequestStatus = createSelector(
    [_createCookieConsent],
    (createCookieConsent): RequestStatus => createCookieConsent.requestStatus,
);

import { CookieConsentServiceInterface } from './CookieConsent.serviceFactory';
import { CookieCategory, CookieConsentConfiguration, CreateCookieConsentResponse } from './CookieConsent.types';

export interface CookieConsentDataMapperInterface {
    getCookieConsentConfigurations: () => Promise<CookieConsentConfiguration>;
    createCookieConsent: (
        url: string,
        approvedCategories: CookieCategory[],
        consentConfigurationId: string,
    ) => Promise<CreateCookieConsentResponse>;
    createCookieConsentConfigurationCookies: (consentConfigurationId: string, cookieNames: string[]) => Promise<void>;
}

export class CookieConsentService implements CookieConsentServiceInterface {
    constructor(private mapper: CookieConsentDataMapperInterface) {}

    public getCookieConsentConfigurations() {
        return this.mapper.getCookieConsentConfigurations();
    }

    public createCookieConsent(url: string, approvedCategories: CookieCategory[], consentConfigurationId: string) {
        return this.mapper.createCookieConsent(url, approvedCategories, consentConfigurationId);
    }

    public createCookieConsentConfigurationCookies(consentConfigurationId: string, cookieNames: string[]) {
        return this.mapper.createCookieConsentConfigurationCookies(consentConfigurationId, cookieNames);
    }
}

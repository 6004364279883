import _dayjs from 'dayjs';

import isMoment from 'dayjs/plugin/isMoment';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import badMutable from 'dayjs/plugin/badMutable';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/hu';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/nl';
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import 'dayjs/locale/fi';
import 'dayjs/locale/ja';
import 'dayjs/locale/ru';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';
import 'dayjs/locale/ro';
import 'dayjs/locale/pl';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/fr';

_dayjs.extend(isMoment);
_dayjs.extend(weekday);
_dayjs.extend(localeData);
_dayjs.extend(localizedFormat);
_dayjs.extend(badMutable);
_dayjs.extend(isBetween);
_dayjs.extend(isSameOrAfter);
_dayjs.extend(utc);
_dayjs.extend(advancedFormat);
_dayjs.extend(isToday);
_dayjs.extend(isYesterday);
_dayjs.extend(isSameOrBefore);
_dayjs.extend(timezone);

_dayjs.locale(window.GlobalConfig.language);

export default _dayjs;

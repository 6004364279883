import { Draft } from '@reduxjs/toolkit';
import { addHours } from 'date-fns';
// eslint-disable-next-line no-restricted-imports
import dayjs from 'global/dayjsExtended';
import { calculateLastMessageTime } from 'domains/Messenger/MessengerThreads/MessengerThreads.mapper';
import { CommonMessage } from 'domains/Messenger/Messenger.types';
import { MessengerMember, PartnerTypeEnum } from 'domains/Messenger/Messages.types';
// eslint-disable-next-line no-restricted-imports
import { JawsMessageResponse, CommonJawsMessage } from 'pages/Messenger/utils/types';
// eslint-disable-next-line no-restricted-imports
import { mapJawsResponseToThreadLastMessage } from 'pages/Messenger/utils/mapJawsResponseToThreadLastMessage';
import { ThreadFilters } from './Threads.types';

export const updateThreadsWithJAWS = (
    threads: Draft<MessengerMember[]>,
    jawsResponse: { data: JawsMessageResponse<CommonJawsMessage>; activeThread: number },
): MessengerMember[] => {
    const { data, activeThread } = jawsResponse;
    const updatedThreadIndex = threads.findIndex((thread) => thread.partner.id === data.body.senderId);

    if (updatedThreadIndex === -1) throw new Error('JAWS mapping failed: No matching sender id');

    const updatedThread: MessengerMember = { ...threads[updatedThreadIndex] };
    const filteredThreads = threads.filter((_, index) => index !== updatedThreadIndex);
    const convertedSentDate = addHours(dayjs(data.body.createdAt).utc().toDate(), 1);

    updatedThread.lastMessage = mapJawsResponseToThreadLastMessage(jawsResponse.data);
    updatedThread.lastMessageTimeStamp = calculateLastMessageTime(convertedSentDate.toString());
    updatedThread.lastMessage.direction = data.body.participant.type === PartnerTypeEnum.Member ? 'received' : 'sent';

    if (data.body.threadId !== activeThread) {
        updatedThread.unreadMessageCount = updatedThread.unreadMessageCount ? updatedThread.unreadMessageCount + 1 : 1;
    }

    return [updatedThread, ...filteredThreads];
};

export const findThreadIndex = (threads: MessengerMember[], id: number) =>
    threads.findIndex((thread) => thread.id === id);

export const getLatestSuccessfulMessage = (entries: CommonMessage[]) => {
    const reversed = entries
        .filter((message: CommonMessage) => !message.isFailed)
        .slice()
        .reverse();

    if (reversed.length < 2) throw new Error('There is no message to restore');

    return reversed[1];
};

export const countUnreadMessages = (threads: MessengerMember[]): number => {
    let notificationsCount = 0;

    threads.forEach((thread) => {
        if (thread.unreadMessageCount) notificationsCount += thread.unreadMessageCount;
    });

    return notificationsCount;
};

export const isThreadFilter = (filter: string | null): filter is ThreadFilters =>
    ['unread', 'unanswered', 'pinned', 'deleted'].includes(filter || '');

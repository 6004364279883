import { AwardStats, AwardsStatsType, AwardsStatisticsPeriod } from './AwardsStatistic.types';

export const mockData: AwardStats[] = [
    {
        type: AwardsStatsType.MY_STORY,
        place: 100,
        point: 10000,
        earning: 30,
    },
    {
        type: AwardsStatsType.MESSENGER,
        place: 1,
        point: 50000,
        earning: 100,
    },
    {
        type: AwardsStatsType.VIDEO_CALL,
        place: 1,
        point: 5000,
        earning: 10,
    },
    {
        type: AwardsStatsType.NEW_COMER,
        place: 199,
        point: 2000,
        earning: 10,
    },
];

export const mockPeriods: AwardsStatisticsPeriod[] = [
    {
        periodStart: new Date(2020, 8, 1).toString(),
        periodEnd: new Date(2020, 8, 15).toString(),
        key: '2020 Sep 01 - Sep 15',
    },
    {
        periodStart: new Date(2020, 7, 16).toString(),
        periodEnd: new Date(2020, 7, 31).toString(),
        key: '2020 Aug 16 - Aug 31',
    },
    {
        periodStart: new Date(2020, 7, 1).toString(),
        periodEnd: new Date(2020, 7, 15).toString(),
        key: '2020 Aug 01 - Aug 15',
    },
];

export const emptyAwardStats: AwardStats[] = [];

export const emptyPeriods = [];

export const gamesResponse = {
    data: [
        { type: 'VideoCallGame', position: 1, points: 5000, creditReward: 10 },
        { type: 'MyStoryGame', position: 100, points: 10000, creditReward: 30 },
        { type: 'MessagesGame', position: 1, points: 50000, creditReward: 100 },
        { type: 'NewModelsGame', position: 199, points: 2000, creditReward: 10 },
    ],
};

export const gamesCompleteResponse = {
    data: [
        {
            id: '62d0f29f-a421-47d8-a9f3-0a81f1fc4dcd',
            type: 'MessagesGame',
            status: 'finished',
            createdAt: '2020-02-03T09:28:09+01:00',
            updatedAt: '2020-02-05T17:37:05+01:00',
            expiresAt: '2020-04-08T15:05:49+02:00',
        },
    ],
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIPromoPeriodTimeState } from './PromoPeriodTime.types';

const initialState: UIPromoPeriodTimeState = {
    times: undefined,
    status: 'initial',
    errorMessage: undefined,
};

const promoPeriodTimeSlice = createSlice({
    name: 'promoPeriodTime',
    initialState,
    reducers: {
        fetchPromoPeriodTimeStarted: (state) => {
            state.times = undefined;
            state.status = 'pending';
            state.errorMessage = undefined;
        },
        fetchPromoPeriodTimeSucceeded: (state, action: PayloadAction<UIPromoPeriodTimeState>) => {
            state.status = 'resolved';
            state.times = action.payload.times;
        },
        fetchPromoPeriodTimeFailed: (state, action: PayloadAction<string>) => {
            state.times = undefined;
            state.status = 'rejected';
            state.errorMessage = action.payload;
        },
    },
});

export const { fetchPromoPeriodTimeStarted, fetchPromoPeriodTimeSucceeded, fetchPromoPeriodTimeFailed } =
    promoPeriodTimeSlice.actions;
export default promoPeriodTimeSlice.reducer;

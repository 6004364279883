import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fanClubState } from './FanClub.types';

const initialState: fanClubState = {
    isLoading: false,
    errorMessage: undefined,
    isEnabled: false,
};

const fanClubSlice = createSlice({
    name: 'fanClub',
    initialState,
    reducers: {
        fetchEnableStart: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchEnableSuccess: (state, { payload }: PayloadAction<boolean>) => {
            state.isEnabled = payload;
            state.isLoading = false;
        },
        fetchEnableError: (state, { payload }: PayloadAction<string>) => {
            state.isEnabled = false;
            state.isLoading = false;
            state.errorMessage = payload;
        },
    },
});

export const { fetchEnableStart, fetchEnableSuccess, fetchEnableError } = fanClubSlice.actions;
export default fanClubSlice.reducer;

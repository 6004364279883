import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'utils/merge';
import { PersistedComponentPayload, PersistedComponentState } from './PersistedComponent.types';

export const initialState: PersistedComponentState = {
    instances: {},
};

const persistedComponentSlice = createSlice({
    name: 'persistedComponent',
    initialState,
    reducers: {
        persistComponent: (state, action: PayloadAction<PersistedComponentPayload>) => {
            state.instances[action.payload.key] = action.payload.state;
        },
        persistPartialComponent: (state, action: PayloadAction<PersistedComponentPayload>) => {
            const currentState = state.instances[action.payload.key] || {};
            const merged = merge(currentState, action.payload.state);
            state.instances[action.payload.key] = merged;
        },
    },
});

export const { persistComponent, persistPartialComponent } = persistedComponentSlice.actions;
export default persistedComponentSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnsentInputTextsState, UnsentInputText } from './UnsentInputTexts.types';

export const unsentInputTextsInitialState: UnsentInputTextsState = {
    entries: [],
};

const unsentInputTextsSlice = createSlice({
    name: 'unsentInputTexts',
    initialState: unsentInputTextsInitialState,
    reducers: {
        updateInputText: (state, action: PayloadAction<UnsentInputText>) => {
            const { id, text } = action.payload;
            const existingEntry = state.entries.find((entry) => entry.id === id);

            if (existingEntry) {
                existingEntry.text = text;
            } else {
                state.entries.push(action.payload);
            }
        },
    },
});

export const { updateInputText } = unsentInputTextsSlice.actions;
export default unsentInputTextsSlice.reducer;

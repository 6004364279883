import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CertifiedStudioEarnings,
    CertifiedStudioKPIs,
    CertifiedStudioTrafficKPIs,
    CertifiedStudioWorkingTime,
    CertifiedStudioTrafficDataChart,
    UIStatistics,
    CertifiedStudioTopCountries,
    CertifiedStudioYearlyTraffic,
    HotDealsCount,
    MobileOnlineTime,
    NewSpendingMemberCount,
} from 'domains/Statistics/Statistics.types';
import { CertifiedStudioTopEarningPerformersWithNamesState, StatisticsState } from './Statistics.types';

const mobileOnlineTimeFormattedDefault = '0h 0m 0s';

const initialState: StatisticsState = {
    statistics: undefined,
    certifiedStudioKPIs: undefined,
    certifiedStudioEarnings: undefined,
    certifiedStudioWorkingTime: undefined,
    certifiedStudioTopEarningPerformers: undefined,
    certifiedStudioTrafficDataChart: undefined,
    certifiedStudioTrafficKPIs: undefined,
    certifiedStudioTopCountries: undefined,
    certifiedStudioYearlyTraffic: undefined,
    newSpendingMemberCount: {
        newSpendingMemberCount: 0,
        trend: 1,
        newSpendingMembersForSiteCount: 0,
        newSpendingMembersForSiteTrend: 1,
        requestStatus: 'initial',
    },
    mobileOnlineTime: {
        timeInSeconds: 0,
        timeFormatted: mobileOnlineTimeFormattedDefault,
        trend: 1,
        requestStatus: 'initial',
    },
    hotDealsCount: {
        createdHotDealsCount: 0,
        createdHotDealsTrend: 1,
        usedHotDealsCount: 0,
        usedHotDealsTrend: 1,
        commonHotDealsTrend: 1,
        requestStatus: 'initial',
    },
    isLoading: false,
    errorMessage: undefined,
};

const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        fetchStatisticsStarted: (state) => {
            state.statistics = undefined;
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchStatisticsSucceeded: (state, action: PayloadAction<UIStatistics>) => {
            state.statistics = action.payload;
            state.isLoading = false;
        },
        fetchStatisticsFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        fetchCertifiedStudioKPIsStarted: (state) => {
            state.certifiedStudioKPIs = {
                kpis: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioKPIsSucceeded: (state, action: PayloadAction<CertifiedStudioKPIs>) => {
            state.certifiedStudioKPIs = {
                kpis: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioKPIsFailed: (state) => {
            state.certifiedStudioKPIs = {
                kpis: undefined,
                requestStatus: 'rejected',
            };
        },
        resetCertifiedStudioKPIs: (state) => {
            state.certifiedStudioKPIs = {
                kpis: undefined,
                requestStatus: 'initial',
            };
        },
        fetchCertifiedStudioEarningsStarted: (state) => {
            state.certifiedStudioEarnings = {
                earnings: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioEarningsSucceeded: (state, action: PayloadAction<CertifiedStudioEarnings>) => {
            state.certifiedStudioEarnings = {
                earnings: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioEarningsFailed: (state) => {
            state.certifiedStudioEarnings = {
                earnings: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioWorkingTimeStarted: (state) => {
            state.certifiedStudioWorkingTime = {
                workingTime: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioWorkingTimeSucceeded: (state, action: PayloadAction<CertifiedStudioWorkingTime>) => {
            state.certifiedStudioWorkingTime = {
                workingTime: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioWorkingTimeFailed: (state) => {
            state.certifiedStudioWorkingTime = {
                workingTime: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioTopEarningPerformersStarted: (state) => {
            state.certifiedStudioTopEarningPerformers = {
                topPerformerEarnings: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioTopEarningPerformersSucceeded: (
            state,
            action: PayloadAction<CertifiedStudioTopEarningPerformersWithNamesState>,
        ) => {
            state.certifiedStudioTopEarningPerformers = {
                topPerformerEarnings: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioTopEarningPerformersFailed: (state) => {
            state.certifiedStudioTopEarningPerformers = {
                topPerformerEarnings: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioTrafficDataChartStarted: (state) => {
            state.certifiedStudioTrafficDataChart = {
                trafficDataChart: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioTrafficDataChartSucceeded: (
            state,
            action: PayloadAction<CertifiedStudioTrafficDataChart>,
        ) => {
            state.certifiedStudioTrafficDataChart = {
                trafficDataChart: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioTrafficDataChartFailed: (state) => {
            state.certifiedStudioTrafficDataChart = {
                trafficDataChart: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioTrafficKPIsStarted: (state) => {
            state.certifiedStudioTrafficKPIs = {
                trafficKpis: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioTrafficKPIsSucceeded: (state, action: PayloadAction<CertifiedStudioTrafficKPIs>) => {
            state.certifiedStudioTrafficKPIs = {
                trafficKpis: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioTrafficKPIsFailed: (state) => {
            state.certifiedStudioTrafficKPIs = {
                trafficKpis: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioTopCountriesStarted: (state) => {
            state.certifiedStudioTopCountries = {
                topCountries: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioTopCountriesSucceeded: (state, action: PayloadAction<CertifiedStudioTopCountries>) => {
            state.certifiedStudioTopCountries = {
                topCountries: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioTopCountriesFailed: (state) => {
            state.certifiedStudioTopCountries = {
                topCountries: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchCertifiedStudioYearlyTrafficStarted: (state) => {
            state.certifiedStudioYearlyTraffic = {
                yearlyTraffic: undefined,
                requestStatus: 'pending',
            };
        },
        fetchCertifiedStudioYearlyTrafficSucceeded: (state, action: PayloadAction<CertifiedStudioYearlyTraffic>) => {
            state.certifiedStudioYearlyTraffic = {
                yearlyTraffic: action.payload,
                requestStatus: 'resolved',
            };
        },
        fetchCertifiedStudioYearlyTrafficFailed: (state) => {
            state.certifiedStudioYearlyTraffic = {
                yearlyTraffic: undefined,
                requestStatus: 'rejected',
            };
        },
        fetchNewSpendingMemberCountStarted: (state) => {
            state.newSpendingMemberCount = {
                ...initialState.newSpendingMemberCount,
                requestStatus: 'pending',
            };
        },
        fetchNewSpendingMemberCountSucceeded: (state, action: PayloadAction<NewSpendingMemberCount>) => {
            state.newSpendingMemberCount = {
                newSpendingMemberCount: action.payload.newSpendingMemberCount,
                trend: action.payload.trend,
                newSpendingMembersForSiteCount: action.payload.newSpendingMembersForSiteCount,
                newSpendingMembersForSiteTrend: action.payload.newSpendingMembersForSiteTrend,
                requestStatus: 'resolved',
            };
        },
        fetchNewSpendingMemberCountFailed: (state) => {
            state.newSpendingMemberCount = {
                ...initialState.newSpendingMemberCount,
                requestStatus: 'rejected',
            };
        },
        fetchMobileOnlineTimeStarted: (state) => {
            state.mobileOnlineTime = {
                ...initialState.mobileOnlineTime,
                requestStatus: 'pending',
            };
        },
        fetchMobileOnlineTimeSucceeded: (state, action: PayloadAction<MobileOnlineTime>) => {
            state.mobileOnlineTime = {
                timeInSeconds: action.payload.timeInSeconds,
                timeFormatted: action.payload.timeFormatted,
                trend: action.payload.trend,
                requestStatus: 'resolved',
            };
        },
        fetchMobileOnlineTimeFailed: (state) => {
            state.mobileOnlineTime = {
                ...initialState.mobileOnlineTime,
                requestStatus: 'rejected',
            };
        },
        fetchHotDealsCountStarted: (state) => {
            state.hotDealsCount = {
                ...initialState.hotDealsCount,
                requestStatus: 'pending',
            };
        },
        fetchHotDealsCountSucceeded: (state, action: PayloadAction<HotDealsCount>) => {
            state.hotDealsCount = {
                createdHotDealsCount: action.payload.createdHotDealsCount,
                createdHotDealsTrend: action.payload.createdHotDealsTrend,
                usedHotDealsCount: action.payload.usedHotDealsCount,
                usedHotDealsTrend: action.payload.usedHotDealsTrend,
                commonHotDealsTrend: action.payload.commonHotDealsTrend,
                requestStatus: 'resolved',
            };
        },
        fetchHotDealsCountFailed: (state) => {
            state.hotDealsCount = {
                ...initialState.hotDealsCount,
                requestStatus: 'rejected',
            };
        },
    },
});

export const {
    fetchStatisticsStarted,
    fetchStatisticsSucceeded,
    fetchStatisticsFailed,
    fetchCertifiedStudioKPIsStarted,
    fetchCertifiedStudioKPIsSucceeded,
    fetchCertifiedStudioKPIsFailed,
    resetCertifiedStudioKPIs,
    fetchCertifiedStudioEarningsStarted,
    fetchCertifiedStudioEarningsSucceeded,
    fetchCertifiedStudioEarningsFailed,
    fetchCertifiedStudioWorkingTimeStarted,
    fetchCertifiedStudioWorkingTimeSucceeded,
    fetchCertifiedStudioWorkingTimeFailed,
    fetchCertifiedStudioTopEarningPerformersStarted,
    fetchCertifiedStudioTopEarningPerformersSucceeded,
    fetchCertifiedStudioTopEarningPerformersFailed,
    fetchCertifiedStudioTrafficDataChartStarted,
    fetchCertifiedStudioTrafficDataChartSucceeded,
    fetchCertifiedStudioTrafficDataChartFailed,
    fetchCertifiedStudioTrafficKPIsStarted,
    fetchCertifiedStudioTrafficKPIsSucceeded,
    fetchCertifiedStudioTrafficKPIsFailed,
    fetchCertifiedStudioTopCountriesStarted,
    fetchCertifiedStudioTopCountriesSucceeded,
    fetchCertifiedStudioTopCountriesFailed,
    fetchCertifiedStudioYearlyTrafficStarted,
    fetchCertifiedStudioYearlyTrafficSucceeded,
    fetchCertifiedStudioYearlyTrafficFailed,
    fetchNewSpendingMemberCountStarted,
    fetchNewSpendingMemberCountSucceeded,
    fetchNewSpendingMemberCountFailed,
    fetchMobileOnlineTimeStarted,
    fetchMobileOnlineTimeSucceeded,
    fetchMobileOnlineTimeFailed,
    fetchHotDealsCountStarted,
    fetchHotDealsCountSucceeded,
    fetchHotDealsCountFailed,
} = statisticsSlice.actions;
export default statisticsSlice.reducer;

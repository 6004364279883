import { combineReducers } from '@reduxjs/toolkit';
import notificationsReducer from './Notifications/Notifications.slice';
import unreadCountReducer from './UnreadCount/UnreadCount.slice';

const notificationReducer = combineReducers({
    notifications: notificationsReducer,
    unreadCount: unreadCountReducer,
});

export default notificationReducer;

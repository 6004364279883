import { AuthToken } from 'configs/appConfig';
import { OAuth20ApiInterface } from 'apiClients/msc-api-gateway/oauth2/client/oauth20-api';
import {
    OAuthTokenRequestCommonPropertiesGrantTypeEnum,
    OAuthTokenRequest,
} from 'apiClients/msc-api-gateway/oauth2/models';
import { globalConfig } from 'configs/globalConfig';
import { TokenServiceInterface } from './Token.service';
import { AuthTokenRequest } from './Token.types';

export class TokenDataMapper implements TokenServiceInterface {
    constructor(private apiClient: OAuth20ApiInterface) {}

    public async getAuthToken(request: AuthTokenRequest, userId: number): Promise<AuthToken> {
        const response = await this.apiClient.createOauthTokens('', toApiClientRequest(request));
        return mapTokenResponseToAuthToken(response.data);
    }
}

const getExpiresAt = (expiresIn: number): number => {
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);
    return expiresAt.getTime();
};

// eslint-disable-next-line camelcase
const mapTokenResponseToAuthToken = ({ access_token = '', refresh_token = '', expires_in = 0 }): AuthToken => {
    const { userId } = globalConfig;
    const token: AuthToken = {
        // eslint-disable-next-line camelcase
        accessToken: access_token,
        // eslint-disable-next-line camelcase
        refreshToken: refresh_token,
        expiresAt: getExpiresAt(expires_in),
        userId: userId.toString(),
    };

    return token;
};
const map = {
    authorization_code: OAuthTokenRequestCommonPropertiesGrantTypeEnum.AuthorizationCode,
    password: OAuthTokenRequestCommonPropertiesGrantTypeEnum.Password,
    refresh_token: OAuthTokenRequestCommonPropertiesGrantTypeEnum.RefreshToken,
};

/* eslint-disable camelcase */
export const toApiClientRequest = (request: AuthTokenRequest): OAuthTokenRequest => {
    const { client_id, client_secret, refresh_token } = request;
    const grant_type: OAuthTokenRequestCommonPropertiesGrantTypeEnum | undefined = request.grant_type
        ? map[request.grant_type]
        : request.grant_type;
    return {
        grant_type,
        client_id,
        client_secret,
        refresh_token,
    };
};

import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import useAppDispatch from 'store/hooks/useAppDispatch';
// eslint-disable-next-line no-restricted-imports
import { ToastMeta } from 'store/slices/Toast/Toast.slice';
// eslint-disable-next-line no-restricted-imports
import { showToast } from 'store/slices/Toast/Toast.thunks';

const useToast = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (meta: ToastMeta) => {
            dispatch(showToast(meta));
        },
        [dispatch],
    );
};

export default useToast;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThreadDetails } from 'domains/Messenger/Messages.types';
import { MemberDetails } from 'domains/Member/Member.types';
import { MessengerTabs } from '../Messenger.types';
import { MessengerPageState } from './MessengerPage.types';

export const messengerPageInitialState: MessengerPageState = {
    translationSubscriptionKey: undefined,
    selectedChatId: 0,
    selectedRecommendedMemberId: 0,
    isThreadsHiddenOnMobile: false,
    activeAudio: 0,
    threadDetails: undefined,
    validations: [],
    threadScrollPosition: 0,
    totalSpentOnPerformer: undefined,
    isSubscriber: false,
    activeTab: 'recommended-members',
    optionsMenuVisibleOnMessageId: undefined,
};

const messengerPageSlice = createSlice({
    name: 'messenger',
    initialState: messengerPageInitialState,
    reducers: {
        setSelectedChatId: (state, action: PayloadAction<number>) => {
            state.selectedChatId = action.payload;
        },
        setSelectedRecommendedMemberId: (state, action: PayloadAction<number>) => {
            state.selectedRecommendedMemberId = action.payload;
        },
        clearSelectedChatAndRecommendedIds: (state) => {
            state.selectedChatId = 0;
            state.selectedRecommendedMemberId = 0;
        },
        hideThreadsOnMobile: (state, action: PayloadAction<boolean>) => {
            state.isThreadsHiddenOnMobile = action.payload;
        },
        setActiveAudio: (state, action: PayloadAction<number>) => {
            state.activeAudio = action.payload;
        },
        setThreadDetails: (state, action: PayloadAction<ThreadDetails | undefined>) => {
            state.threadDetails = action.payload;
        },
        setValidations: (state, action: PayloadAction<string[]>) => {
            state.validations = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<MessengerTabs>) => {
            state.activeTab = action.payload;
        },
        setThreadScrollPosition: (state, action: PayloadAction<number>) => {
            state.threadScrollPosition = action.payload;
        },
        fetchMemberDetailsByNickStarted: (state) => {
            state.totalSpentOnPerformer = undefined;
        },
        fetchMemberDetailsByNickSucceeded: (
            state,
            action: PayloadAction<{ memberNick: string; memberDetails: MemberDetails }>,
        ) => {
            const {
                memberDetails: { totalSpentOnPerformer, isSubscriber },
            } = action.payload;
            state.totalSpentOnPerformer = totalSpentOnPerformer;
            state.isSubscriber = isSubscriber;
        },
        fetchMemberDetailsByNickFailed: (state) => {
            state.totalSpentOnPerformer = undefined;
        },
        fetchTranslationSubscriptionKeySucceeded: (state, action: PayloadAction<{ subscriptionKey: string }>) => {
            state.translationSubscriptionKey = action.payload.subscriptionKey;
        },
        setErrorMessage: (state, action: PayloadAction<string | undefined>) => {
            state.errorMessage = action.payload;
        },
    },
});

export const {
    setSelectedChatId,
    setSelectedRecommendedMemberId,
    clearSelectedChatAndRecommendedIds,
    hideThreadsOnMobile,
    setActiveAudio,
    setThreadDetails,
    setValidations,
    setActiveTab,
    setThreadScrollPosition,
    fetchMemberDetailsByNickStarted,
    fetchMemberDetailsByNickSucceeded,
    fetchMemberDetailsByNickFailed,
    fetchTranslationSubscriptionKeySucceeded,
    setErrorMessage,
} = messengerPageSlice.actions;
export default messengerPageSlice.reducer;

const uri = {
    absolute(address: string): boolean {
        return /^(https?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i.test(address);
    },
    relative(address: string): boolean {
        return !uri.absolute(address);
    },
    external(address: string): boolean {
        const uriDomain = (target: string): string | undefined => /https?:\/\/((?:[\w-]+\.)+\w{2,})/i.exec(target)?.[1];

        return uri.absolute(address) && uriDomain(window.location.href) !== uriDomain(address);
    },
    internal(address: string): boolean {
        return !uri.external(address);
    },
    ip(address: string): boolean {
        const regexpIPAddress =
            // eslint-disable-next-line max-len
            /^(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})$/;

        return Boolean(regexpIPAddress.exec(address));
    },
};

export default uri;

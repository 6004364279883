/* tslint:disable */
/* eslint-disable */
/**
 * OAuth_2.0 API
 * The API is reachable from public networks, the caller can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key (for in-house services).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { AccessTokenResponse } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { AuthCodeRequestCamApp } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { BadRequestErrorResponse } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { OAuthCodeResponse } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { OAuthErrorResponse } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { OAuthTokenRequest } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { TokenRevocationRequest } from '../../../msc-api-gateway/oauth2/models';
// @ts-ignore
import { UnauthorizedError } from '../../../msc-api-gateway/oauth2/models';
/**
 * OAuth20Api - axios parameter creator
 * @export
 */
export const OAuth20ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token for cam applications
         * @param {AuthCodeRequestCamApp} [authCodeRequestCamApp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCamAppOauthAuthCode: async (
            authCodeRequestCamApp?: AuthCodeRequestCamApp,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/auth/oauth2/auth-code/cam-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth', ['*'], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                authCodeRequestCamApp,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token
         * @param {number} userId The person ID that needs to be logged-in
         * @param {string} scope Requested scopes as a space separated list
         * @param {string} [roles] Comma separated list of user dynamic roles, atm only limited-studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOauthAuthCode: async (
            userId: number,
            scope: string,
            roles?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOauthAuthCode', 'userId', userId);
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('createOauthAuthCode', 'scope', scope);
            const localVarPath = `/auth/oauth2/auth-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (userId !== undefined) {
                localVarFormParams.set('user_id', userId as any);
            }

            if (scope !== undefined) {
                localVarFormParams.set('scope', scope as any);
            }

            if (roles !== undefined) {
                localVarFormParams.set('roles', roles as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get and access token
         * @param {string} [xFeatureToggles] Semicolon separated list of the above feature toggles         - disableStoryAutoApprovalForTestAccounts - enableCrossDomainAuth
         * @param {OAuthTokenRequest} [oAuthTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOauthTokens: async (
            xFeatureToggles?: string,
            oAuthTokenRequest?: OAuthTokenRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/auth/oauth2/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFeatureToggles !== undefined && xFeatureToggles !== null) {
                localVarHeaderParameter['X-Feature-Toggles'] = String(xFeatureToggles);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                oAuthTokenRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Revokes a token
         * @param {TokenRevocationRequest} [tokenRevocationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOauthTokens: async (
            tokenRevocationRequest?: TokenRevocationRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/auth/oauth2/revoke-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                tokenRevocationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * OAuth20Api - functional programming interface
 * @export
 */
export const OAuth20ApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuth20ApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token for cam applications
         * @param {AuthCodeRequestCamApp} [authCodeRequestCamApp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCamAppOauthAuthCode(
            authCodeRequestCamApp?: AuthCodeRequestCamApp,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCamAppOauthAuthCode(
                authCodeRequestCamApp,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token
         * @param {number} userId The person ID that needs to be logged-in
         * @param {string} scope Requested scopes as a space separated list
         * @param {string} [roles] Comma separated list of user dynamic roles, atm only limited-studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOauthAuthCode(
            userId: number,
            scope: string,
            roles?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOauthAuthCode(
                userId,
                scope,
                roles,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get and access token
         * @param {string} [xFeatureToggles] Semicolon separated list of the above feature toggles         - disableStoryAutoApprovalForTestAccounts - enableCrossDomainAuth
         * @param {OAuthTokenRequest} [oAuthTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOauthTokens(
            xFeatureToggles?: string,
            oAuthTokenRequest?: OAuthTokenRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOauthTokens(
                xFeatureToggles,
                oAuthTokenRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Revokes a token
         * @param {TokenRevocationRequest} [tokenRevocationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeOauthTokens(
            tokenRevocationRequest?: TokenRevocationRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeOauthTokens(
                tokenRevocationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * OAuth20Api - factory interface
 * @export
 */
export const OAuth20ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuth20ApiFp(configuration);
    return {
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token for cam applications
         * @param {AuthCodeRequestCamApp} [authCodeRequestCamApp]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCamAppOauthAuthCode(
            authCodeRequestCamApp?: AuthCodeRequestCamApp,
            options?: any,
        ): AxiosPromise<OAuthCodeResponse> {
            return localVarFp
                .createCamAppOauthAuthCode(authCodeRequestCamApp, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate a one time used authorization code to be able to get access token
         * @param {number} userId The person ID that needs to be logged-in
         * @param {string} scope Requested scopes as a space separated list
         * @param {string} [roles] Comma separated list of user dynamic roles, atm only limited-studio
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOauthAuthCode(
            userId: number,
            scope: string,
            roles?: string,
            options?: any,
        ): AxiosPromise<OAuthCodeResponse> {
            return localVarFp
                .createOauthAuthCode(userId, scope, roles, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get and access token
         * @param {string} [xFeatureToggles] Semicolon separated list of the above feature toggles         - disableStoryAutoApprovalForTestAccounts - enableCrossDomainAuth
         * @param {OAuthTokenRequest} [oAuthTokenRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOauthTokens(
            xFeatureToggles?: string,
            oAuthTokenRequest?: OAuthTokenRequest,
            options?: any,
        ): AxiosPromise<AccessTokenResponse> {
            return localVarFp
                .createOauthTokens(xFeatureToggles, oAuthTokenRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Revokes a token
         * @param {TokenRevocationRequest} [tokenRevocationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOauthTokens(tokenRevocationRequest?: TokenRevocationRequest, options?: any): AxiosPromise<void> {
            return localVarFp
                .revokeOauthTokens(tokenRevocationRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuth20Api - interface
 * @export
 * @interface OAuth20Api
 */
export interface OAuth20ApiInterface {
    /**
     *
     * @summary Generate a one time used authorization code to be able to get access token for cam applications
     * @param {AuthCodeRequestCamApp} [authCodeRequestCamApp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20ApiInterface
     */
    createCamAppOauthAuthCode(
        authCodeRequestCamApp?: AuthCodeRequestCamApp,
        options?: any,
    ): AxiosPromise<OAuthCodeResponse>;

    /**
     *
     * @summary Generate a one time used authorization code to be able to get access token
     * @param {number} userId The person ID that needs to be logged-in
     * @param {string} scope Requested scopes as a space separated list
     * @param {string} [roles] Comma separated list of user dynamic roles, atm only limited-studio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20ApiInterface
     */
    createOauthAuthCode(userId: number, scope: string, roles?: string, options?: any): AxiosPromise<OAuthCodeResponse>;

    /**
     *
     * @summary Get and access token
     * @param {string} [xFeatureToggles] Semicolon separated list of the above feature toggles         - disableStoryAutoApprovalForTestAccounts - enableCrossDomainAuth
     * @param {OAuthTokenRequest} [oAuthTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20ApiInterface
     */
    createOauthTokens(
        xFeatureToggles?: string,
        oAuthTokenRequest?: OAuthTokenRequest,
        options?: any,
    ): AxiosPromise<AccessTokenResponse>;

    /**
     *
     * @summary Revokes a token
     * @param {TokenRevocationRequest} [tokenRevocationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20ApiInterface
     */
    revokeOauthTokens(tokenRevocationRequest?: TokenRevocationRequest, options?: any): AxiosPromise<void>;
}

/**
 * OAuth20Api - object-oriented interface
 * @export
 * @class OAuth20Api
 * @extends {BaseAPI}
 */
export class OAuth20Api extends BaseAPI implements OAuth20ApiInterface {
    /**
     *
     * @summary Generate a one time used authorization code to be able to get access token for cam applications
     * @param {AuthCodeRequestCamApp} [authCodeRequestCamApp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20Api
     */
    public createCamAppOauthAuthCode(authCodeRequestCamApp?: AuthCodeRequestCamApp, options?: any) {
        return OAuth20ApiFp(this.configuration)
            .createCamAppOauthAuthCode(authCodeRequestCamApp, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Generate a one time used authorization code to be able to get access token
     * @param {number} userId The person ID that needs to be logged-in
     * @param {string} scope Requested scopes as a space separated list
     * @param {string} [roles] Comma separated list of user dynamic roles, atm only limited-studio
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20Api
     */
    public createOauthAuthCode(userId: number, scope: string, roles?: string, options?: any) {
        return OAuth20ApiFp(this.configuration)
            .createOauthAuthCode(userId, scope, roles, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get and access token
     * @param {string} [xFeatureToggles] Semicolon separated list of the above feature toggles         - disableStoryAutoApprovalForTestAccounts - enableCrossDomainAuth
     * @param {OAuthTokenRequest} [oAuthTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20Api
     */
    public createOauthTokens(xFeatureToggles?: string, oAuthTokenRequest?: OAuthTokenRequest, options?: any) {
        return OAuth20ApiFp(this.configuration)
            .createOauthTokens(xFeatureToggles, oAuthTokenRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Revokes a token
     * @param {TokenRevocationRequest} [tokenRevocationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth20Api
     */
    public revokeOauthTokens(tokenRevocationRequest?: TokenRevocationRequest, options?: any) {
        return OAuth20ApiFp(this.configuration)
            .revokeOauthTokens(tokenRevocationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

import { GlobalConfig } from 'contracts/window';
import { isOranum } from 'configs/sites';

export type Products = 'livejasmin' | 'oranum';

export enum Environment {
    DEV = 'dev',
    STAGING = 'staging',
    PROD = 'prod',
}

const isDev = window.GlobalConfig?.applicationEnvironment === Environment.DEV;
const cdnProd = isOranum ? ['https://msgalleryx0.doclercdn.com'] : ['https://gallery.dditscdn.com'];
const cdnDev = 'https://gallerydev.dditscdn.com';

export const globalConfig: GlobalConfig = {
    ...window.GlobalConfig,
    cdnList: isDev ? [cdnDev] : cdnProd,
    cdn: isDev ? cdnDev : cdnProd[0],
};

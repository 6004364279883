import { globalConfig } from 'configs/globalConfig';

/**
 * Defines HTTP Request Methods
 */
export enum RequestMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

/**
 * Defines request content types
 * Empty string means the browser can automatically define it
 */
export enum RequestContentTypes {
    JSON = 'application/json',
    BLOB = 'application/blob',
    FORM = '',
}

export enum RestStatusCodesEnum {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    MovedPermanently = 301,
    Found = 302,
    SeeOther = 303,
    NotModified = 304,
    TemporaryRedirect = 307,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    PreconditionFailed = 412,
    UnsupportedMediaType = 415,
    InternalServerError = 500,
    NotImplemented = 501,
}

export interface AuthToken {
    expiresAt: number;
    accessToken: string;
    refreshToken: string;
    userId: string;
}

export enum RequestProtocols {
    HTTP = 'http',
    HTTPS = 'https',
}

export enum Environment {
    DEV = 'dev',
    STAGING = 'staging',
    PROD = 'prod',
}

export interface Config {
    api: {
        protocol: string;
        host: string;
        portalHost: string;
        clientId: string;
        clientSecret: string;
        redirectUri: string;
    };
}

const { protocol, apiHost, apiClientId, apiClientSecret, apiRedirectUri, language } = globalConfig;
export const config: Config = {
    api: {
        protocol: protocol || RequestProtocols.HTTPS,
        host: apiHost,
        /* eslint-disable-next-line no-restricted-globals */
        portalHost: `${window.location.host}/${language || 'en'}`,
        clientId: apiClientId,
        clientSecret: apiClientSecret,
        redirectUri: apiRedirectUri,
    },
};

export const FEATURE_FLAG_STORAGE_KEY = 'featureFlags';

export const ATOM_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";

export enum MwhMediaUploadUrl {
    DEV = 'https://master-mwh-upload.dev.k8sdevel.com/media/upload',
    PROD_LJ = 'https://ip-109-71-161-120.dditscdn.com/media/upload',
    PROD_ORANUM = 'https://ip-109-71-161-120.doclercdn.com/media/upload',
}

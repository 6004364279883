const maxVisibleToasts = 3;

const initialTopOffset = 114; // px

const perToastTopOffset = 72; // px

const defaultTimeout = 5000; // 5 seconds

const priorityWeights: Record<string, number> = {
    low: 1,
    medium: 2,
    high: 3,
    critical: 4,
};

const config = {
    maxVisibleToasts,
    initialTopOffset,
    perToastTopOffset,
    defaultTimeout,
    priorityWeights,
};

export default config;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfilePicture } from 'domains/ProfilePicture/ProfilePicture.types';
import {
    CreateProfilePictureTagPayload,
    MoveProfilePictureCategoryPayload,
    NewPictureSetup,
    ProfilePictureState,
    SetProfilePictureAsSelectedPayload,
} from './ProfilePicture.types';

export const initialState: ProfilePictureState = {
    fetchProfilePictures: {
        requestStatus: 'initial',
        pictures: [],
    },
    deleteProfilePicture: {
        requestStatus: 'initial',
    },
    updateProfilePicture: {
        requestStatus: 'initial',
    },
    createProfilePicture: {
        requestStatus: 'initial',
        newPictureSetup: undefined,
        previousMainProfilePicture: undefined,
    },
    addWillingnessTagToProfilePicture: {
        requestStatus: 'initial',
    },
    moveProfilePictureCategory: {
        requestStatus: 'initial',
    },
};

const profilePictureSlice = createSlice({
    name: 'profilePicture',
    initialState,
    reducers: {
        fetchProfilePicturesStarted: (state) => {
            state.fetchProfilePictures.requestStatus = 'pending';
        },
        fetchProfilePicturesSucceeded: (state, action: PayloadAction<ProfilePicture[]>) => {
            state.fetchProfilePictures.pictures = action.payload;
            state.fetchProfilePictures.requestStatus = 'resolved';
        },
        fetchProfilePicturesFailed: (state) => {
            state.fetchProfilePictures.pictures = [];
            state.fetchProfilePictures.requestStatus = 'rejected';
        },
        deleteProfilePictureStarted: (state, action: PayloadAction<string>) => {
            state.deleteProfilePicture = {
                id: action.payload,
                requestStatus: 'pending',
            };
        },
        deleteProfilePictureSucceeded: (state, action: PayloadAction<string>) => {
            // Remove from profile-pictures list
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.filter(
                (profilePicture) => profilePicture.id !== action.payload,
            );
            state.deleteProfilePicture.id = undefined;
            state.deleteProfilePicture.requestStatus = 'resolved';
        },
        deleteProfilePictureFailed: (state) => {
            state.deleteProfilePicture.id = undefined;
            state.deleteProfilePicture.requestStatus = 'rejected';
        },
        setProfilePictureAsSelectedStarted: (state, action: PayloadAction<SetProfilePictureAsSelectedPayload>) => {
            const { profilePicture } = action.payload;
            state.updateProfilePicture = {
                id: profilePicture.id,
                requestStatus: 'pending',
            };
        },
        setProfilePictureAsSelectedSucceeded: (state, action: PayloadAction<SetProfilePictureAsSelectedPayload>) => {
            const { profilePicture } = action.payload;
            // Update newly selected photo in profile-pictures list
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((picture) => {
                if (picture.id !== profilePicture.id) {
                    if (picture.category !== profilePicture.category) return picture;
                    // Turn off selected flag. Also, if status is "accepted", then make it "inactive"
                    return {
                        ...picture,
                        isSelected: false,
                        status: picture.status === 'accepted' ? 'inactive' : picture.status,
                    };
                }

                // Mark as selected. If status is "inactive", then make it "accepted".
                return {
                    ...profilePicture,
                    isSelected: true,
                    status: profilePicture.status === 'inactive' ? 'accepted' : profilePicture.status,
                };
            });
            state.updateProfilePicture.requestStatus = 'resolved';
        },
        updateProfilePictureFailed: (state) => {
            state.updateProfilePicture.requestStatus = 'rejected';
        },
        setNewProfilePictureSetup: (state, action: PayloadAction<NewPictureSetup | undefined>) => {
            state.createProfilePicture.newPictureSetup = action.payload;
        },
        createProfilePictureStarted: (state) => {
            state.createProfilePicture.requestStatus = 'pending';
            state.createProfilePicture.profilePicture = undefined;
        },
        createProfilePictureCreated: (state, action: PayloadAction<ProfilePicture>) => {
            // Add profile-photo to list in first position
            state.fetchProfilePictures.pictures = [action.payload].concat(state.fetchProfilePictures.pictures);
        },
        createProfilePictureSucceeded: (state, action: PayloadAction<ProfilePicture>) => {
            const profilePictureId = action.payload.id;
            // Update profile-picture in list with final result
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((profilePicture) => {
                return profilePicture.id === profilePictureId ? action.payload : profilePicture;
            });
            state.createProfilePicture.requestStatus = 'resolved';
            state.createProfilePicture.profilePicture = action.payload;
            state.createProfilePicture.newPictureSetup = undefined;
        },
        createProfilePictureFailed: (state, action: PayloadAction<string | undefined>) => {
            const failedPictureId = action.payload;
            if (failedPictureId) {
                // Remove failed picture from the list
                state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.filter(
                    (picture) => picture.id !== failedPictureId,
                );
            }
            state.createProfilePicture.requestStatus = 'rejected';
            state.createProfilePicture.profilePicture = undefined;
            state.createProfilePicture.newPictureSetup = undefined;
        },
        addWillingnessTagToProfilePictureSetup: (state, action: PayloadAction<ProfilePicture>) => {
            state.addWillingnessTagToProfilePicture.picture = action.payload;
        },
        addWillingnessTagToProfilePictureStopSetup: (state) => {
            state.addWillingnessTagToProfilePicture.requestStatus = 'initial';
            state.addWillingnessTagToProfilePicture.picture = undefined;
        },
        addWillingnessTagToProfilePictureStarted: (state) => {
            state.addWillingnessTagToProfilePicture.requestStatus = 'pending';
        },
        addWillingnessTagToProfilePictureSucceeded: (state, action: PayloadAction<CreateProfilePictureTagPayload>) => {
            state.addWillingnessTagToProfilePicture.requestStatus = 'resolved';
            // Update the tag on the profile-picture
            const targetPicture = state.addWillingnessTagToProfilePicture.picture;
            if (!targetPicture) return;
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((profilePicture) => {
                return targetPicture.id === profilePicture.id
                    ? {
                          ...profilePicture,
                          status: 'waiting',
                          tags: [action.payload.name],
                      }
                    : profilePicture;
            });
            state.addWillingnessTagToProfilePicture.picture = undefined;
        },
        addWillingnessTagToProfilePictureFailed: (state) => {
            state.addWillingnessTagToProfilePicture.requestStatus = 'rejected';
        },
        moveProfilePictureCategoryStarted: (state, action: PayloadAction<MoveProfilePictureCategoryPayload>) => {
            state.moveProfilePictureCategory.id = action.payload.pictureId;
            state.moveProfilePictureCategory.requestStatus = 'pending';
        },
        moveProfilePictureCategorySucceeded: (state, action: PayloadAction<MoveProfilePictureCategoryPayload>) => {
            const { pictureId, category } = action.payload;
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((picture) => {
                if (picture.id !== pictureId) return picture;
                // Update the category and set to waiting state
                return {
                    ...picture,
                    category,
                    status: 'waiting',
                };
            });
            state.moveProfilePictureCategory.requestStatus = 'resolved';
        },
        moveProfilePictureCategoryFailed: (state) => {
            state.moveProfilePictureCategory.requestStatus = 'rejected';
        },
        replaceProfilePicture: (state, action: PayloadAction<{ picture: ProfilePicture; keepVersions?: boolean }>) => {
            const { picture: updatedPicture, keepVersions } = action.payload;

            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((picture) => {
                if (picture.id !== updatedPicture.id) {
                    if (picture.category !== updatedPicture.category) return picture;
                    // There can be only one selected and/or accepted picture, so if our updated picture
                    // has those flags, we need to turn them off here.
                    return {
                        ...picture,
                        isSelected: updatedPicture.isSelected ? false : picture.isSelected,
                        // If this picture was "accepted" but the one we are updating is as well, then this
                        // picture reverts to "inactive" status.
                        status:
                            updatedPicture.status === 'accepted' && picture.status === 'accepted'
                                ? 'inactive'
                                : picture.status,
                    };
                }

                if (keepVersions) {
                    return {
                        ...updatedPicture,
                        versions: picture.versions,
                    };
                }

                return updatedPicture;
            });
        },
        removeProfilePicture: (state, action: PayloadAction<ProfilePicture>) => {
            const removedPicture = action.payload;
            state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.filter(
                (picture) => picture.id !== removedPicture.id,
            );
        },
        reselectPreviousPicture: (state) => {
            const previousPicture = state.createProfilePicture.previousMainProfilePicture;
            // If we are removing the selected picture, and we have a picture stored in "previous" storage, then
            // set the previous picture as selected.
            if (previousPicture) {
                state.fetchProfilePictures.pictures = state.fetchProfilePictures.pictures.map((picture) => {
                    if (picture.category !== previousPicture.category) return picture;
                    const isSelected = picture.id === previousPicture.id;
                    return {
                        ...picture,
                        isSelected,
                    };
                });
                state.createProfilePicture.previousMainProfilePicture = undefined;
            }
        },
        updatePreviousMainProfilePicture: (state, action: PayloadAction<ProfilePicture | undefined>) => {
            state.createProfilePicture.previousMainProfilePicture = action.payload;
        },
    },
});

export const {
    fetchProfilePicturesStarted,
    fetchProfilePicturesSucceeded,
    fetchProfilePicturesFailed,
    deleteProfilePictureStarted,
    deleteProfilePictureSucceeded,
    deleteProfilePictureFailed,
    setProfilePictureAsSelectedStarted,
    setProfilePictureAsSelectedSucceeded,
    updateProfilePictureFailed,
    setNewProfilePictureSetup,
    createProfilePictureStarted,
    createProfilePictureCreated,
    createProfilePictureSucceeded,
    createProfilePictureFailed,
    addWillingnessTagToProfilePictureSetup,
    addWillingnessTagToProfilePictureStopSetup,
    addWillingnessTagToProfilePictureStarted,
    addWillingnessTagToProfilePictureSucceeded,
    addWillingnessTagToProfilePictureFailed,
    moveProfilePictureCategoryStarted,
    moveProfilePictureCategorySucceeded,
    moveProfilePictureCategoryFailed,
    replaceProfilePicture,
    removeProfilePicture,
    reselectPreviousPicture,
    updatePreviousMainProfilePicture,
} = profilePictureSlice.actions;

export default profilePictureSlice.reducer;

import { domain } from 'configs/sites';
// eslint-disable-next-line no-restricted-imports
import { SeasonTypes } from 'domains/Achievements/Achievements.types';
import { globalConfig } from './globalConfig';
import nautilusRoutes from './nautilusRoutes';

const language = globalConfig.language || 'en';

export const routeChunks = {
    messenger: {
        currentChats: `current-chats`,
        recommendedMembers: `recommended`,
    },
};

export const routes = {
    dashboard: `/${language}/dashboard`,
    accountSettings: `/${language}/account-settings`, // required for legacy settings overlay
    logout: `/${language}/logout`,
    personalData: `/${language}/profile/personal-data`,
    profilePicture: `/${language}/profile/profile-picture`,
    profilePictureNonExplicit: `/${language}/profile/profile-picture/non-explicit`,
    profilePictureWillingness: `/${language}/profile/profile-picture/willingness`,
    profileSelect: `/${language}/profile/select`,
    profilePersonalData: `/${language}/profile/personal-data`,
    profilePriceSettings: `/${language}/profile/prices`,
    profilePrivacySettings: `/${language}/profile/privacy`,
    profileActionRequests: `/${language}/profile/action-requests`,
    profileSpecialRequests: `/${language}/profile/special-requests`,
    profileBannedWords: `/${language}/profile/banned-words`,
    profileCountryBan: `/${language}/profile/country-ban`,
    news: `/${language}/help/news`,
    awards: `/${language}/awards`,
    members: `/${language}/members`,
    fanClubMembers: `/${language}/statistics/fan-club`,
    supportChat: `/${language}/online-support-chat`,
    levelDependentPayment: `/${language}/help/income-shares`,
    helpRules: `/${language}/help/rules`,
    supportChatWithTopic: (topic: SupportChatTopics) => `${routes.supportChat}/topic/${topic}`,
    payoutIncomeStatistics: `/${language}/payout/income-statistics`,
    payoutOverview: `/${language}/payout/payout-overview`,
    payoutRecipentTypeSelector: `/${language}/payout/recipient-type-selector`,
    payoutPayinfo: `/${language}/payout/payinfo`,
    payoutBillingAddress: `/${language}/payout/billing-address`,
    payoutDirect: `/${language}/direct-payout`,
    myContentFreeEditor: `/${language}/my-content/editor/free`,
    myContentPremiumEditor: `/${language}/my-content/editor/premium`,
    willingnessSettings: `/${language}/profile/willingness`,
    introductionSettings: `/${language}/profile/introduction`,
    finishMigration: `/${language}/migration/finish-process`,
    displayNameChangeOverlay: `/${language}/display-name-change/overlay`,
    onlineSupportChat: `/${language}/online-support-chat`,
    termsUrl: `/${language}/services-agreement`,
    streamingAgreementUrl: `/${language}/live-streaming-services-agreement`,
    softwareLicenseAgreementUrl: `/${language}/software-licence-agreement`,
    cookiePolicyUrl: `/${language}/cookie-policy`,
    privacyPolicyUrl: `/${language}/privacy-policy`,
    privacyPolicySensitiveDataUrl: `/${language}/privacy-policy#sensitiveData`,
    signUpUrl: (queryParams: string) => `/${language}/signup${queryParams ? `?${queryParams}` : ''}`,
    signUpSelectAccountType: `/${language}/signup/select-account-type`,
    login: `/${language}/login`,
    awardsInfo: {
        video: `/${language}/my-content/editor/premium#videos_folder`,
        picture: `/${language}/my-content/editor/premium#photos_folder`,
    },
    forgotPassword: `/${language}/forgot-password`,
    landscapeTeaser:
        domain === 'oranum'
            ? `/${language}/promotion-tools/video-ads/desktop`
            : `/${language}/promotion-tools/erotic-teaser/desktop`,
    portraitTeaser: `/${language}/promotion-tools/erotic-teaser/mobile`,
    promoTeaserMobile: `/${language}/promotion-tools/promo-teaser/mobile`,
    promoTeaserDesktop: `/${language}/promotion-tools/promo-teaser/desktop`,
    videoCallTeaser: `/${language}/promotion-tools/video-call`,
    explicitVideos: `/${language}/promotion-tools/explicit-videos`,
    explicitVideosConsentModal: `/${language}/promotion-tools/explicit-videos/landing?openConsentModal=1`,
    goOnline: `/${language}/go-online`,
    homePage: `/${language}/`,
    faq: `/${language}/faq`,
    messenger: {
        root: `/${language}/messenger`,
        recommendedMembers: `/${language}/messenger/recommended`,
        currentChats: `/${language}/messenger/current-chats`,
        support: `/${language}/support-messenger/support/inbox`,
        wall: `/${language}/messages/wall`,
        system: `/${language}/support-messenger/system/inbox`,
        automatedMessages: `/${language}/messenger/automated-messages`,
    },
    statistics: {
        dashboard: `/${language}/statistics/dashboard`,
        incomeOverview: `/${language}/statistics/income-overview/overview`,
        payingMembers: `/${language}/statistics/paying-members`,
    },
    studioModels: `/${language}/models`,
    studioCreateModel: `/${language}/models/create-model`,
    studioStatistics: `/${language}/statistics/studio/dashboard`,
    stories: `/${language}/my-content/stories`,
    shorts: `/${language}/my-content/shorts`,
    musicLibrary: `/${language}/music-library`,
    memberReferral: `/${language}/referrals/member-referral`,
    modelReferral: `/${language}/referral`,
    earnMore: {
        actionRequests: `/${language}/earn-more/action-requests`,
        privateShows: `/${language}/earn-more/private-sessions`,
        bemyfan: `/${language}/earn-more/bemyfan`,
        mobileLive: `/${language}/earn-more/mobile-live`,
        jasminSelection: `/${language}/earn-more/jasmin-selection`,
        livejasminSelection: `/${language}/earn-more/jasmin-selection`,
        exclusiveModel: `/${language}/earn-more/exclusive-model`,
        videoCall: `/${language}/earn-more/video-call`,
        myStory: `/${language}/earn-more/my-story`,
        messenger: `/${language}/earn-more/messenger-introduction`,
        privateShowDiscount:
            domain === 'oranum'
                ? `/${language}/earn-more/private-session-discounts`
                : `/${language}/earn-more/private-show-discount`,
        messagingStreak: `/${language}/earn-more/messaging-streak`,
        eroticTeaser: `/${language}/earn-more/erotic-teasers`,
        kingOfTheRoom: `/${language}/earn-more/king-of-the-room`,
        vipShow: domain === 'oranum' ? `/${language}/earn-more/group-session` : `/${language}/earn-more/vip-show`,
        clubElite: `/${language}/earn-more/club-elite`,
        promoVideo: `/${language}/earn-more/promo-video`,
        privateGame: `/${language}/earn-more/private-game`,
        protection: `/${language}/earn-more/protection`,
        twoWayAudio: `/${language}/earn-more/two-way-audio`,
        payingSnapshots: `/${language}/earn-more/paying-snapshot`,
        memberReferral: `/${language}/earn-more/member-referral`,
        cam2cam: `/${language}/earn-more/cam-to-cam`,
        interactiveToy: `/${language}/earn-more/interactive-toy`,
        tips: `/${language}/earn-more/tips`,
        fanClub: `/${language}/earn-more/fan-club`,
        soldierAid: `/${language}/earn-more/soldier-aid`,
        loyalfans: `/${language}/earn-more/loyalfans`,
    },
    certifiedStudios: `/${language}/certified-partners`,
    error500: `/${language}/error/500`,
    topModels: (season?: SeasonTypes | null) =>
        domain === 'oranum'
            ? `/${language}/bestof/topexperts`
            : `/${language}/bestof/topmodels${season ? `/${season}` : ''}`,
    topModelsWinners:
        domain === 'oranum' ? `/${language}/bestof/topexperts-winners` : `/${language}/bestof/topmodels-winners`,
    topExpertsLastMonthWinners: `/${language}/bestof/topexperts-last-month-winners`,

    ...nautilusRoutes,
};

export enum MusicLibraryRoutes {
    MAIN = '/',
    PLAYLIST = '/playlist',
    SEARCH_RESULTS = '/search',
    FAVORITES = '/favorites',
}

export enum SupportChatTopics {
    REGISTRATION = 'registration',
    TECHNICAL = 'technical',
    PICTURE_OR_VIDEO = 'picture-or-video',
    AUDIO_OR_VIDEO_RATING = 'audio-or-video-rating',
    OTHER = 'other',
}

export default routes;

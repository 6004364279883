import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectionTask } from 'domains/SelectionTaskList/SelectionTaskList.types';
import { SelectionTaskListState } from './SelectionTaskList.types';

const initialState: SelectionTaskListState = {
    taskList: undefined,
    status: 'initial',
    errorMessage: undefined,
};

const selectionTaskListSlice = createSlice({
    name: 'selectionTaskList',
    initialState,
    reducers: {
        fetchTaskListStarted: (state) => {
            state.taskList = undefined;
            state.status = 'pending';
            state.errorMessage = undefined;
        },
        fetchTaskListSucceeded: (state, action: PayloadAction<SelectionTask[]>) => {
            state.taskList = action.payload;
            state.status = 'resolved';
        },
        fetchTaskListFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.status = 'rejected';
        },
    },
});

export const { fetchTaskListStarted, fetchTaskListSucceeded, fetchTaskListFailed } = selectionTaskListSlice.actions;
export default selectionTaskListSlice.reducer;

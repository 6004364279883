import React, { FC, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { deleteToast, ToastMeta } from 'store/slices/Toast/Toast.slice';
import useAppSelector from 'store/hooks/useAppSelector';
import useAppDispatch from 'store/hooks/useAppDispatch';
import useToast from 'hooks/useToast';
import is from 'utils/is';

import { TOAST_EVENT_NAME } from './Toasts.config';
import config from './Toast/Toast.config';
import Toast from './Toast';

const ToastsContainer: FC = () => {
    const dispatch = useAppDispatch();
    const toastHook = useToast();
    const { toasts } = useAppSelector((state) => state.toast);
    const toastsQueue: [string, ToastMeta][] = Object.entries(toasts);
    const [pendingToasts, setPendingToasts] = useState<[string, ToastMeta][]>([]);
    const [visibleToasts, setVisibleToasts] = useState<[string, ToastMeta][]>([]);

    const deleteVisibleToast = (id: string) => {
        dispatch(deleteToast({ id }));
        setVisibleToasts((oldVal) =>
            oldVal.filter(([visibleId, _]) => {
                return id !== visibleId;
            }),
        );
    };

    const handleCloseClick = useCallback(
        (id: string) => {
            deleteVisibleToast(id);
        },
        [deleteVisibleToast],
    );

    const queueDispatchedToastEvent = useCallback(
        (event: CustomEvent) => {
            if (event.detail) {
                toastHook(event.detail as ToastMeta);
            }
        },
        [toastHook],
    );

    // Listen for externally-triggered toasts (via dispatchToastEvent)
    useEffect(() => {
        window.addEventListener(TOAST_EVENT_NAME, queueDispatchedToastEvent as EventListener);

        return () => {
            window.removeEventListener(TOAST_EVENT_NAME, queueDispatchedToastEvent as EventListener);
        };
    }, [queueDispatchedToastEvent]);

    // Add toasts from the queue to pending
    useEffect(() => {
        if (toastsQueue.length) {
            const newPendingQueue = toastsQueue
                .sort((a, b) => {
                    return (
                        config.priorityWeights[b[1].priority || 'medium'] -
                        config.priorityWeights[a[1].priority || 'medium']
                    );
                })
                .filter(([pendingId, _]) => {
                    return !visibleToasts.some(([visibleId, _]) => pendingId === visibleId);
                });

            setPendingToasts(newPendingQueue);
        }
    }, [toastsQueue.length]);

    // Transfer toasts from pending to visible
    useEffect(() => {
        if (visibleToasts.length < config.maxVisibleToasts && pendingToasts.length) {
            const newToast = pendingToasts.slice(0, 1)[0];
            if (newToast[1].timeout !== false) {
                setTimeout(
                    () => {
                        deleteVisibleToast(newToast[0]);
                    },
                    (is.number(newToast[1].timeout) && (newToast[1].timeout as number)) || config.defaultTimeout,
                );
            }

            setPendingToasts((oldVal) => oldVal.slice(1));
            setVisibleToasts((oldVal) => [newToast].concat(oldVal));
        }
    }, [visibleToasts.length, pendingToasts.length]);

    return (
        <>
            {visibleToasts.map(([id, toast], index) => (
                <Toast
                    key={id}
                    content={toast.message}
                    position={index}
                    icon={toast.icon}
                    isOpen
                    onCloseClick={handleCloseClick}
                    id={id}
                    hasClose={toast?.isCloseable !== false}
                />
            ))}
        </>
    );
};

export default ToastsContainer;

import React, { createContext, useContext, useEffect } from 'react';
import type { Products } from 'configs/globalConfig';
import { getDomain } from 'configs/sites';
import styles from 'global/themes';

export interface ThemeContextInterface {
    theme: Products;
    isLiveJasmin: boolean;
    isOranum: boolean;
}

interface ThemeProviderProps {
    theme?: Products;
    children: React.ReactNode;
}

const ThemeContext = createContext<ThemeContextInterface | undefined>(undefined);

export const ThemeContextProvider: React.FC<ThemeProviderProps> = ({ theme = getDomain(), children }) => {
    useEffect(() => {
        document.body.classList.add(styles[theme], styles.root, styles.common);

        return () => {
            document.body.classList.remove(styles[theme], styles.root, styles.common);
        };
    }, [theme]);
    return (
        <ThemeContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                theme,
                isLiveJasmin: theme === 'livejasmin',
                isOranum: theme === 'oranum',
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        // https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript--flow
        throw new Error('useTheme must be used within a ThemeContextProvider');
    }
    return context;
};

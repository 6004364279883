import { compileVariables, tryParseToSnakeCase } from './utils';
import type { TrackingArgument, DefineTracking, TrackingEvent } from './types';

/**
 * Normalize Tracking Values
 * @description ensure specific values in snake_case
 * @param {DefineTracking} tracking
 */
const normalizeTrackingValues = <Input extends TrackingArgument, Output extends DefineTracking<Input>>(
    tracking: Input,
): Output => {
    return Object.keys(tracking).reduce(
        (acc, key) => ({
            ...acc,
            [key]: {
                nonInteraction: false,
                ...tracking[key],
                action: tryParseToSnakeCase(tracking[key].action)!,
                label: tryParseToSnakeCase(tracking[key].label)!,
                format(values?: Record<string, string>): TrackingEvent {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { format, ...data } = this as Output[keyof Output];

                    return compileVariables(data, values);
                },
            },
        }),
        {} as Output,
    );
};

const defineTracking = <T extends TrackingArgument>(tracking: T): DefineTracking<T> => {
    return normalizeTrackingValues(structuredClone(tracking));
};

export type { DefineTracking };
export { defineTracking };

/**
 * File generated using "pnpm generate-icons"
 * DO NOT MANUALLY UPDATE IT
 */
export enum IconList {
    achievementsOutline = 'achievements-outline',
    achievementsSolid = 'achievements-solid',
    actionListOutline = 'action-list-outline',
    actionListSolid = 'action-list-solid',
    archiveOutline = 'archive-outline',
    archiveSolid = 'archive-solid',
    arrowDownCircularOutline = 'arrow-down-circular-outline',
    arrowDownCircularSolid = 'arrow-down-circular-solid',
    arrowDownDoubleCircularOutline = 'arrow-down-double-circular-outline',
    arrowDownDoubleCircularSolid = 'arrow-down-double-circular-solid',
    arrowDownDoubleOutline = 'arrow-down-double-outline',
    arrowDownDoubleSolid = 'arrow-down-double-solid',
    arrowDownOutline = 'arrow-down-outline',
    arrowDownSolid = 'arrow-down-solid',
    arrowDownTripleCircularOutline = 'arrow-down-triple-circular-outline',
    arrowDownTripleCircularSolid = 'arrow-down-triple-circular-solid',
    arrowDownTripleOutline = 'arrow-down-triple-outline',
    arrowDownTripleSolid = 'arrow-down-triple-solid',
    arrowLeftCircularOutline = 'arrow-left-circular-outline',
    arrowLeftCircularSolid = 'arrow-left-circular-solid',
    arrowLeftOutline = 'arrow-left-outline',
    arrowLeftSolid = 'arrow-left-solid',
    arrowRightCircularOutline = 'arrow-right-circular-outline',
    arrowRightCircularSolid = 'arrow-right-circular-solid',
    arrowRightOutline = 'arrow-right-outline',
    arrowRightSolid = 'arrow-right-solid',
    arrowUpCircularOutline = 'arrow-up-circular-outline',
    arrowUpCircularSolid = 'arrow-up-circular-solid',
    arrowUpDoubleCircularOutline = 'arrow-up-double-circular-outline',
    arrowUpDoubleCircularSolid = 'arrow-up-double-circular-solid',
    arrowUpDoubleOutline = 'arrow-up-double-outline',
    arrowUpDoubleSolid = 'arrow-up-double-solid',
    arrowUpOutline = 'arrow-up-outline',
    arrowUpSolid = 'arrow-up-solid',
    arrowUpTripleCircularOutline = 'arrow-up-triple-circular-outline',
    arrowUpTripleCircularSolid = 'arrow-up-triple-circular-solid',
    arrowUpTripleOutline = 'arrow-up-triple-outline',
    arrowUpTripleSolid = 'arrow-up-triple-solid',
    aspectRatioOutline = 'aspect-ratio-outline',
    aspectRatioSolid = 'aspect-ratio-solid',
    attachmentOutline = 'attachment-outline',
    attachmentSolid = 'attachment-solid',
    battery0 = 'battery0',
    battery100 = 'battery100',
    battery15 = 'battery15',
    battery45 = 'battery45',
    battery75 = 'battery75',
    battleOutline = 'battle-outline',
    battleSolid = 'battle-solid',
    bluetoothOffOutline = 'bluetooth-off-outline',
    bluetoothOffSolid = 'bluetooth-off-solid',
    bluetoothOnOutline = 'bluetooth-on-outline',
    bluetoothOnSolid = 'bluetooth-on-solid',
    blurBackgroundOutline = 'blur-background-outline',
    blurBackgroundSolid = 'blur-background-solid',
    blurOutline = 'blur-outline',
    blurSolid = 'blur-solid',
    bookmarkAddOutline = 'bookmark-add-outline',
    bookmarkAddSolid = 'bookmark-add-solid',
    bookmarkOutline = 'bookmark-outline',
    bookmarkSolid = 'bookmark-solid',
    brightnessOutline = 'brightness-outline',
    brightnessSolid = 'brightness-solid',
    bwFilterOutline = 'bw-filter-outline',
    bwFilterSolid = 'bw-filter-solid',
    cakeOutline = 'cake-outline',
    cakeSolid = 'cake-solid',
    calendarOutline = 'calendar-outline',
    calendarSolid = 'calendar-solid',
    cameraOutline = 'camera-outline',
    cameraSettingsOutline = 'camera-settings-outline',
    cameraSettingsSolid = 'camera-settings-solid',
    cameraSolid = 'camera-solid',
    capslockOutline = 'capslock-outline',
    capslockSolid = 'capslock-solid',
    caretDouble = 'caret-double',
    caretDown = 'caret-down',
    caretLeft = 'caret-left',
    caretRight = 'caret-right',
    caretUp = 'caret-up',
    chartBarsOutline = 'chart-bars-outline',
    chartBarsSolid = 'chart-bars-solid',
    chartDonutOutline = 'chart-donut-outline',
    chartDonutSolid = 'chart-donut-solid',
    chartLinesOutline = 'chart-lines-outline',
    chartLinesSolid = 'chart-lines-solid',
    checkCircularOutline = 'check-circular-outline',
    checkCircularSolid = 'check-circular-solid',
    checkDoubleOutline = 'check-double-outline',
    checkDoubleSolid = 'check-double-solid',
    checkOutline = 'check-outline',
    checkSolid = 'check-solid',
    clockOutline = 'clock-outline',
    clockSolid = 'clock-solid',
    closeCircularOutline = 'close-circular-outline',
    closeCircularSolid = 'close-circular-solid',
    closeOutline = 'close-outline',
    closeSolid = 'close-solid',
    coinBimBimOutline = 'coin-bim-bim-outline',
    coinBimBimSolid = 'coin-bim-bim-solid',
    coinDollarOutline = 'coin-dollar-outline',
    coinDollarSolid = 'coin-dollar-solid',
    coinJasminOutline = 'coin-jasmin-outline',
    coinJasminSolid = 'coin-jasmin-solid',
    coinTipOutline = 'coin-tip-outline',
    coinTipSolid = 'coin-tip-solid',
    commentsOffOutline = 'comments-off-outline',
    commentsOffSolid = 'comments-off-solid',
    commentsOnOutline = 'comments-on-outline',
    commentsOnSolid = 'comments-on-solid',
    connectionOffOutline = 'connection-off-outline',
    connectionOffSolid = 'connection-off-solid',
    connectionOnOutline = 'connection-on-outline',
    connectionOnSolid = 'connection-on-solid',
    contentQualityOutline = 'content-quality-outline',
    contentQualitySolid = 'content-quality-solid',
    contrastOutline = 'contrast-outline',
    contrastSolid = 'contrast-solid',
    copyOutline = 'copy-outline',
    copySolid = 'copy-solid',
    creditCardOutline = 'credit-card-outline',
    creditCardSolid = 'credit-card-solid',
    creditsOutline = 'credits-outline',
    creditsRiseOutline = 'credits-rise-outline',
    creditsRiseSolid = 'credits-rise-solid',
    creditsSolid = 'credits-solid',
    crownOutline = 'crown-outline',
    crownSolid = 'crown-solid',
    desktopMobileOutline = 'desktop-mobile-outline',
    desktopMobileSolid = 'desktop-mobile-solid',
    desktopOutline = 'desktop-outline',
    desktopSettingsOutline = 'desktop-settings-outline',
    desktopSettingsSolid = 'desktop-settings-solid',
    desktopSolid = 'desktop-solid',
    desktopVolumeOutline = 'desktop-volume-outline',
    desktopVolumeSolid = 'desktop-volume-solid',
    devilHeartOutline = 'devil-heart-outline',
    devilHeartSolid = 'devil-heart-solid',
    diamondOutline = 'diamond-outline',
    diamondSolid = 'diamond-solid',
    disabledOutline = 'disabled-outline',
    disabledSolid = 'disabled-solid',
    dislikeOutline = 'dislike-outline',
    dislikeSolid = 'dislike-solid',
    dollarOutline = 'dollar-outline',
    dollarSolid = 'dollar-solid',
    downloadOutline = 'download-outline',
    downloadSolid = 'download-solid',
    dragIndicatorOutline = 'drag-indicator-outline',
    dragIndicatorSolid = 'drag-indicator-solid',
    editOutline = 'edit-outline',
    editSolid = 'edit-solid',
    editorOutline = 'editor-outline',
    editorSolid = 'editor-solid',
    envelopeOutline = 'envelope-outline',
    envelopeSolid = 'envelope-solid',
    equalizerOutline = 'equalizer-outline',
    equalizerSolid = 'equalizer-solid',
    exposureOutline = 'exposure-outline',
    exposureSolid = 'exposure-solid',
    eyeOffCircularOutline = 'eye-off-circular-outline',
    eyeOffCircularSolid = 'eye-off-circular-solid',
    eyeOffOutline = 'eye-off-outline',
    eyeOffSolid = 'eye-off-solid',
    eyeOnCircularOutline = 'eye-on-circular-outline',
    eyeOnCircularSolid = 'eye-on-circular-solid',
    eyeOnOutline = 'eye-on-outline',
    eyeOnSolid = 'eye-on-solid',
    eyeTrophyOutline = 'eye-trophy-outline',
    eyeTrophySolid = 'eye-trophy-solid',
    facebook = 'facebook',
    fanclubOutline = 'fanclub-outline',
    fanclubSolid = 'fanclub-solid',
    filterOutline = 'filter-outline',
    filterSolid = 'filter-solid',
    flagOutline = 'flag-outline',
    flagSolid = 'flag-solid',
    flameOutline = 'flame-outline',
    flameSolid = 'flame-solid',
    flashOutline = 'flash-outline',
    flashSolid = 'flash-solid',
    flipOutline = 'flip-outline',
    flipSolid = 'flip-solid',
    focusOutline = 'focus-outline',
    focusSolid = 'focus-solid',
    folderAddOutline = 'folder-add-outline',
    folderAddSolid = 'folder-add-solid',
    folderOutline = 'folder-outline',
    folderPhotoOutline = 'folder-photo-outline',
    folderPhotoSolid = 'folder-photo-solid',
    folderSolid = 'folder-solid',
    folderVideoOutline = 'folder-video-outline',
    folderVideoSolid = 'folder-video-solid',
    forwardOutline = 'forward-outline',
    forwardSolid = 'forward-solid',
    fullScreenCloseOutline = 'full-screen-close-outline',
    fullScreenCloseSolid = 'full-screen-close-solid',
    fullScreenOutline = 'full-screen-outline',
    fullScreenSolid = 'full-screen-solid',
    giftOutline = 'gift-outline',
    giftSolid = 'gift-solid',
    globeOutline = 'globe-outline',
    globeSolid = 'globe-solid',
    gridLargeOutline = 'grid-large-outline',
    gridLargeSolid = 'grid-large-solid',
    gridMediumOutline = 'grid-medium-outline',
    gridMediumSolid = 'grid-medium-solid',
    gridSmallOutline = 'grid-small-outline',
    gridSmallSolid = 'grid-small-solid',
    groupAddOutline = 'group-add-outline',
    groupAddSolid = 'group-add-solid',
    groupOutline = 'group-outline',
    groupSolid = 'group-solid',
    groupStudioOutline = 'group-studio-outline',
    groupStudioSolid = 'group-studio-solid',
    groupThreeOutline = 'group-three-outline',
    groupThreeSolid = 'group-three-solid',
    groupTwoOutline = 'group-two-outline',
    groupTwoSolid = 'group-two-solid',
    hashtagCircularOutline = 'hashtag-circular-outline',
    hashtagCircularSolid = 'hashtag-circular-solid',
    hashtagOutline = 'hashtag-outline',
    hashtagSolid = 'hashtag-solid',
    hdOutline = 'hd-outline',
    hdSolid = 'hd-solid',
    headphonesOutline = 'headphones-outline',
    headphonesSolid = 'headphones-solid',
    heartOutline = 'heart-outline',
    heartSolid = 'heart-solid',
    heartbeatOutline = 'heartbeat-outline',
    heartbeatSolid = 'heartbeat-solid',
    helpCircularOutline = 'help-circular-outline',
    helpCircularSolid = 'help-circular-solid',
    helpOutline = 'help-outline',
    helpSolid = 'help-solid',
    highlightsOutline = 'highlights-outline',
    highlightsSolid = 'highlights-solid',
    historyOutline = 'history-outline',
    historySolid = 'history-solid',
    homeOutline = 'home-outline',
    homeSolid = 'home-solid',
    hourglassOutline = 'hourglass-outline',
    hourglassSolid = 'hourglass-solid',
    idCardOutline = 'id-card-outline',
    idCardSolid = 'id-card-solid',
    indoorOutdoorOutline = 'indoor-outdoor-outline',
    indoorOutdoorSolid = 'indoor-outdoor-solid',
    indoorSolid = 'indoor-solid',
    infinitOutline = 'infinit-outline',
    infinitSolid = 'infinit-solid',
    infoCircularOutline = 'info-circular-outline',
    infoCircularSolid = 'info-circular-solid',
    infoOutline = 'info-outline',
    infoSolid = 'info-solid',
    instagram = 'instagram',
    interactiveToyOutline = 'interactive-toy-outline',
    interactiveToySolid = 'interactive-toy-solid',
    interactiveToyVibrationOutline = 'interactive-toy-vibration-outline',
    interactiveToyVibrationSolid = 'interactive-toy-vibration-solid',
    keyboardOutline = 'keyboard-outline',
    keyboardSolid = 'keyboard-solid',
    kickOutline = 'kick-outline',
    kickSolid = 'kick-solid',
    lightBulbOutline = 'light-bulb-outline',
    lightBulbSolid = 'light-bulb-solid',
    lightBulbSparkleOutline = 'light-bulb-sparkle-outline',
    lightBulbSparkleSolid = 'light-bulb-sparkle-solid',
    likeOutline = 'like-outline',
    likeSolid = 'like-solid',
    lipsOutline = 'lips-outline',
    lipsSolid = 'lips-solid',
    liveOutline = 'live-outline',
    liveSolid = 'live-solid',
    locationOutline = 'location-outline',
    locationSolid = 'location-solid',
    locketCloseOutline = 'locket-close-outline',
    locketCloseSolid = 'locket-close-solid',
    locketHeartCloseOutline = 'locket-heart-close-outline',
    locketHeartCloseSolid = 'locket-heart-close-solid',
    locketHeartOpenOutline = 'locket-heart-open-outline',
    locketHeartOpenSolid = 'locket-heart-open-solid',
    locketMoneyCloseOutline = 'locket-money-close-outline',
    locketMoneyCloseSolid = 'locket-money-close-solid',
    locketOpenOutline = 'locket-open-outline',
    locketOpenSolid = 'locket-open-solid',
    loginOutline = 'login-outline',
    loginSolid = 'login-solid',
    logosLoyalfans = 'logos-loyalfans',
    logoutOutline = 'logout-outline',
    logoutSolid = 'logout-solid',
    magazineOutline = 'magazine-outline',
    magazineSolid = 'magazine-solid',
    maskOutline = 'mask-outline',
    maskSolid = 'mask-solid',
    massMessageOutline = 'mass-message-outline',
    massMessageSolid = 'mass-message-solid',
    medalStarOutline = 'medal-star-outline',
    medalStarSolid = 'medal-star-solid',
    megaphoneOutline = 'megaphone-outline',
    megaphoneSolid = 'megaphone-solid',
    menuHamburgerOutline = 'menu-hamburger-outline',
    menuHamburgerSolid = 'menu-hamburger-solid',
    menuMoreHorizontalOutline = 'menu-more-horizontal-outline',
    menuMoreHorizontalSolid = 'menu-more-horizontal-solid',
    menuMoreVerticalOutline = 'menu-more-vertical-outline',
    menuMoreVerticalSolid = 'menu-more-vertical-solid',
    microphoneOffOutline = 'microphone-off-outline',
    microphoneOffSolid = 'microphone-off-solid',
    microphoneOnOutline = 'microphone-on-outline',
    microphoneOnSolid = 'microphone-on-solid',
    minusCircularOutline = 'minus-circular-outline',
    minusCircularSolid = 'minus-circular-solid',
    minusOutline = 'minus-outline',
    minusSolid = 'minus-solid',
    mobileOutline = 'mobile-outline',
    mobileRotationOutline = 'mobile-rotation-outline',
    mobileRotationSolid = 'mobile-rotation-solid',
    mobileSolid = 'mobile-solid',
    moonOutline = 'moon-outline',
    moonSolid = 'moon-solid',
    movieOutline = 'movie-outline',
    movieSolid = 'movie-solid',
    musicNoteOutline = 'music-note-outline',
    musicNoteSolid = 'music-note-solid',
    myContentOutline = 'my-content-outline',
    myContentSolid = 'my-content-solid',
    nextOutline = 'next-outline',
    nextSolid = 'next-solid',
    nextSongOutline = 'next-song-outline',
    nextSongSolid = 'next-song-solid',
    noteAddOutline = 'note-add-outline',
    noteAddSolid = 'note-add-solid',
    noteOutline = 'note-outline',
    noteSolid = 'note-solid',
    notificationsOffOutline = 'notifications-off-outline',
    notificationsOffSolid = 'notifications-off-solid',
    notificationsOnOutline = 'notifications-on-outline',
    notificationsOnSolid = 'notifications-on-solid',
    outdoorSolid = 'outdoor-solid',
    pasteOutline = 'paste-outline',
    pasteSolid = 'paste-solid',
    pauseCircularOutline = 'pause-circular-outline',
    pauseCircularSolid = 'pause-circular-solid',
    pauseOutline = 'pause-outline',
    pauseSolid = 'pause-solid',
    payoutOutline = 'payout-outline',
    payoutSolid = 'payout-solid',
    percentageOutline = 'percentage-outline',
    percentageSolid = 'percentage-solid',
    performanceOutline = 'performance-outline',
    performanceSolid = 'performance-solid',
    phoneDownOutline = 'phone-down-outline',
    phoneDownSolid = 'phone-down-solid',
    phoneOutline = 'phone-outline',
    phoneSolid = 'phone-solid',
    photoAddOutline = 'photo-add-outline',
    photoAddSolid = 'photo-add-solid',
    photoFilterOutline = 'photo-filter-outline',
    photoFilterSolid = 'photo-filter-solid',
    photoGalleryOutline = 'photo-gallery-outline',
    photoGallerySolid = 'photo-gallery-solid',
    photoOffOutline = 'photo-off-outline',
    photoOffSolid = 'photo-off-solid',
    photoSingleOutline = 'photo-single-outline',
    photoSingleSolid = 'photo-single-solid',
    pinOutline = 'pin-outline',
    pinSolid = 'pin-solid',
    playCircularOutline = 'play-circular-outline',
    playCircularSolid = 'play-circular-solid',
    playOutline = 'play-outline',
    playSolid = 'play-solid',
    plusCircularOutline = 'plus-circular-outline',
    plusCircularSolid = 'plus-circular-solid',
    plusOutline = 'plus-outline',
    plusSolid = 'plus-solid',
    previousOutline = 'previous-outline',
    previousSolid = 'previous-solid',
    previousSongOutline = 'previous-song-outline',
    previousSongSolid = 'previous-song-solid',
    privateInvitationCancelOutline = 'private-invitation-cancel-outline',
    privateInvitationCancelSolid = 'private-invitation-cancel-solid',
    privateInvitationOutline = 'private-invitation-outline',
    privateInvitationProgressOutline = 'private-invitation-progress-outline',
    privateInvitationProgressSolid = 'private-invitation-progress-solid',
    privateInvitationProgressStarOutline = 'private-invitation-progress-star-outline',
    privateInvitationProgressStarSolid = 'private-invitation-progress-star-solid',
    privateInvitationSolid = 'private-invitation-solid',
    privateInvitationStarOutline = 'private-invitation-star-outline',
    privateInvitationStarSolid = 'private-invitation-star-solid',
    privateInvitationSuccessOutline = 'private-invitation-success-outline',
    privateInvitationSuccessSolid = 'private-invitation-success-solid',
    proOutline = 'pro-outline',
    proSolid = 'pro-solid',
    profileAddOutline = 'profile-add-outline',
    profileAddSolid = 'profile-add-solid',
    profileDetailsOutline = 'profile-details-outline',
    profileDetailsSolid = 'profile-details-solid',
    profileFrameOutline = 'profile-frame-outline',
    profileFrameSolid = 'profile-frame-solid',
    profileOutline = 'profile-outline',
    profileSolid = 'profile-solid',
    profileStudioOutline = 'profile-studio-outline',
    profileStudioSolid = 'profile-studio-solid',
    promotionDeleteOutline = 'promotion-delete-outline',
    promotionDeleteSolid = 'promotion-delete-solid',
    promotionOutline = 'promotion-outline',
    promotionSolid = 'promotion-solid',
    publishOutline = 'publish-outline',
    publishSolid = 'publish-solid',
    puzzleOutline = 'puzzle-outline',
    puzzleSolid = 'puzzle-solid',
    quickReplyOutline = 'quick-reply-outline',
    quickReplySolid = 'quick-reply-solid',
    quoteOutline = 'quote-outline',
    quoteSolid = 'quote-solid',
    recordCircularOutline = 'record-circular-outline',
    recordCircularSolid = 'record-circular-solid',
    referralOutline = 'referral-outline',
    referralSolid = 'referral-solid',
    reloadOutline = 'reload-outline',
    reloadSolid = 'reload-solid',
    repeatOutline = 'repeat-outline',
    repeatSolid = 'repeat-solid',
    replyOutline = 'reply-outline',
    replySolid = 'reply-solid',
    robotOutline = 'robot-outline',
    robotSolid = 'robot-solid',
    rocketOutline = 'rocket-outline',
    rocketSolid = 'rocket-solid',
    roomTopicOutline = 'room-topic-outline',
    roomTopicSolid = 'room-topic-solid',
    rotateOutline = 'rotate-outline',
    rotateSolid = 'rotate-solid',
    rotation360Outline = 'rotation360-outline',
    rotation360Solid = 'rotation360-solid',
    saturationOutline = 'saturation-outline',
    saturationSolid = 'saturation-solid',
    scissorsOutline = 'scissors-outline',
    scissorsSolid = 'scissors-solid',
    screenFullOutline = 'screen-full-outline',
    screenFullSolid = 'screen-full-solid',
    screenLargeOutline = 'screen-large-outline',
    screenLargeSolid = 'screen-large-solid',
    screenStandardOutline = 'screen-standard-outline',
    screenStandardSolid = 'screen-standard-solid',
    searchCircularOutline = 'search-circular-outline',
    searchCircularSolid = 'search-circular-solid',
    searchOutline = 'search-outline',
    searchSolid = 'search-solid',
    sendOutline = 'send-outline',
    sendSolid = 'send-solid',
    settingsOutline = 'settings-outline',
    settingsSolid = 'settings-solid',
    shareOutline = 'share-outline',
    shareSolid = 'share-solid',
    sharpnessOutline = 'sharpness-outline',
    sharpnessSolid = 'sharpness-solid',
    shieldOutline = 'shield-outline',
    shieldSolid = 'shield-solid',
    shoppingBagHeartOutline = 'shopping-bag-heart-outline',
    shoppingBagHeartSolid = 'shopping-bag-heart-solid',
    shoppingBagOutline = 'shopping-bag-outline',
    shoppingBagSolid = 'shopping-bag-solid',
    shuffleOutline = 'shuffle-outline',
    shuffleSolid = 'shuffle-solid',
    shutterTimerOutline = 'shutter-timer-outline',
    shutterTimerSolid = 'shutter-timer-solid',
    smileyHappyOutline = 'smiley-happy-outline',
    smileyHappySolid = 'smiley-happy-solid',
    smileyNeutralOutline = 'smiley-neutral-outline',
    smileyNeutralSolid = 'smiley-neutral-solid',
    smileySadOutline = 'smiley-sad-outline',
    smileySadSolid = 'smiley-sad-solid',
    sortOutline = 'sort-outline',
    sortSolid = 'sort-solid',
    speechTextOutline = 'speech-text-outline',
    speechTextSolid = 'speech-text-solid',
    speedOutline = 'speed-outline',
    speedSolid = 'speed-solid',
    speedTestDownloadOutline = 'speed-test-download-outline',
    speedTestDownloadSolid = 'speed-test-download-solid',
    speedTestJitterOutline = 'speed-test-jitter-outline',
    speedTestJitterSolid = 'speed-test-jitter-solid',
    speedTestPingOutline = 'speed-test-ping-outline',
    speedTestPingSolid = 'speed-test-ping-solid',
    speedTestUploadOutline = 'speed-test-upload-outline',
    speedTestUploadSolid = 'speed-test-upload-solid',
    squeezeOutline = 'squeeze-outline',
    squeezeSolid = 'squeeze-solid',
    starCircularOutline = 'star-circular-outline',
    starCircularSolid = 'star-circular-solid',
    starDoubleOutline = 'star-double-outline',
    starDoubleSolid = 'star-double-solid',
    starOutline = 'star-outline',
    starSolid = 'star-solid',
    starTripleOutline = 'star-triple-outline',
    starTripleSolid = 'star-triple-solid',
    stopCircularOutline = 'stop-circular-outline',
    stopCircularSolid = 'stop-circular-solid',
    stopOutline = 'stop-outline',
    stopSolid = 'stop-solid',
    storiesOutline = 'stories-outline',
    storiesSolid = 'stories-solid',
    streamExpandOutline = 'stream-expand-outline',
    streamExpandPortraitOutline = 'stream-expand-portrait-outline',
    streamExpandPortraitSolid = 'stream-expand-portrait-solid',
    streamExpandSolid = 'stream-expand-solid',
    streamMinimizeOutline = 'stream-minimize-outline',
    streamMinimizePortraitOutline = 'stream-minimize-portrait-outline',
    streamMinimizePortraitSolid = 'stream-minimize-portrait-solid',
    streamMinimizeSolid = 'stream-minimize-solid',
    streamOutline = 'stream-outline',
    streamSolid = 'stream-solid',
    suctionOutline = 'suction-outline',
    suctionSolid = 'suction-solid',
    sunOutline = 'sun-outline',
    sunSolid = 'sun-solid',
    supportOutline = 'support-outline',
    supportSolid = 'support-solid',
    swapOutline = 'swap-outline',
    swapSolid = 'swap-solid',
    tableChartOutline = 'table-chart-outline',
    tableChartSolid = 'table-chart-solid',
    tagOutline = 'tag-outline',
    tagSolid = 'tag-solid',
    telegram = 'telegram',
    textSizeOutline = 'text-size-outline',
    textSizeSolid = 'text-size-solid',
    thermometerOutline = 'thermometer-outline',
    thermometerSolid = 'thermometer-solid',
    thrustingOutline = 'thrusting-outline',
    thrustingSolid = 'thrusting-solid',
    tikTok = 'tik-tok',
    tonalityOutline = 'tonality-outline',
    tonalitySolid = 'tonality-solid',
    toy2ToyOffOutline = 'toy2-toy-off-outline',
    toy2ToyOffSolid = 'toy2-toy-off-solid',
    toy2ToyOnOutline = 'toy2-toy-on-outline',
    toy2ToyOnSolid = 'toy2-toy-on-solid',
    translationOutline = 'translation-outline',
    translationSolid = 'translation-solid',
    trashOutline = 'trash-outline',
    trashRestoreOutline = 'trash-restore-outline',
    trashRestoreSolid = 'trash-restore-solid',
    trashSolid = 'trash-solid',
    trendingDownOutline = 'trending-down-outline',
    trendingDownSolid = 'trending-down-solid',
    trendingUpOutline = 'trending-up-outline',
    trendingUpSolid = 'trending-up-solid',
    trophyOutline = 'trophy-outline',
    trophySolid = 'trophy-solid',
    tutorialOutline = 'tutorial-outline',
    tutorialSolid = 'tutorial-solid',
    twitter = 'twitter',
    unarchiveOutline = 'unarchive-outline',
    unarchiveSolid = 'unarchive-solid',
    uploadOutline = 'upload-outline',
    uploadSolid = 'upload-solid',
    vibrationOutline = 'vibration-outline',
    vibrationSolid = 'vibration-solid',
    videoCallOffOutline = 'video-call-off-outline',
    videoCallOffSolid = 'video-call-off-solid',
    videoCallOnOutline = 'video-call-on-outline',
    videoCallOnSolid = 'video-call-on-solid',
    videoCameraOutline = 'video-camera-outline',
    videoCameraSolid = 'video-camera-solid',
    videoLandscapeAddOutline = 'video-landscape-add-outline',
    videoLandscapeAddSolid = 'video-landscape-add-solid',
    videoLandscapeOutline = 'video-landscape-outline',
    videoLandscapeSolid = 'video-landscape-solid',
    videoPortraitAddOutline = 'video-portrait-add-outline',
    videoPortraitAddSolid = 'video-portrait-add-solid',
    videoPortraitOutline = 'video-portrait-outline',
    videoPortraitSolid = 'video-portrait-solid',
    vipOutline = 'vip-outline',
    vipSolid = 'vip-solid',
    volumeHighOutline = 'volume-high-outline',
    volumeHighSolid = 'volume-high-solid',
    volumeLowOutline = 'volume-low-outline',
    volumeLowSolid = 'volume-low-solid',
    volumeMuteOutline = 'volume-mute-outline',
    volumeMuteSolid = 'volume-mute-solid',
    walletOutline = 'wallet-outline',
    walletSolid = 'wallet-solid',
    warningCircularOutline = 'warning-circular-outline',
    warningCircularSolid = 'warning-circular-solid',
    warningOutline = 'warning-outline',
    warningSolid = 'warning-solid',
    webcamOutline = 'webcam-outline',
    webcamSolid = 'webcam-solid',
    websiteEarningsOutline = 'website-earnings-outline',
    websiteEarningsSolid = 'website-earnings-solid',
    whatsapp = 'whatsapp',
    whiteBalanceOutline = 'white-balance-outline',
    whiteBalanceSolid = 'white-balance-solid',
    widgetAddOutline = 'widget-add-outline',
    widgetAddSolid = 'widget-add-solid',
    widgetOutline = 'widget-outline',
    widgetSolid = 'widget-solid',
    wrenchOutline = 'wrench-outline',
    wrenchSolid = 'wrench-solid',
    x = 'x',
    youtube = 'youtube',
    zoomInOutline = 'zoom-in-outline',
    zoomInSolid = 'zoom-in-solid',
    zoomOutOutline = 'zoom-out-outline',
    zoomOutSolid = 'zoom-out-solid',
    zoomOutline = 'zoom-outline',
    zoomSolid = 'zoom-solid',
}

// eslint-disable-next-line no-restricted-imports
import { Environment } from 'configs/globalConfig';
import { LevelRaw } from 'domains/LevelDependent/LevelDependent.types';

export interface GlobalConfig {
    csrfToken: string;
    cdnList: string[];
    cdn: string;
    applicationEnvironment: Environment;
    apiHost: string;
    protocol?: string;
    apiRedirectUri: string;
    apiClientId: string;
    apiClientSecret: string;
    activePerformerId?: number;
    activeStudioId?: number;
    isLoggedIn: boolean;
    viewType?: ViewType;
    accountViewType?: AccountViewType | null;
    account: Account;
    language: string;
    statisticsDashboard?: StatisticsDashboardConfig;
    performerCategoryId: string;
    isSingleAccount: boolean;
    performer: PerformerTmp;
    studio: StudioTmp;
    hasStudioAccount: boolean;
    isManagedPerformer: boolean;
    isNewcomer: boolean;
    userId: number;
    userType?: UserType;
    levelDependent: LevelDependent;
    payoutFAQConfig?: PayoutFAQConfig;
    registrationLink?: string;
    periods: Array<Periods>;
    referralConsentStatusId: ConsentStatusEnum;
    isActionRequestEnabled: boolean;
    ljTopModels?: Array<TopModel>;
    ljTopEarning?: Array<TopEarning>;
    isLimitedAccess: boolean;
    isGiftSendingEnabled?: boolean;
    isRoseSendingEnabled?: boolean;
    enabledFeatureFlags?: string;
    isTinNeeded?: string;
    gtm?: string;
}

interface TopModel {
    performerNick: string;
    accountId: number;
}
interface TopEarning {
    /* eslint-disable camelcase */
    sum_amount: number;
    account_id: number;
    /* eslint-enable camelcase */
}

export interface Periods {
    fromDate: string;
    toDate: string;
}

interface LevelDependent {
    levels: LevelRaw;
    earnings: number;
}

interface PayoutFAQConfig {
    isPayoneerEnabled: boolean;
    isPaypalEnabled: boolean;
    isSkrillEnabled: boolean;
    isWebmoneyEnabled: boolean;
    isEpayserviceEnabled: boolean;
    isPaxumEnabled: boolean;
    isChexxEnabled: boolean;
    isCheckEnabled: boolean;
    isCosmoEnabled: boolean;
    isTripleaEnabled: boolean;
    isPagomundoEnabled: boolean;
    isPandablueEnabled: boolean;
    transferFeePercent: boolean;
    usFee: number;
    coFeePercentage: number;
    coMaxFee: number;
    payInfoEmail: string;
}

export interface StatisticsDashboardConfigToken {
    token: string;
    expireAt: string;
}

interface StatisticsDashboardConfig {
    biApi: {
        baseUri: string;
        token: StatisticsDashboardConfigToken;
    };
    displayActionRequestsInStatistics: boolean;
}

interface Category {
    id: number;
    name: string;
    url: string;
}

interface PerformerTmp {
    name: string;
    status: PerformerStatus;
    category: Category;
    hasExclusiveBadge: boolean;
    hasReachedEarning: boolean;
    isExclusivePartner: boolean;
    person?: PerformerPerson;
}

export interface StudioTmp {
    accountType: string;
    certificationType: string;
    name: string;
}

export interface DashboardPortalApi {
    isMigrationFinished: boolean;
    isDisplayNameChangePending: boolean;
    isDisplayNameChangeForced: boolean;
    newDisplayName: string;
    isLandscapeEroticTeaserWidgetVisible: boolean;
    isPortraitEroticTeaserWidgetVisible: boolean;
    isDisplayNameFeatureFlagEnabled: boolean;
    isExclusiveBadgeRejectedPermanently: boolean;
    isSessionLimitedAccess: boolean;
    selectionTaskList: string;
    tips: string;
    performer: {
        hasReachedEarning: boolean;
        isExclusivePartner: boolean;
    };
    statistics?: {
        currentPeriod: {
            from: string;
            to: string;
        };
    };
    featureAvailability: {
        soldierAid: boolean;
        awards: boolean;
    };
}

interface LevelDependentPaymentPortalApi {
    levelDependentIncome: number;
    stripes: {
        [key: string]: {
            limit: number;
            shareFree: number;
            shareMember: number;
            name: string;
            step: number;
        };
    };
}

interface ProfilePicturePortalApi {
    categoryName: string;
    categoryWikiLink: string;
}

export interface PortalApi {
    homeDashboard?: DashboardPortalApi;
    levelDependentPayment?: LevelDependentPaymentPortalApi;
    profilePicture?: ProfilePicturePortalApi;
}

interface PerformerPerson {
    gender: 'Female' | 'Male' | null;
    isDressSizeSet?: number;
}

interface Account {
    isLoading?: boolean;
    performer?: {
        pic?: string;
        categoryName?: string;
        hasExclusiveBadge: boolean;
        name: string;
        status: PerformerStatus;
        hasPicture?: boolean;
    };
    studio?: {
        id: number;
        name: string;
        ownerUserId: number;
        isCertified: boolean;
        certificationLevel: StudioBadge | null;
    };
}

export type UserType = 'performer' | 'studio' | 'guest';

enum PerformerStatus {
    ACTIVE = 'active',
    CLOSED = 'closed',
    ONLINE = 'online',
    REJECTED = 'rejected',
    SUSPENDED = 'suspended',
    UNFINISHED = 'unfinished',
    PENDING = 'pending',
}

export enum ViewType {
    STUDIO = 'studio',
    PERFORMER = 'performer',
    SINGLE = 'single_performer',
    GUEST = 'guest',
    REGISTRATION = 'registration',
}

export enum AccountViewType {
    STUDIO = 'studio',
    MODEL = 'model',
    SINGLE = 'single',
}

enum StudioBadge {
    NONE = '',
    GOLD = 'gold',
    SILVER = 'silver',
    BRONZE = 'bronze',
}

enum ConsentStatusEnum {
    PENDING = '0',
    ACCEPTED = '1',
    REVOKED = '2',
    EXPIRED = '3',
}

import { setLanguage as setDHLanguage, setTable as setDhLanguageTable } from '@dh-fe/translate';
import { globalConfig } from 'configs/globalConfig';
import '@dh-fe/translate/enable-bb';
import '@dh-fe/translate/enable-jsx';

const { language } = globalConfig;

setDhLanguageTable({
	[language]: window.translations[language] || {}
});
setDHLanguage(language);
class PublicPath {
	static get Url() {
		if (PublicPath.IsDev && PublicPath.IsDevServer) {
			return 'https://localhost.dhdevel.com:3000/';
		}

		return window.__wpp__; // eslint-disable-line
	}

	static get IsDev() {
		return !!(location.origin.indexOf('dhdevel.com') + 1);
	}

	static get IsDevServer() {
		return !!module.hot;
	}
}

__webpack_public_path__ = PublicPath.Url;  // eslint-disable-line

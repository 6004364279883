import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PerformerTwoWayAudio } from 'domains/Performer/Performer.types';
import { PerformerTwoWayAudioState } from './PerformerTwoWayAudio.types';

const initialState: PerformerTwoWayAudioState = {
    data: undefined,
    status: 'initial',
};

const performerTwoWayAudioSlice = createSlice({
    name: 'performerTwoWayAudio',
    initialState,
    reducers: {
        setPerformerTwoWayAudio: (state, action: PayloadAction<PerformerTwoWayAudio>) => {
            state.data = action.payload;
            state.status = 'resolved';
        },
        updatePerformerTwoWayAudioEnabled: (state, action: PayloadAction<boolean>) => {
            if (state.data) {
                state.data.isEnabled = action.payload;
            }
        },
        updatePerformerTwoWayAudioLanguages: (state, action: PayloadAction<string[]>) => {
            if (state.data) {
                state.data.spokenLanguages = action.payload;
            }
        },
    },
});

export const { setPerformerTwoWayAudio, updatePerformerTwoWayAudioEnabled, updatePerformerTwoWayAudioLanguages } =
    performerTwoWayAudioSlice.actions;
export default performerTwoWayAudioSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
    AwardsStatisticsPeriodsState,
    AwardsStatisticsPeriodSucceededPayload,
    AwardsStatisticsPeriodFailPayload,
} from './AwardsStatisticsPeriods.types';

const initialState: AwardsStatisticsPeriodsState = {
    periods: undefined,
    isLoading: false,
    errorMessage: '',
};

const awardStatisticsPeriodsSlice = createSlice({
    name: 'awardStatisticsPeriods',
    initialState,
    reducers: {
        fetchAwardsStatisticsPeriodsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = '';
        },
        fetchAwardsStatisticsPeriodsSucceeded: (
            state,
            action: PayloadAction<AwardsStatisticsPeriodSucceededPayload>,
        ) => {
            const { periods } = action.payload;
            state.periods = periods;
            state.isLoading = false;
        },
        fetchAwardsStatisticsPeriodsFailed: (state, action: PayloadAction<AwardsStatisticsPeriodFailPayload>) => {
            const { message } = action.payload;
            state.periods = [];
            state.errorMessage = message;
            state.isLoading = false;
        },
    },
});

export const {
    fetchAwardsStatisticsPeriodsStarted,
    fetchAwardsStatisticsPeriodsSucceeded,
    fetchAwardsStatisticsPeriodsFailed,
} = awardStatisticsPeriodsSlice.actions;
export default awardStatisticsPeriodsSlice.reducer;

/* tslint:disable */
/* eslint-disable */
/**
 * MSC API Gateway
 * The API is reachable from public networks. Client can be authenticated either by an OAuth2 Access Token (for web and mobile) or an API key
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../base';
// @ts-ignore
import { ApiSuccess } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { BadRequestErrorResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { BulkCreateConsentConfigurationCookieRequest } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { CookieConsentConfigurationsResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { CookieConsentResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { CreateCookieConsentRequest } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { ForbiddenErrorResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { InternalServerErrorResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
// @ts-ignore
import { NotFoundErrorResponse } from '../../../../msc-api-gateway/proxy/cookieconsent/models';
/**
 * CookieConsentsApi - axios parameter creator
 * @export
 */
export const CookieConsentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new cookie consent
         * @param {CreateCookieConsentRequest} [createCookieConsentRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCookieConsent: async (
            createCookieConsentRequest?: CreateCookieConsentRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public-gw/consent/v1/cookie-consent/consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createCookieConsentRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add unknown cookies to the configuration.
         * @param {string} consentConfigurationId The consent configuration ID
         * @param {BulkCreateConsentConfigurationCookieRequest} [bulkCreateConsentConfigurationCookieRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCookieConsentConfigurationCookies: async (
            consentConfigurationId: string,
            bulkCreateConsentConfigurationCookieRequest?: BulkCreateConsentConfigurationCookieRequest,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'consentConfigurationId' is not null or undefined
            assertParamExists(
                'createCookieConsentConfigurationCookies',
                'consentConfigurationId',
                consentConfigurationId,
            );
            const localVarPath =
                `/public-gw/consent/v1/cookie-consent/configurations/{consentConfigurationId}/cookies`.replace(
                    `{${'consentConfigurationId'}}`,
                    encodeURIComponent(String(consentConfigurationId)),
                );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                bulkCreateConsentConfigurationCookieRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Find cookie consent configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCookieConsentConfigurations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/public-gw/consent/v1/cookie-consent/configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CookieConsentsApi - functional programming interface
 * @export
 */
export const CookieConsentsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CookieConsentsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a new cookie consent
         * @param {CreateCookieConsentRequest} [createCookieConsentRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCookieConsent(
            createCookieConsentRequest?: CreateCookieConsentRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccess & CookieConsentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCookieConsent(
                createCookieConsentRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add unknown cookies to the configuration.
         * @param {string} consentConfigurationId The consent configuration ID
         * @param {BulkCreateConsentConfigurationCookieRequest} [bulkCreateConsentConfigurationCookieRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCookieConsentConfigurationCookies(
            consentConfigurationId: string,
            bulkCreateConsentConfigurationCookieRequest?: BulkCreateConsentConfigurationCookieRequest,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCookieConsentConfigurationCookies(
                consentConfigurationId,
                bulkCreateConsentConfigurationCookieRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Find cookie consent configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCookieConsentConfigurations(
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSuccess & CookieConsentConfigurationsResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCookieConsentConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * CookieConsentsApi - factory interface
 * @export
 */
export const CookieConsentsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = CookieConsentsApiFp(configuration);
    return {
        /**
         * Create a new cookie consent
         * @param {CreateCookieConsentRequest} [createCookieConsentRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCookieConsent(
            createCookieConsentRequest?: CreateCookieConsentRequest,
            options?: any,
        ): AxiosPromise<ApiSuccess & CookieConsentResponse> {
            return localVarFp
                .createCookieConsent(createCookieConsentRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Add unknown cookies to the configuration.
         * @param {string} consentConfigurationId The consent configuration ID
         * @param {BulkCreateConsentConfigurationCookieRequest} [bulkCreateConsentConfigurationCookieRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCookieConsentConfigurationCookies(
            consentConfigurationId: string,
            bulkCreateConsentConfigurationCookieRequest?: BulkCreateConsentConfigurationCookieRequest,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .createCookieConsentConfigurationCookies(
                    consentConfigurationId,
                    bulkCreateConsentConfigurationCookieRequest,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * Find cookie consent configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCookieConsentConfigurations(options?: any): AxiosPromise<ApiSuccess & CookieConsentConfigurationsResponse> {
            return localVarFp.findCookieConsentConfigurations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CookieConsentsApi - interface
 * @export
 * @interface CookieConsentsApi
 */
export interface CookieConsentsApiInterface {
    /**
     * Create a new cookie consent
     * @param {CreateCookieConsentRequest} [createCookieConsentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApiInterface
     */
    createCookieConsent(
        createCookieConsentRequest?: CreateCookieConsentRequest,
        options?: any,
    ): AxiosPromise<ApiSuccess & CookieConsentResponse>;

    /**
     * Add unknown cookies to the configuration.
     * @param {string} consentConfigurationId The consent configuration ID
     * @param {BulkCreateConsentConfigurationCookieRequest} [bulkCreateConsentConfigurationCookieRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApiInterface
     */
    createCookieConsentConfigurationCookies(
        consentConfigurationId: string,
        bulkCreateConsentConfigurationCookieRequest?: BulkCreateConsentConfigurationCookieRequest,
        options?: any,
    ): AxiosPromise<void>;

    /**
     * Find cookie consent configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApiInterface
     */
    findCookieConsentConfigurations(options?: any): AxiosPromise<ApiSuccess & CookieConsentConfigurationsResponse>;
}

/**
 * CookieConsentsApi - object-oriented interface
 * @export
 * @class CookieConsentsApi
 * @extends {BaseAPI}
 */
export class CookieConsentsApi extends BaseAPI implements CookieConsentsApiInterface {
    /**
     * Create a new cookie consent
     * @param {CreateCookieConsentRequest} [createCookieConsentRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApi
     */
    public createCookieConsent(createCookieConsentRequest?: CreateCookieConsentRequest, options?: any) {
        return CookieConsentsApiFp(this.configuration)
            .createCookieConsent(createCookieConsentRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add unknown cookies to the configuration.
     * @param {string} consentConfigurationId The consent configuration ID
     * @param {BulkCreateConsentConfigurationCookieRequest} [bulkCreateConsentConfigurationCookieRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApi
     */
    public createCookieConsentConfigurationCookies(
        consentConfigurationId: string,
        bulkCreateConsentConfigurationCookieRequest?: BulkCreateConsentConfigurationCookieRequest,
        options?: any,
    ) {
        return CookieConsentsApiFp(this.configuration)
            .createCookieConsentConfigurationCookies(
                consentConfigurationId,
                bulkCreateConsentConfigurationCookieRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find cookie consent configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CookieConsentsApi
     */
    public findCookieConsentConfigurations(options?: any) {
        return CookieConsentsApiFp(this.configuration)
            .findCookieConsentConfigurations(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import {
    UpdatePerformerPropertiesRequest,
    UpdatePerformerPropertiesRequestReferralConsentStatusEnum,
} from 'apiClients/msc-api-gateway/accountservices/models';
import { globalConfig } from 'configs/globalConfig';
import { MeEntity, PerformerEntity, PerformerPeriod } from 'domains/Performer/Performer.types';
import { Progress, PerformerState, PerformerLoadingTypes, ProgressStatus } from './Performer.types';

export const initialState: PerformerState = {
    me: undefined,
    myPerformer: undefined,
    language: globalConfig.language,
    isLoading: true,
    progress: [],
    errorMessage: undefined,
    periods: [],
    properties: {
        referralConsentStatus: UpdatePerformerPropertiesRequestReferralConsentStatusEnum._0,
    },
};

const performerSlice = createSlice({
    name: 'performer',
    initialState,
    reducers: {
        fetchMeSucceeded: (state, action: PayloadAction<MeEntity>) => {
            state.me = action.payload;
        },
        fetchMeFailed: (state, action: PayloadAction<string>) => {
            state.me = undefined;
            state.errorMessage = action.payload;
        },
        fetchMyPerformerStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchMyPerformerSucceeded: (state, action: PayloadAction<PerformerEntity>) => {
            state.myPerformer = action.payload;
            state.isLoading = false;
        },
        fetchMyPerformerFailed: (state, action: PayloadAction<string>) => {
            state.myPerformer = undefined;
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        fetchMyPerformerPeriodsSucceeded: (state, action: PayloadAction<Array<PerformerPeriod>>) => {
            state.periods = action.payload;
            state.isLoading = false;
        },
        updatePerformerPropertiesStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        updatePerformerPropertiesSucceeded: (state, action: PayloadAction<UpdatePerformerPropertiesRequest>) => {
            state.isLoading = false;
            state.properties = { ...state.properties, ...action.payload };
        },
        updatePerformerPropertiesFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        setSaveIndicatorStarted: (state, action: PayloadAction<PerformerLoadingTypes>) => {
            addOrUpdateProgress(state, action.payload, ProgressStatus.Loading);
        },
        setSaveIndicatorSucceeded: (state, action: PayloadAction<PerformerLoadingTypes>) => {
            state.progress = state.progress.map((progress) => {
                if (progress.type === action.payload) {
                    progress.status = ProgressStatus.Succeeded;
                }
                return progress;
            });
        },
        setSaveIndicatorFailed: (state, action: PayloadAction<{ type: PerformerLoadingTypes; message: string }>) => {
            const { type, message } = action.payload;
            state.progress = state.progress.map((progress) => {
                if (progress.type === type) {
                    progress.status = ProgressStatus.Failed;
                    progress.errorMessage = message;
                }
                return progress;
            });
        },
        clearAllSaveIndicators: (state) => {
            state.progress = [];
        },
    },
});

const addOrUpdateProgress = (state: Draft<PerformerState>, type: PerformerLoadingTypes, status: ProgressStatus) => {
    if (state.progress.find((progress: Draft<Progress>) => progress.type === type)) {
        state.progress = state.progress.map((progress: Draft<Progress>) => {
            if (progress.type === type) {
                progress.status = status;
            }
            return progress;
        });
    } else {
        state.progress = [
            ...state.progress,
            {
                type,
                status,
            },
        ];
    }
};

export const {
    fetchMeSucceeded,
    fetchMeFailed,
    fetchMyPerformerStarted,
    fetchMyPerformerSucceeded,
    fetchMyPerformerFailed,
    fetchMyPerformerPeriodsSucceeded,
    updatePerformerPropertiesStarted,
    updatePerformerPropertiesSucceeded,
    updatePerformerPropertiesFailed,
    setSaveIndicatorStarted,
    setSaveIndicatorSucceeded,
    setSaveIndicatorFailed,
    clearAllSaveIndicators,
} = performerSlice.actions;
export default performerSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ToastIconTypes = 'success' | 'warning' | 'error' | 'birthday';
export interface ToastState {
    toasts: {
        [id: string]: ToastMeta;
    };
}

export interface ToastMeta {
    message: string | string[];
    icon?: ToastIconTypes;
    timeout?: number | boolean;
    priority?: 'low' | 'medium' | 'high' | 'critical';
    isCloseable?: boolean;
    // A customId will prevent the toast from being shown multiple times while it's in the queue
    customId?: string;
}

export const initialState: ToastState = {
    toasts: {},
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<{ id: string; toast: ToastMeta }>) => {
            const { id, toast } = action.payload;
            state.toasts[id] = toast;
        },
        deleteToast: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            delete state.toasts[id];
        },
    },
});

export const { addToast, deleteToast } = toastSlice.actions;
export default toastSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Short, ShortPrivacyType, ShortStatus, Song } from 'domains/Shorts/Shorts.types';
import { Challenge, ShortFetchStrategy, ShortsList, ShortsState, UpdateShortBodyPayload } from './Shorts.types';

const initialShortsByStatus: Record<ShortStatus, ShortsList> = {
    active: {
        shorts: [],
        requestStatus: 'initial',
        total: null,
    },
    inactive: {
        shorts: [],
        requestStatus: 'initial',
        total: null,
    },
    rejected: {
        shorts: [],
        requestStatus: 'initial',
        total: null,
    },
};

export const initialState: ShortsState = {
    fetchChallenges: {
        requestStatus: 'initial',
        challenges: [],
    },
    fetchShorts: {
        free: initialShortsByStatus,
        premium: initialShortsByStatus,
    },
    createShort: {
        requestStatus: 'initial',
    },
    editShort: {
        requestStatus: 'initial',
    },
    deleteShort: {
        requestStatus: 'initial',
    },
    musicPlayer: {
        isPaused: false,
    },
};

const shortsSlice = createSlice({
    name: 'shorts',
    initialState,
    reducers: {
        fetchChallengesStarted: (state) => {
            state.fetchChallenges.requestStatus = 'pending';
        },
        fetchChallengesSucceeded: (state, action: PayloadAction<Challenge[]>) => {
            state.fetchChallenges.challenges = action.payload;
            state.fetchChallenges.requestStatus = 'resolved';
        },
        fetchChallengesFailed: (state) => {
            state.fetchChallenges.challenges = [];
            state.fetchChallenges.requestStatus = 'rejected';
        },
        fetchShortsStarted: (
            state,
            action: PayloadAction<{ shortType: ShortPrivacyType; shortStatus: ShortStatus }>,
        ) => {
            const { shortType, shortStatus } = action.payload;
            state.fetchShorts[shortType][shortStatus].requestStatus = 'pending';
        },
        fetchShortsSucceeded: (
            state,
            action: PayloadAction<{
                shortType: ShortPrivacyType;
                shortStatus: ShortStatus;
                shorts: Short[];
                total: number;
                strategy: ShortFetchStrategy;
            }>,
        ) => {
            const { shortType, shortStatus, shorts, total, strategy } = action.payload;
            state.fetchShorts[shortType][shortStatus].shorts =
                strategy === 'append' ? [...state.fetchShorts[shortType][shortStatus].shorts, ...shorts] : shorts;
            state.fetchShorts[shortType][shortStatus].total = total;
            state.fetchShorts[shortType][shortStatus].requestStatus = 'resolved';
        },
        fetchShortsFailed: (
            state,
            action: PayloadAction<{ shortType: ShortPrivacyType; shortStatus: ShortStatus }>,
        ) => {
            const { shortType, shortStatus } = action.payload;
            state.fetchShorts[shortType][shortStatus].shorts = [];
            state.fetchShorts[shortType][shortStatus].requestStatus = 'rejected';
        },
        createShortStarted: (state) => {
            state.createShort.short = undefined;
            state.createShort.uploadProgress = undefined;
            state.createShort.requestStatus = 'pending';
        },
        createShortUploadStarting: (state, action: PayloadAction<Short>) => {
            state.createShort.short = action.payload;
            state.createShort.uploadProgress = 0;
        },
        createShortUploadProgress: (state, action: PayloadAction<number>) => {
            state.createShort.uploadProgress = action.payload;
        },
        createShortValidationFailed: (state, statusReason: PayloadAction<Pick<Short, 'statusReason'>>) => {
            if (state.createShort.short) {
                state.createShort.short = {
                    ...state.createShort.short,
                    status: 'validation_failed',
                    statusReason: statusReason.payload.statusReason,
                };
            }
        },
        createShortSucceeded: (state) => {
            state.createShort.uploadProgress = 100;
            state.createShort.requestStatus = 'resolved';
        },
        addUploadedShortToList: (state, action: PayloadAction<ShortStatus>) => {
            const { short } = state.createShort;
            if (short) {
                state.fetchShorts[short.privacy][action.payload].shorts = [
                    short,
                    ...state.fetchShorts[short.privacy][action.payload].shorts,
                ];
            }
        },
        createShortFailed: (state) => {
            state.createShort.uploadProgress = undefined;
            state.createShort.requestStatus = 'rejected';
        },
        createShortReset: (state) => {
            state.createShort.short = undefined;
            state.createShort.uploadProgress = undefined;
            state.createShort.requestStatus = 'initial';
        },
        updateShortStarted: (state) => {
            state.editShort.requestStatus = 'pending';
        },
        updateShortSucceeded: (
            state,
            action: PayloadAction<{
                type: ShortPrivacyType;
                status: ShortStatus;
                id: number;
                body: UpdateShortBodyPayload;
            }>,
        ) => {
            const {
                type,
                status,
                id,
                body: { title },
            } = action.payload;
            const shortsItem = state.fetchShorts[type][status];
            const short = shortsItem.shorts.find((short) => short.id === id);

            if (short) short.title = title;

            state.editShort.requestStatus = 'resolved';
        },
        updateShortFailed: (state) => {
            state.editShort.requestStatus = 'rejected';
        },
        updateShortReset: (state) => {
            state.editShort.requestStatus = 'initial';
            state.editShort.content = undefined;
        },
        deleteShortStarted: (state) => {
            state.deleteShort.requestStatus = 'pending';
        },
        deleteShortSucceeded: (
            state,
            action: PayloadAction<{ type: ShortPrivacyType; status: ShortStatus; id: number }>,
        ) => {
            const { type, status, id } = action.payload;
            state.fetchShorts[type][status].shorts = state.fetchShorts[type][status].shorts.filter(
                (short) => short.id !== id,
            );
            state.deleteShort.requestStatus = 'resolved';
        },
        deleteShortFailed: (state) => {
            state.deleteShort.requestStatus = 'rejected';
        },
        deleteShortReset: (state) => {
            state.deleteShort.requestStatus = 'initial';
            state.deleteShort.content = undefined;
        },
        editShort: (state, action: PayloadAction<{ id: number; title: string; status: ShortStatus } | undefined>) => {
            state.editShort.content = action.payload;
        },
        deletingShort: (state, action: PayloadAction<{ id: number; status: ShortStatus } | undefined>) => {
            state.deleteShort.content = action.payload;
        },
        replaceShort: (
            state,
            action: PayloadAction<{
                shortId: number;
                shortType: ShortPrivacyType;
                shortStatus: ShortStatus;
                updates: Partial<Short>;
            }>,
        ) => {
            const { shortId, shortType, shortStatus, updates } = action.payload;
            state.fetchShorts[shortType][shortStatus].shorts = state.fetchShorts[shortType][shortStatus].shorts.map(
                (short) => {
                    if (short.id !== shortId) return short;

                    return {
                        ...short,
                        ...updates,
                    };
                },
            );
        },
        removeShort: (
            state,
            action: PayloadAction<{
                shortId: number;
                shortType: ShortPrivacyType;
                shortStatus: ShortStatus;
            }>,
        ) => {
            const { shortId, shortType, shortStatus } = action.payload;
            state.fetchShorts[shortType][shortStatus].shorts = state.fetchShorts[shortType][shortStatus].shorts.filter(
                (short) => short.id !== shortId,
            );
        },
        openMusicPlayer: (state, action: PayloadAction<Song>) => {
            state.musicPlayer.song = action.payload;
            state.musicPlayer.isPaused = false;
        },
        setMusicPlayerPaused: (state, action: PayloadAction<boolean>) => {
            state.musicPlayer.isPaused = action.payload;
        },
        closeMusicPlayer: (state) => {
            state.musicPlayer.song = undefined;
        },
    },
});

export const {
    fetchChallengesStarted,
    fetchChallengesSucceeded,
    fetchChallengesFailed,
    fetchShortsStarted,
    fetchShortsSucceeded,
    fetchShortsFailed,
    createShortStarted,
    createShortUploadStarting,
    createShortUploadProgress,
    createShortValidationFailed,
    createShortSucceeded,
    addUploadedShortToList,
    createShortFailed,
    createShortReset,
    updateShortStarted,
    updateShortSucceeded,
    updateShortFailed,
    updateShortReset,
    deleteShortStarted,
    deleteShortSucceeded,
    deleteShortFailed,
    deleteShortReset,
    editShort,
    deletingShort,
    replaceShort,
    removeShort,
    openMusicPlayer,
    setMusicPlayerPaused,
    closeMusicPlayer,
} = shortsSlice.actions;

export default shortsSlice.reducer;

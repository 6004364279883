import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Consent, ConsentListResponse, GeneratedConsentResponse } from 'domains/Consent/Consent.types';
import { ConsentState } from './Consent.types';

export const initialState: ConsentState = {
    fetchConsentList: {
        requestStatus: 'initial',
    },
    createConsent: {
        requestStatus: 'initial',
    },
    fetchGeneratedConsent: {
        requestStatus: 'initial',
    },
    updateConsentSignature: {
        requestStatus: 'initial',
    },
};

const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        fetchConsentListStarted: (state) => {
            state.fetchConsentList.data = undefined;
            state.fetchConsentList.requestStatus = 'pending';
        },
        fetchConsentListSucceeded: (state, action: PayloadAction<ConsentListResponse>) => {
            state.fetchConsentList.data = action.payload;
            state.fetchConsentList.requestStatus = 'resolved';
        },
        fetchConsentListFailed: (state) => {
            state.fetchConsentList.data = undefined;
            state.fetchConsentList.requestStatus = 'rejected';
        },
        createConsentStarted: (state) => {
            state.createConsent.data = undefined;
            state.createConsent.requestStatus = 'pending';
        },
        createConsentSucceeded: (state, action: PayloadAction<GeneratedConsentResponse>) => {
            state.createConsent.data = action.payload;
            state.createConsent.requestStatus = 'resolved';
        },
        createConsentFailed: (state) => {
            state.createConsent.data = undefined;
            state.createConsent.requestStatus = 'rejected';
        },
        fetchGeneratedConsentStarted: (state) => {
            state.fetchGeneratedConsent.data = undefined;
            state.fetchGeneratedConsent.requestStatus = 'pending';
        },
        fetchGeneratedConsentSucceeded: (state, action: PayloadAction<GeneratedConsentResponse>) => {
            state.fetchGeneratedConsent.data = action.payload;
            state.fetchGeneratedConsent.requestStatus = 'resolved';
        },
        fetchGeneratedConsentFailed: (state) => {
            state.fetchGeneratedConsent.data = undefined;
            state.fetchGeneratedConsent.requestStatus = 'rejected';
        },
        updateConsentSignatureStarted: (state) => {
            state.updateConsentSignature.data = undefined;
            state.updateConsentSignature.requestStatus = 'pending';
        },
        updateConsentSignatureSucceeded: (state, action: PayloadAction<Consent>) => {
            state.updateConsentSignature.data = action.payload;
            state.updateConsentSignature.requestStatus = 'resolved';
        },
        updateConsentSignatureFailed: (state) => {
            state.updateConsentSignature.data = undefined;
            state.updateConsentSignature.requestStatus = 'rejected';
        },
    },
});

export const {
    fetchConsentListStarted,
    fetchConsentListSucceeded,
    fetchConsentListFailed,
    createConsentStarted,
    createConsentSucceeded,
    createConsentFailed,
    fetchGeneratedConsentStarted,
    fetchGeneratedConsentSucceeded,
    fetchGeneratedConsentFailed,
    updateConsentSignatureStarted,
    updateConsentSignatureSucceeded,
    updateConsentSignatureFailed,
} = consentSlice.actions;

export default consentSlice.reducer;

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeContextProvider } from 'contexts/ThemeContext';
import store from 'store/store';
import Toasts from 'components/Toasts';
import getLanding from 'pages/LandingPage';
import CookieConsent from 'pages/Shared/CookieConsent';

const CurrentLanding = getLanding();

const LandingApp = () => (
    <Suspense fallback={<></>}>
        <CurrentLanding />
    </Suspense>
);
const root = createRoot(document.getElementById('root'));
root.render(
    <ThemeContextProvider>
        <Provider store={store}>
            <Toasts />
            <CookieConsent />
        </Provider>
        <LandingApp />
    </ThemeContextProvider>,
);

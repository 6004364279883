import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaItemResponse } from 'domains/Messenger/messengerGallery/MessengerGalleryTypes';
import { MessengerGalleryState } from './MessengerGallery.types';

export const initialState: MessengerGalleryState = {
    errorMessage: undefined,
    gallery: [],
    isLastPage: undefined,
    isGalleryOpen: false,
    requestStatus: 'pending',
    selectedMediaIndex: undefined,
};

interface SuccessResponse {
    media: MediaItemResponse[];
    lastId?: number;
    isLastPage: boolean;
    isNextPageRequest?: boolean;
}

const messengerGallerySlice = createSlice({
    name: 'messengerGallery',
    initialState,
    reducers: {
        fetchMessengerGalleryStarted: (state, action: PayloadAction<{ isNextPageRequest?: boolean }>) => {
            state.requestStatus = action.payload.isNextPageRequest ? 'nextPagePending' : 'pending';
            state.errorMessage = undefined;
            state.isLastPage = undefined;
        },
        fetchMessengerGallerySucceeded: (
            state,
            { payload: { media, isLastPage, isNextPageRequest } }: PayloadAction<SuccessResponse>,
        ) => {
            state.requestStatus = 'resolved';
            state.errorMessage = undefined;
            state.isLastPage = isLastPage;
            state.gallery = isNextPageRequest ? state.gallery.concat(media) : media;
        },
        fetchMessengerGalleryFailed: (state, { payload }: PayloadAction<string>) => {
            state.requestStatus = 'rejected';
            state.errorMessage = payload;
            state.isLastPage = undefined;
        },
        setGalleryOpen: (state, { payload: isOpen }: PayloadAction<boolean>) => {
            state.isGalleryOpen = isOpen;
            if (!isOpen) state.gallery = initialState.gallery;
        },
        clearGalleryState: (state) => {
            state.gallery = initialState.gallery;
        },
        removeItemFromGallery: (state, action: PayloadAction<{ messageId: number }>) => {
            state.gallery = state.gallery.filter((item) => item.id !== action.payload.messageId);
        },
        setMessengerGallerySelectedMediaIndex: (state, action: PayloadAction<number | undefined>) => {
            state.selectedMediaIndex = action.payload;
        },
    },
});

export const {
    fetchMessengerGalleryStarted,
    fetchMessengerGalleryFailed,
    fetchMessengerGallerySucceeded,
    setGalleryOpen,
    clearGalleryState,
    removeItemFromGallery,
    setMessengerGallerySelectedMediaIndex,
} = messengerGallerySlice.actions;
export default messengerGallerySlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { AWARDS_GAME_TYPE_ORDER } from 'pages/Awards/Awards.config';
import { getProduct } from 'configs/sites';
import {
    AwardsState,
    AwardsGamesPayload,
    AwardsStandingsPayload,
    AwardsCompetitorsPayload,
    AwardsExcludedPayload,
} from './Awards.types';

export const initialState: AwardsState = {
    games: undefined,
    standings: undefined,
    competitors: undefined,
    excluded: undefined,
    isLoading: false,
    errorMessage: undefined,
    isCompetitorsLoading: false,
    cache: {
        competitorsArray: [],
        competitorArray: [],
    },
};

const awardsGameTypeOrder = AWARDS_GAME_TYPE_ORDER[getProduct()];

const awardsSlice = createSlice({
    name: 'awards',
    initialState,
    reducers: {
        fetchAwardsGamesStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchAwardsGamesSucceeded: (state, action: PayloadAction<AwardsGamesPayload>) => {
            const gamesArray = action.payload.data;
            gamesArray.sort((a, b) => {
                return awardsGameTypeOrder.indexOf(a.type) - awardsGameTypeOrder.indexOf(b.type);
            });
            state.games = { data: gamesArray };
            state.isLoading = false;
        },
        fetchAwardsGamesFailed: (state, action: PayloadAction<string>) => {
            state.games = undefined;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },

        fetchAwardsStandingsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchAwardsStandingsSucceeded: (state, action: PayloadAction<AwardsStandingsPayload>) => {
            state.standings = action.payload;
            state.isLoading = false;
        },
        fetchAwardsStandingsFailed: (state, action: PayloadAction<string>) => {
            state.standings = undefined;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },

        fetchAwardsCompetitorsStarted: (state) => {
            state.errorMessage = undefined;
            state.isCompetitorsLoading = true;
        },
        fetchAwardsCompetitorsSucceeded: (state, action: PayloadAction<AwardsCompetitorsPayload>) => {
            let { competitorsArray } = state.cache;
            let { competitorArray } = state.cache;
            if (!competitorArray.find((competitor) => competitor.gameType === action.payload.gameType)) {
                competitorsArray = competitorArray.concat(action.payload);
                competitorArray = competitorArray.concat(action.payload);
                competitorsArray.sort((a, b) => {
                    return awardsGameTypeOrder.indexOf(a.gameType) - awardsGameTypeOrder.indexOf(b.gameType);
                });
            }
            state.cache.competitorsArray = competitorsArray;
            state.cache.competitorArray = competitorArray;

            state.competitors = state.cache.competitorsArray;
            state.isCompetitorsLoading = false;
        },
        fetchAwardsCompetitorsFailed: (state, action: PayloadAction<string>) => {
            state.competitors = undefined;
            state.errorMessage = action.payload;
            state.isCompetitorsLoading = false;
        },

        fetchExcludedGamesStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchExcludedGamesSucceeded: (state, action: PayloadAction<AwardsExcludedPayload>) => {
            state.excluded = action.payload;
            state.isLoading = false;
        },
        fetchExcludedGamesFailed: (state, action: PayloadAction<string>) => {
            state.excluded = undefined;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    fetchAwardsGamesStarted,
    fetchAwardsGamesSucceeded,
    fetchAwardsGamesFailed,
    fetchAwardsStandingsStarted,
    fetchAwardsStandingsSucceeded,
    fetchAwardsStandingsFailed,
    fetchAwardsCompetitorsStarted,
    fetchAwardsCompetitorsSucceeded,
    fetchAwardsCompetitorsFailed,
    fetchExcludedGamesStarted,
    fetchExcludedGamesSucceeded,
    fetchExcludedGamesFailed,
} = awardsSlice.actions;
export default awardsSlice.reducer;

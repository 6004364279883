/* eslint-disable no-restricted-imports */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MassMessageLimit, RecipientItem } from 'domains/Messenger/massMessage/MassMessage.types';
import { MassMessageState } from './MassMessage.types';

export const initialState: MassMessageState = {
    isLoading: false,
    errorMessage: undefined,
    massMessageLimit: undefined,
    massMessageRecipients: [],
    selectedRecipients: [],
    currentMediaToUpload: {
        isUploading: false,
        uploadingProgress: 0,
    },
    overlays: {
        isOverlayOpened: false,
        isConfirmVisible: false,
        isDiscardVisible: false,
        isVideoPlayerOpened: false,
        isTooltipOpened: false,
    },
};

const massMessageSlice = createSlice({
    name: 'massMessage',
    initialState,
    reducers: {
        fetchMassMessageLimitStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchMassMessageLimitSucceeded: (state, action: PayloadAction<MassMessageLimit>) => {
            state.massMessageLimit = action.payload;
            state.isLoading = false;
        },
        fetchMassMessageLimitFailed: (state, action: PayloadAction<string>) => {
            state.massMessageLimit = undefined;
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        fetchMassMessageRecipientsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchMassMessageRecipientsSucceeded: (state, action: PayloadAction<RecipientItem[]>) => {
            state.massMessageRecipients = action.payload;
            state.isLoading = false;
        },
        fetchMassMessageRecipientsFailed: (state, action: PayloadAction<string>) => {
            state.massMessageRecipients = [];
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        updateRecipientsList: (state, action: PayloadAction<RecipientItem[]>) => {
            state.massMessageRecipients = action.payload;
        },
        addSelectedRecipient: (state, action: PayloadAction<RecipientItem>) => {
            state.selectedRecipients.push(action.payload);
        },
        removeSelectedRecipient: (state, action: PayloadAction<number>) => {
            state.selectedRecipients = state.selectedRecipients.filter((recipient) => recipient.id !== action.payload);
        },
        clearSelectedRecipients: (state) => {
            state.selectedRecipients = [];
        },
        clearSelectionAndSelectedList: (state) => {
            state.selectedRecipients = [];
            state.massMessageRecipients = state.massMessageRecipients.map((recipient) => ({
                ...recipient,
                isSelected: false,
            }));
        },
        uploadMediaStarted: (state) => {
            state.currentMediaToUpload = {
                isUploading: true,
                uploadingProgress: 0,
                uploadingErrorMessage: undefined,
            };
        },
        uploadMediaProgressUpdate: (state, action: PayloadAction<{ uploadingProgress: number }>) => {
            const { uploadingProgress } = action.payload;
            if (!state.currentMediaToUpload) return;
            state.currentMediaToUpload.uploadingProgress = uploadingProgress;
        },
        uploadMediaSucceed: (state) => {
            state.currentMediaToUpload = {
                isUploading: false,
                uploadingProgress: 100,
            };
        },
        uploadMediaFailed: (state, action: PayloadAction<{ message: string }>) => {
            const { message } = action.payload;
            if (!state.currentMediaToUpload) {
                return;
            }
            state.currentMediaToUpload.uploadingErrorMessage = message;
            state.currentMediaToUpload.isUploading = false;
        },
        setMassMessageOverlayOpened: (state, action: PayloadAction<boolean>) => {
            state.overlays.isOverlayOpened = action.payload;
        },
        setMassMessageConfirmVisible: (state, action: PayloadAction<boolean>) => {
            state.overlays.isConfirmVisible = action.payload;
        },
        setMassMessageDiscardVisible: (state, action: PayloadAction<boolean>) => {
            state.overlays.isDiscardVisible = action.payload;
        },
        setMassMessageVideoPlayerOpened: (state, action: PayloadAction<boolean>) => {
            state.overlays.isVideoPlayerOpened = action.payload;
        },
        setMassMessageTooltipOpened: (state, action: PayloadAction<boolean>) => {
            state.overlays.isTooltipOpened = action.payload;
        },
    },
});

export const {
    fetchMassMessageLimitStarted,
    fetchMassMessageLimitSucceeded,
    fetchMassMessageLimitFailed,
    fetchMassMessageRecipientsStarted,
    fetchMassMessageRecipientsSucceeded,
    fetchMassMessageRecipientsFailed,
    updateRecipientsList,
    addSelectedRecipient,
    removeSelectedRecipient,
    clearSelectedRecipients,
    clearSelectionAndSelectedList,
    uploadMediaStarted,
    uploadMediaProgressUpdate,
    uploadMediaSucceed,
    uploadMediaFailed,
    setMassMessageOverlayOpened,
    setMassMessageConfirmVisible,
    setMassMessageDiscardVisible,
    setMassMessageVideoPlayerOpened,
    setMassMessageTooltipOpened,
} = massMessageSlice.actions;
export default massMessageSlice.reducer;

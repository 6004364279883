import { Products } from 'configs/globalConfig';
import { GameTypeEnum } from 'domains/Awards/Awards.types';
import { cookieNameCreator } from 'utils/storage/cookies';

const {
    Newbies,
    OranumWeeklyVideoCallAwards,
    OranumWeeklyMessagesAwards,
    OranumWeeklyNewModelsAwards,
    OranumWeeklySurprisesAwards,
    TeaserConversion,
    RisingStar,
    IconGame,
    Superstar,
    Guy,
    Fetish,
    Trans,
    NewbieRisingStarHoliday,
    IconSuperstarHoliday,
} = GameTypeEnum;

export const AWARDS_COMPETITORS_LIMIT = 100;
export const AWARDS_COMPETITORS_PREFETCH_COUNT = 5;
export const AWARDS_MAX_PRIZE_PER_CATEGORY_LIVEJASMIN = 500;
export const AWARDS_MAX_PRIZE_PER_CATEGORY_NON_LIVEJASMIN = 500;

const AWARDS_GAME_TYPE_ORDER_ORANUM = [
    OranumWeeklyNewModelsAwards,
    OranumWeeklyVideoCallAwards,
    OranumWeeklyMessagesAwards,
    OranumWeeklySurprisesAwards,
];

const AWARDS_GAME_TYPE_ORDER_LJ = [
    NewbieRisingStarHoliday,
    IconSuperstarHoliday,
    Newbies,
    RisingStar,
    IconGame,
    Superstar,
    Guy,
    Fetish,
    Trans,
    TeaserConversion,
];

export const AWARDS_GAME_TYPE_ORDER: { [key in Products]: GameTypeEnum[] } = {
    livejasmin: AWARDS_GAME_TYPE_ORDER_LJ,
    oranum: AWARDS_GAME_TYPE_ORDER_ORANUM,
};

export const NEW_MODEL_GAMES = [GameTypeEnum.OranumWeeklyNewModelsAwards, GameTypeEnum.Newbies];

export const AWARDS_EMPTY_LIST_LENGTH = 5;
export const AWARDS_DAYS_LIMIT = 60;

// +1 week after the new awards game starts
export const RISING_STAR_AWARDS_HISTORY_START = '2024-08-12T12:00:00+02:00';
export const ICON_AND_SUPERSTAR_AWARDS_HISTORY_START = '2024-10-07T12:00:00+02:00';
export const GUY_FETISH_TRANS_AWARDS_HISTORY_START = '2024-11-11T12:00:00+01:00';
export const HOLIDAY_AWARDS_HISTORY_START = '2024-12-23T12:00:00+01:00';

export const ICON_AND_SUPERSTAR_AWARDS_NEW_UNTIL = '2024-11-29T12:00:00+01:00';
export const GUY_FETISH_TRANS_AWARDS_NEW_UNTIL = '2024-01-03T12:00:00+01:00';

export const COOKIE_AWARDS_PAGE_SHOWN_ID = cookieNameCreator('functional', 'awardsPageShown');

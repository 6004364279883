import _ from '@dh-fe/translate';
import routes from 'configs/routes';

const content = {
    title: _`We Respect Your Privacy`,
    message:
        _`We use cookies and similar technologies, including third-party cookies, to improve your experience, analyze traffic and user activity, and for promotion purposes. To learn about our cookies, please read our [a href="{cookieUrl}" target="_blank"]Cookie Policy[/a].`
            .withData({
                cookieUrl: routes.cookiePolicyUrl,
            })
            .parseBB()
            .toJSX(),

    accept: _`Accept Cookies`,
    reject: _`Reject Cookies`,
    manage: _`Manage Cookies`,
};

export default content;

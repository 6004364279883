// eslint-disable-next-line no-restricted-imports
import dayjs from 'global/dayjsExtended';
import { fromZonedTime } from 'date-fns-tz';
import _ from '@dh-fe/translate';

const minute = 60;
const hour = 3600;
const day = 86400;
const week = 604800;

export const defaultTimeZone = 'Europe/Luxembourg';
export const defaultLocales = 'en-US';

export const monthShortNames = [
    _`Jan`,
    _`Feb`,
    _`Mar`,
    _`Apr`,
    _`May`,
    _`Jun`,
    _`Jul`,
    _`Aug`,
    _`Sep`,
    _`Oct`,
    _`Nov`,
    _`Dec`,
];

export const monthNames = [
    _`January`,
    _`February`,
    _`March`,
    _`April`,
    _`May`,
    _`June`,
    _`July`,
    _`August`,
    _`September`,
    _`October`,
    _`November`,
    _`December`,
];

export const ago = (time?: Date | null): string => {
    if (!time) return '';
    const diff = (Date.now() - time.getTime()) / 1000;
    const title = (value: number, unit: string) => `${Math.floor(diff / value)}${unit}`;
    if (diff < minute) return 'now';
    if (diff < hour) return title(minute, 'm');
    if (diff < day) return title(hour, 'h');
    if (diff < week) return title(day, 'd');
    return title(week, 'w');
};

interface DateTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const secondsToDateTime = (seconds: number): DateTime => {
    const d = Math.floor(seconds / (24 * 3600));
    const h = Math.floor((seconds % (24 * 3600)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    return {
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
    };
};

export const getDoclerQueryParamsFormattedDate = (d: Date): string => {
    return d.toISOString().replace(/\.\d\d\d/, '');
};

/** 90 => 1:30 */
export const getRelativeTimeFromSeconds = (secondsCount: number): string => {
    const d = new Date(secondsCount * 1000);
    const hhmmss = d.toUTCString().match(/\d{2}:\d{2}:\d{2}/)?.[0];
    if (!hhmmss) {
        throw new Error('Date can not be parsed');
    }
    return hhmmss.replace(/^00:/, '');
};

export const formatDate = (date: Date, separator = '-') => {
    const fillToTwoCharacters = (number: number) => {
        return number < 10 ? `0${number}` : number;
    };

    const month = fillToTwoCharacters(date.getMonth() + 1);
    const day = fillToTwoCharacters(date.getDate());

    return `${day}${separator}${month}${separator}${date.getFullYear()}`;
};

export const convertTZ = (date: Date | string | undefined = new Date(), { locales = defaultLocales } = {}) => {
    return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString(locales, { timeZone: defaultTimeZone }),
    );
};

// returns utc date
export const beginningOfDay = (date = new Date(), timeZone = defaultTimeZone) =>
    fromZonedTime(dayjs(date).startOf('day').toDate(), timeZone);

/**
 * Creates a date with local time 00:00:00 from a date string.
 * @param dateString The date string with format "YYYY-MM-DD".
 */
export const beginningOfDayFromDateString = (dateString: string): Date => dayjs(dateString).startOf('day').toDate();

// returns utc date
export const endOfDay = (date = new Date(), timeZone = defaultTimeZone) =>
    fromZonedTime(dayjs(date).endOf('day').toDate(), timeZone);

type UntrustableDate = string | number | Date | null | undefined;
export const isEarlier = (input: UntrustableDate, dateToCompare: Date, { orSameDay = false } = {}) => {
    let date = input;
    if (input === null) return false;
    if (typeof input === 'string') date = dayjs(input).toDate();
    if (date instanceof Date || typeof date === 'number')
        return orSameDay ? dayjs(date).isSameOrBefore(dateToCompare, 'day') : dayjs(date).isBefore(dateToCompare);
    return false;
};

export const toISO8601 = (input?: string | Date) => {
    let date = input;
    if (!input) return '';
    if (typeof input === 'string') date = new Date(input.replace(' ', 'T'));
    if (date instanceof Date) return `${date.toISOString().slice(0, -5)}Z`;
    throw new Error(`uncaught case ${typeof input}`);
};

export const mapJawsDateToUTC = (input: string): Date => {
    return new Date(`${input.replace(' ', 'T')}Z`);
};

export const formatDateInverse = (date: Date, separator = '-') => {
    const fillToTwoCharacters = (number: number) => {
        return number < 10 ? `0${number}` : number;
    };

    const month = fillToTwoCharacters(date.getMonth() + 1);
    const day = fillToTwoCharacters(date.getDate());

    return `${date.getFullYear()}${separator}${month}${separator}${day}`;
};

export const addSecondsToCurrentTime = (seconds: number): Date => dayjs().add(seconds, 'second').toDate();

export const getTimeDifference = (expiresIn: number, validForSeconds: number) => {
    const timeDifference = validForSeconds - expiresIn;
    if (timeDifference < minute) return _`{seconds}s`.withData({ seconds: timeDifference });
    if (timeDifference < hour) return _`{minutes}m`.withData({ minutes: Math.floor(timeDifference / minute) });
    return _`{hours}h`.withData({ hours: Math.floor(timeDifference / hour) });
};

export const getNumDaysBetween = (fromDateIso: string, toDateIso: string, floatPrecision = false) => {
    return dayjs(toDateIso).diff(dayjs(fromDateIso), 'day', floatPrecision);
};

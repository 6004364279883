import { combineReducers } from '@reduxjs/toolkit';
import messengerPageReducer from './MessengerPage/MessengerPage.slice';
import threadsReducer from './Threads/Threads.slice';
import recommendedMembersReducer from './RecommendedMembers/RecommendedMembers.slice';
import messagesReducer from './Messages/Messages.slice';
import unsentInputTextsReducer from './UnsentInputTexts/UnsentInputTexts.slice';
import recipientFilterReducer from './RecipientFilter/RecipientFilter.slice';
import messageActionsReducer from './MessageActions/MessageActions.slice';

const messengerReducer = combineReducers({
    messageActions: messageActionsReducer,
    messages: messagesReducer,
    messengerPage: messengerPageReducer,
    recipientFilter: recipientFilterReducer,
    recommendedMembers: recommendedMembersReducer,
    threads: threadsReducer,
    unsentInputTexts: unsentInputTextsReducer,
});

export default messengerReducer;

import { globalConfig } from 'configs/globalConfig';
import { StatisticsDashboardConfigToken } from 'contracts/window';
import { Configuration } from './configuration';

const apiGwConfig = new Configuration();

const defaultApiHost = '';
const apiHost = globalConfig.apiHost ? `https://${globalConfig.apiHost}` : defaultApiHost;

const defaultBiTokens = {
    biApiHost: '',
    biToken: {
        token: '',
        expireAt: '',
    },
};

const { statisticsDashboard } = globalConfig;
const configBiApiHost = statisticsDashboard && statisticsDashboard.biApi.baseUri;
const configBiToken = statisticsDashboard && statisticsDashboard.biApi.token;
const biApiHost = configBiApiHost || defaultBiTokens.biApiHost;
const biToken: StatisticsDashboardConfigToken = configBiToken || defaultBiTokens.biToken;

export { apiGwConfig, apiHost, biApiHost, biToken };

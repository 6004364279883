import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AcademyStatus, CountryTier, Mentor } from 'domains/TopModelAcademy/TopModelAcademy.types';
import { TopModelAcademyState } from './TopModelAcademy.types';

export const initialState: TopModelAcademyState = {
    mentor: {
        requestStatus: 'initial',
    },
    academyStatus: {
        requestStatus: 'initial',
    },
    countryTier: {
        requestStatus: 'initial',
    },
};

const topModelAcademySlice = createSlice({
    name: 'topModelAcademy',
    initialState,
    reducers: {
        fetchMentorByCountryCodeStarted: (state) => {
            state.mentor.requestStatus = 'pending';
        },
        fetchMentorByCountryCodeSucceeded: (state, action: PayloadAction<Mentor>) => {
            const { name, phone } = action.payload;
            state.mentor.name = name;
            state.mentor.phone = phone;
            state.mentor.requestStatus = 'resolved';
        },
        fetchMentorByCountryCodeFailed: (state) => {
            state.mentor.name = undefined;
            state.mentor.phone = undefined;
            state.mentor.requestStatus = 'rejected';
        },
        fetchAcademyStatusStarted: (state) => {
            state.academyStatus.requestStatus = 'pending';
        },
        fetchAcademyStatusSucceeded: (state, action: PayloadAction<AcademyStatus>) => {
            const { isAcademy, isMentorship, countryTier, countryCode } = action.payload;
            state.academyStatus.isAcademy = isAcademy;
            state.academyStatus.isMentorship = isMentorship;
            state.academyStatus.countryTier = countryTier;
            state.academyStatus.countryCode = countryCode;
            state.academyStatus.requestStatus = 'resolved';
        },
        fetchAcademyStatusFailed: (state) => {
            state.academyStatus.isAcademy = undefined;
            state.academyStatus.isMentorship = undefined;
            state.academyStatus.countryTier = undefined;
            state.academyStatus.countryCode = undefined;
            state.academyStatus.requestStatus = 'rejected';
        },
        fetchCountryTierStarted: (state, action: PayloadAction<string>) => {
            state.countryTier.countryCode = action.payload;
            state.countryTier.requestStatus = 'pending';
        },
        fetchCountryTierSucceeded: (state, action: PayloadAction<CountryTier>) => {
            const { tier } = action.payload;
            state.countryTier.tier = tier;
            state.countryTier.requestStatus = 'resolved';
        },
        fetchCountryTierFailed: (state) => {
            state.countryTier.countryCode = undefined;
            state.countryTier.tier = undefined;
            state.countryTier.requestStatus = 'rejected';
        },
    },
});

export const {
    fetchMentorByCountryCodeStarted,
    fetchMentorByCountryCodeSucceeded,
    fetchMentorByCountryCodeFailed,
    fetchAcademyStatusStarted,
    fetchAcademyStatusSucceeded,
    fetchAcademyStatusFailed,
    fetchCountryTierStarted,
    fetchCountryTierSucceeded,
    fetchCountryTierFailed,
} = topModelAcademySlice.actions;

export default topModelAcademySlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationUnreadCount } from 'domains/Notification/Notification.types';
import { UnreadCountState } from './UnreadCount.types';

const initialState: UnreadCountState = {
    unreadCount: 0,
    isLoading: false,
    errorMessage: undefined,
};

const unreadCountSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        fetchUnreadCountStarted: (state) => {
            state.unreadCount = 0;
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchUnreadCountSucceeded: (state, action: PayloadAction<NotificationUnreadCount>) => {
            state.unreadCount = action.payload.unreadCount;
            state.isLoading = false;
        },
        fetchUnreadCountFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        updateNotificationCount: (state, action: PayloadAction<number>) => {
            state.unreadCount = action.payload;
        },
    },
});

export const { fetchUnreadCountStarted, fetchUnreadCountSucceeded, fetchUnreadCountFailed, updateNotificationCount } =
    unreadCountSlice.actions;
export default unreadCountSlice.reducer;

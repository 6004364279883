import { MyStoryItemEntity, MyStoryTypes } from 'domains/LJStories/LJStories.types';

export interface StoriesState {
    isLoading: boolean;
    isDeleting: boolean;
    uploadProgress: number;
    storiesInProgress: ConvertingStoryEntity[];
    currentStep: UploadProcessStep;
    storyItems: Array<MyStoryItemEntity>;
    errorMessage?: string;
    deletingMediaType?: MyStoryTypes;
    viewerState: MyStoryViewerState;
}

export interface MyStoryViewerState {
    isOpen: boolean;
    currentStoryIndex: number;
}

export interface ConvertingStoryEntity {
    id: number;
}

export enum UploadProcessStep {
    DEFAULT,
    PREVIEW,
    UPLOAD,
    FINISHED,
}

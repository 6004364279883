import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountStripeArray } from 'domains/Statistics/AccountStripes/AccountStripesTypes';
import { AccountStripesState } from './AccountStripes.types';

const initialState: AccountStripesState = {
    accountStripes: undefined,
    isLoading: false,
    errorMessage: undefined,
};

const accountStripesSlice = createSlice({
    name: 'accountStripes',
    initialState,
    reducers: {
        fetchAccountStripesStarted: (state) => {
            state.accountStripes = undefined;
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchAccountStripesSucceeded: (state, action: PayloadAction<AccountStripeArray>) => {
            state.accountStripes = action.payload;
            state.isLoading = false;
        },
        fetchAccountStripesFailed: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
    },
});

export const { fetchAccountStripesStarted, fetchAccountStripesSucceeded, fetchAccountStripesFailed } =
    accountStripesSlice.actions;
export default accountStripesSlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MyStoryItemEntity } from 'domains/LJStories/LJStories.types';
import { StoriesState, ConvertingStoryEntity, UploadProcessStep, MyStoryViewerState } from './LJStories.types';

export const initialState: StoriesState = {
    isLoading: true,
    isDeleting: false,
    uploadProgress: 0,
    storiesInProgress: [],
    errorMessage: undefined,
    currentStep: UploadProcessStep.DEFAULT,
    storyItems: [],
    viewerState: {
        isOpen: false,
        currentStoryIndex: 0,
    },
};

const ljStoriesSlice = createSlice({
    name: 'ljStories',
    initialState,
    reducers: {
        updateStories: (state, action: PayloadAction<{ stories: Array<MyStoryItemEntity> }>) => {
            const { stories } = action.payload;
            for (const story of stories) {
                const currentStoryIndex = state.storyItems.findIndex((s) => s.id === story.id);
                if (currentStoryIndex !== -1) {
                    state.storyItems[currentStoryIndex] = story;
                } else {
                    state.storyItems.push(story);
                }
            }
        },
        setUploadProgress: (state, action: PayloadAction<number>) => {
            state.uploadProgress = action.payload;
        },
        setCurrentStep: (state, action: PayloadAction<UploadProcessStep>) => {
            state.currentStep = action.payload;
        },
        addStoryInProgress: (state, action: PayloadAction<ConvertingStoryEntity>) => {
            state.storiesInProgress.push(action.payload);
        },
        removeStoryInProgress: (state, action: PayloadAction<number>) => {
            state.storiesInProgress = state.storiesInProgress.filter(({ id }) => id !== action.payload);
        },
        fetchStoryItemsStarted: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        fetchStoryItemsSucceeded: (state, action: PayloadAction<Array<MyStoryItemEntity>>) => {
            state.isLoading = false;
            state.storyItems = action.payload;
            state.errorMessage = undefined;
        },
        fetchStoryItemsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deleteStoryItemStarted: (state) => {
            state.isDeleting = true;
            state.errorMessage = undefined;
        },
        deleteStoryItemSucceeded: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            state.isDeleting = false;
            state.errorMessage = undefined;
            state.storyItems = state.storyItems.filter((item) => item.id !== id);
        },
        deleteStoryItemFailed: (state, action: PayloadAction<string>) => {
            state.isDeleting = false;
            state.errorMessage = action.payload;
        },
        setViewerState: (state, action: PayloadAction<Partial<MyStoryViewerState>>) => {
            if (action.payload.isOpen !== undefined) {
                state.viewerState.isOpen = action.payload.isOpen;
            }
            if (action.payload.currentStoryIndex !== undefined) {
                state.viewerState.currentStoryIndex = action.payload.currentStoryIndex;
            }
        },
    },
});

export const {
    setUploadProgress,
    setCurrentStep,
    addStoryInProgress,
    removeStoryInProgress,
    fetchStoryItemsStarted,
    fetchStoryItemsSucceeded,
    fetchStoryItemsFailed,
    deleteStoryItemStarted,
    deleteStoryItemSucceeded,
    deleteStoryItemFailed,
    setViewerState,
    updateStories,
} = ljStoriesSlice.actions;

export default ljStoriesSlice.reducer;

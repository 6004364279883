import Cookies from 'js-cookie';
import type { Cookie, CookieCategory, CookieConsentConfiguration } from 'domains/CookieConsent/CookieConsent.types';
import type { CookieConsentUserSettings } from './CookieConsent.types';

const getUnconsentedCookieCategories = (
    cookieConsentConfiguration: CookieConsentConfiguration | undefined,
    consentedCookies: CookieConsentUserSettings,
): CookieCategory[] => {
    const consentedCookieCategories = Object.keys(consentedCookies.categories) as CookieCategory[];

    if (!cookieConsentConfiguration) {
        return consentedCookieCategories;
    }

    const configCategories = cookieConsentConfiguration?.cookies.map((cookie) => cookie.category) || [];

    return configCategories.filter((category) => category && !(category in consentedCookies.categories));
};

const getUnknownCookies = (cookies: Cookie[]): string[] => {
    const knownCookies = cookies.map((cookie) => cookie.name);
    const allCookies = Cookies.get();
    return Object.keys(allCookies).filter((cookie) => !knownCookies.includes(cookie));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCookieConsentSettings(settings: any): settings is CookieConsentUserSettings {
    return (
        (<CookieConsentUserSettings>settings).updatedAt !== undefined &&
        typeof (<CookieConsentUserSettings>settings).categories === 'object'
    );
}

export { getUnconsentedCookieCategories, getUnknownCookies, isCookieConsentSettings };
